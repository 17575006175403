@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

body {
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.container-mobile-menu {
    display: none;
}


/*body*/
.fa {
    color: #7c7f83 !important;
}

/*left side nav*/

.sidenav-outer {
    height: 100%;
    width: 9%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transition: 0.5s;     
}

.sidenav-outer a {
    transition: 0.3s;
}

#container-adjusts{
    margin-bottom: 100px;
}

.forscroll {
    /* overflow-y: scroll;
    max-height: 535px;
    text-align: initial; */
}

/* Track */
.sidenav-inner .forscroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}

/* Handle */
.sidenav-inner .forscroll::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

/* Handle on hover */


.sidenav-outer .closebtn {
    position: absolute;
    top: 36px;
    right: -9px;
    font-size: 0px;
    z-index: 99;
}

i.fa.fa-chevron-circle-left.sidenav_topicon {
    font-size: 25px !important;
    color: #808080c9 !important;
}

i.fa.fa-chevron-circle-right.sidenav_topicon {
    font-size: 25px !important;
    color: #808080c9 !important;
}

#clickimg {
    width: 90%;
    margin: 13px 0px;
}

.sidenav-inner ul {
    padding-left: 0.2em;
}

.sidenav-inner {
    position: relative;
    top: 0%;
    width: 100%;
    left: 0%;
    bottom: 0;
   overflow-y: scroll;
   height: 100%;
}

.sidenav-inner::-webkit-scrollbar {
   width: 3px;
}

.sidenav-inner::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey;
}

.sidenav-inner::-webkit-scrollbar-thumb {
   background: gray;
   border-radius: 10px;
}

.sidenav-inner .forscroll::-webkit-scrollbar {
    width: 3px;
}

.sidenav-inner li {
    list-style: none;
}

.sidenav-inner a {
    color: #7c7f83;
    font-weight: 500;
    display: block;
    padding: 17px 0px;
    text-decoration: none;
}

.sidenav-outer a:hover,
.sidenav-outer a:focus {
    text-decoration: none;
    color: #7c7f83;
}

.sidenav-inner li:has(> a.active) {
    background-color: #226920;
}

.sidenav-inner a.active {
    color: #ffffff;
}

#sidenav .active .sidenav_icon.fa:before,
.sidenav-inner .active span {
    color: #ffffff !important;
}

.closeNavwidth {
    width: 9%;
}

.side-navli {
    display: initial;
}

.addmember-logo {
    /* margin-bottom: 70px; */
    /* margin-top: 90px; */
    text-align: center;
    bottom: 0;
    position: fixed;
}

.addmember-logo span {
    font-size: 16px;
    color: #fff !important;
    background-color: #000;
    font-weight: 100;
    padding: 9px;
    padding-left: 20px;
    border: 3px solid transparent;
    border-radius: 29px;
    display: inline-block;
}

.addmember-logo i {
    color: #fff !important;
    background-color: #226920;
    padding: 6px;
    /* position: absolute; */
    top: 12px;
    left: 7px;
    /* font-size: 23px !important; */
    border: 1px solid transparent;
    border-radius: 44px;
}

.addmember-logo a {
    position: relative;
}

/* #addmember {
    display: initial;
} */

/*top header section*/
.Container-enlarge {
    /*margin: 14px 135px;*/
    margin-right: 5px;
    transition: 0.6s;
}

.Container-shrink {
    /*margin: 14px 235px;*/
    margin-right: 3px;
    transition: 0.3s;
}

.row.common {
    margin: 0;
}

.navbar {
    flex: 0%;
}

.navbar input {
    font-family: 'FontAwesome';
}

.userdrptag {
    text-align: end;
    position: relative;
}

.userdrptag img {
    border: 2px solid #ffa705;
    background-size: cover;
    border-radius: 50% 50% 50% 50%;
    width: 49px;
    height: 45px;
}

span#Userdrptag-icon i {
    font-size: 14px !important;
    margin: 0 6px;
}

.userdrp-content {
    border-radius: 5px;
    padding: 0px;
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 58px;
    text-align: center;
    overflow: auto;
    right: 0;
    top: 47px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
}

div#userDropdown a {
    display: block;
    padding: 4px 33px;
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: none;
    text-align: left;
}

div#userDropdown a:hover {
    color: black;
    text-decoration: none;
    background-color: #e5e5e5;
}

div#userDropdownnot p {
    display: block;
    padding: 4px 33px;
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
}

div#userDropdownnot p:hover {
    color: black;
    text-decoration: none;
    background-color: #e5e5e5;
}

div#userDropdownnot a {
    display: block;
    padding: 14px 33px;
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid #cecece;
}

div#userDropdownnot a:hover {
    color: black;
    text-decoration: none;
    background-color: #e5e5e5;
}

input.form-control.mr-sm-2 {
    border-radius: 12px;
}

.drpbtn::after {
    display: none !important;
}

.drpbtn {
    border: 1px solid !important;
    border-color: #979797 !important;
    padding: 5px 25px !important;
    border-radius: 50px;
}

span.calender {
    font-size: 14px;
}

span.chevron {
    padding-left: 30px;
    font-size: 11px;
}

input[type="search"]::placeholder {
    font-size: 12px !important;
}

/*dashboard section */
section.dashboard {
    background: #a7a7a714;
    padding: 12px;
    min-height: 500px;
}

/*pos 1*/
select#datedrpdwn {
    cursor: pointer;
    padding: 8px 15px;
    font-size: 14px;
    border: 2px solid #80808047 !important;
    -webkit-appearance: none;
    font-family: Arial, FontAwesome;
    border-radius: 13px;
    font-weight: 500;
}

.colRpos1 {
    display: flex;
    justify-content: space-between;
    margin: 12px 0px;
}

.colRpos1 h2 {
    font-size: 28px;
    font-weight: 600;
}

.colRpos1 input[type="text"] {
    width: 136px;
    border: 1px solid #80808047;
    padding: 6px;
    text-align: center;
    border-radius: 12px;
    font-size: 13px;
}

.colRpos1 input[type="text"]::placeholder {
    color: #000;
}

/*pos 2*/
.colRpos2 {
    margin: 0px 0px;
}

.pos2inner {
    text-align: center;
    background-color: #100422;
    padding: 11px;
    border-radius: 14px
}

.pos2inner h3 {
    color: #fff;
}

.pos2inner p {
    color: #fff;
}

/*po3*/
.colRpos3 {
    margin: 50px 22px;
    justify-content: space-between;
}

.pos3inner h6 {
    font-weight: 600;
    display: inline;
}

span.pos3innericon {
    margin-left: 60px;
}

span.pos3innericon i {
    font-size: 10px !important;
    color: #fff !important;
    padding: 5px;
    border: 1px solid transparent;
    background-color: #226920;
    border-radius: 15px;
}

.pos3innertop {
    position: relative;
    margin-bottom: 13px;
    padding-bottom: 12px;
    border-bottom: 1px solid #80808063;
}

.pos3inner h3 {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
}

.pos3innericon i.fa.fa-user-plus {
    background-color: #009fe1;
}

i.fa.fa-user-o {
    background-color: #000;
}

i.fa.fa-sticky-note-o {
    background-color: #665cff;
}

/*next appointment*/
.colRpos4 {
    margin: 0 20px;
}

.pos4inner h2 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 30px;
}

table {
    border-collapse: collapse;
    font-family: system-ui;
}

.thead th {
    color: #7C7F83 !important;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
}

th.thtime {
    text-align: right;
}

.tbody tr {
    border-top: 9px solid #fbfbfb;
    padding: 12px;
    border-radius: 15px;
    background-color: #fff;
}

/*.tbody td:nth-child(3) { text-align: right; font-size: 14px; font-weight: 600; font-family: system-ui; padding: 14px; }*/
td {
    padding: 10px;
    font-size: 13px;
    font-weight: 600;
    font-family: system-ui;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    background-color: white;
    /*width: 10%;*/
    padding-left: 0
}

.username span {
    margin: 0 10px;
}

.purble p {
    font-size: 12px;
    background-color: lavender;
    font-weight: 700;
    display: inline;
    color: #7c7cf3;
    border-radius: 16px;
    padding: 9px 15px;
}

.orange p {
    font-size: 12px;
    background-color: #ffa50059;
    font-weight: 700;
    display: inline;
    color: #f9a203;
    border-radius: 16px;
    padding: 9px 15px;
}

.green p {
    font-size: 12px;
    background-color: #00d10029;
    font-weight: 700;
    display: inline;
    color: #226920;
    border-radius: 16px;
    padding: 9px 15px;
}

.col-4.colL {
    margin-right: 0px;
    padding-left: 33px;
}

.colLpos1 {
    margin: 20px 0px;
}

.colLpos1 h3 {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 30px;
}

.pos1list ul {
    list-style: none;
}

.pos1list li {
    padding: 10px;
    background-color: #fff;
    margin: 12px 0px;
    font-size: 14px;
    font-weight: 600;
    border-radius: 9px;
}

.pos1list img {
    margin-right: 11px;
}

span.fav_symbol {
    margin-left: 84px;
}

.fav_symbol i {
    margin: 0 15px;
    margin-left: 0px;
}

.fav_symbol i.fa.fa-calendar {
    font-size: 12px !important;
    padding: 5px;
    background-color: #80808026;
    border-radius: 10px;
}

.fav_symbol i.fa.fa-envelope-o {
    font-size: 12px !important;
    padding: 5px;
    background-color: #80808026;
    border-radius: 10px;
}

.col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center;
}

.form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

.mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
}

.grayfont {
    color: #7C7F83;
}

.td_icon::before {
    font-size: 12px;
    margin-right: 8px;
    color: #7C7F83;
}

.td_icon.squarelarge::before {
    font-size: 20px;
    cursor: pointer;
}

.td_icon.action::before {
    cursor: pointer;
}

.orangebox {
    font-size: 12px;
    background-color: #fff1d7de !important;
    /* display: inherit; */
    display: inline;
    color: #f9a203;
    border-radius: 16px;
    padding: 9px 15px;
}

.purplebox {
    font-size: 12px;
    background-color: #dceef5 !important;
    /* display: inherit; */
    display: inline;
    color: #009fe1;
    border-radius: 16px;
    padding: 9px 15px;
}

.greenbox {
    font-size: 12px;
    background-color: lightgreen !important;
    /* display: inherit; */
    display: inline;
    color: #226920;
    border-radius: 16px;
    padding: 9px 15px;
}

.bluebox {
    font-size: 12px;
    background-color: lightblue !important;
    /* display: inherit; */
    display: inline;
    color: blue;
    border-radius: 16px;
    padding: 9px 15px;
}

.brownbox {
    font-size: 12px;
    background-color: #ffdbdb !important;
    /* display: inherit; */
    display: inline;
    color: brown;
    border-radius: 16px;
    padding: 9px 15px;
}

.pinkbox {
    font-size: 12px;
    background-color: lightpink !important;
    /* display: inherit; */
    display: inline;
    color: #333333;
    border-radius: 16px;
    padding: 9px 15px;
}

.graybox {
    font-size: 12px;
    background-color: lightgrey !important;
    /* display: inherit; */
    display: inline;
    color: #7c7f83;
    border-radius: 16px;
    padding: 9px 15px;
}

.maroonbox {
    font-size: 12px;
    background-color: lightgray !important;
    /* display: inherit; */
    display: inline;
    color: maroon;
    border-radius: 16px;
    padding: 9px 15px;
}

.violetbox {
    font-size: 12px;
    background-color: lightgray !important;
    /* display: inherit; */
    display: inline;
    color: violet;
    border-radius: 16px;
    padding: 9px 15px;
}

.yellowbox {
    font-size: 12px;
    background-color: lightgray !important;
    /* display: inherit; */
    display: inline;
    color: yellow;
    border-radius: 16px;
    padding: 9px 15px;
}

.dashboard .container {
    margin-top: 15px;
}

.adminmanageusers .menubox,
.adminmarketing .menubox,
.adminpayout .menubox,
.coach_plans .menubox,
.coach_food_plans .menubox,
.coach_users .mealplan_tab .menubox,
.client_weekplan .menubox,
.coach_availability .menubox,
.adminmanageteam .menubox,
.chatmessages .menubox,
.admin_analytics .menubox,
.adminlabtest .menubox,
.coach_users .connected_devices_tab .menubox,
.client_agreement_docs .menubox
 {
    width: 100%;
    clear: both;
    margin-bottom: 20px;
    display: inline-block;
}

.client_mealplan .inner_menubox {
    margin: 0 auto;
    display: table;
    margin-top: 20px;
}


.adminmanageusers .tabmenu a,
.adminmarketing .tabmenu a,
.coach_plans .tabmenu a,
.coach_food_plans .tabmenu a,
.coach_plan_recipe_modal_popup .recipetabmenu a,
.client_weekplan .menubox .tabmenu a,
.client_agreement_docs .menubox .tabmenu a,
.coach_availability .tabmenu a,
.adminmanageteam .tabmenu a,
.admin_analytics .tabmenu a,
.adminlabtest .tabmenu a,
.coach_food_plans .recipe_library_tab a,
.user_recipe_library .recipe_library_tab a {
    float: left;
    border-bottom: 2px solid #cfc7c7;
    color: #7C7F83;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}


.coach_users .connected_devices_tab .menubox {
    border-bottom: 1px solid #226920;
}

.coach_users .connected_devices_tab .menubox .device_tabmenu a, .client_connected_devices .menubox .device_tabmenu a {
    float: left;
    border-bottom: 2px solid #cfc7c7;
    color: #fff;
    text-decoration: none;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 10px;
    background-color: #226920;
}

.coach_users .connected_devices_tab .menubox .device_tabmenu a.active,.client_connected_devices .menubox .device_tabmenu a.active {
    text-decoration: underline;
}

.adminmanageusers .rightmenu input,
.adminpayout .rightmenu input,
.coach_plans .rightmenu .search,
.coach_food_plans .recipes_library .rightmenu .search,
.coach_users .mealplan_tab .recipes_library .rightmenu .search,
.adminmanageteam .rightmenu input,
.chatmessages .rightmenu input,
.adminlabtest .rightmenu input,
.import_recipe_div .search,.admin_coach_imported_recipe .rightmenu input {
    float: right;
    margin-right: 10px;
    font-family: 'FontAwesome';
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
}

.coach_blog .title_box .search {
    margin-right: 10px;
    font-family: 'FontAwesome';
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
}

.coach_blog .title_box {
    margin-bottom: 20px;
}

.adminmarketing .tabmenu {
    height: 49px;
    border-bottom: 2px solid #cfc7c7;
}

.adminmanageusers .tabmenu .active,
.adminmarketing .tabmenu .active,
.coach_plans .tabmenu .active,
.coach_plan_recipe_modal_popup .recipetabmenu .active,
.client_weekplan .menubox .tabmenu .active,
.coach_availability .tabmenu .active,
.adminmanageteam .tabmenu .active,
.admin_analytics .tabmenu .active,
.client_agreement_docs .menubox .tabmenu .active,
.adminlabtest .tabmenu .active {
    border-bottom: 2px solid #226920;
    color: #226920;
}

.admin_analytics .tabmenu a {
    padding-left: 20px;
    padding-right: 20px;
    font-size: 20px;
}


.coach_food_plans .menubox {
    margin-top: 30px;
    width: 100%;
    border-radius: 20px;
}

.coach_food_plans .tabmenu {
    background-color: #fff;
    float: left;
}

.coach_users .mealplan_tab .inner_tabmenu,
.client_mealplan .inner_tabmenu {
    background-color: #fff;
    display: flex;
    float: left;
}

.coach_users .mealplan_tab .action_buttons {
    float: right;
}

/*.coach_users .mealplan_tab .menubox{
   width:auto;
   display:table;
  margin: 0 auto;
}*/
.coach_users .mealplan_tab .menubox .inner_tabmenu a,
.client_mealplan .inner_tabmenu a {
    padding: 10px 30px !important;
    font-size: 16px !important;
}

.coach_food_plans .menubox .action_buttons {
    float: right;
    margin-right: 10px;
}

.coach_food_plans .tabmenu a,
.coach_users .mealplan_tab .inner_tabmenu a,
.client_mealplan .inner_tabmenu a,
.coach_food_plans .recipe_library_tab a,
.user_recipe_library .recipe_library_tab a {
    border-bottom: none !important;
}

.coach_food_plans .tabmenu .active,
.coach_users .mealplan_tab .inner_tabmenu .active,
.client_mealplan .inner_tabmenu .active {
    color: #226920 !important;
}

a {
    cursor: pointer;
}

.AdminChartMain {
    background-color: white;
    width: 60%;
}

.Tob_button span.active {
    color: #226920;
}

.Tob_button span {
    margin-right: 2.25em;
    font-size: 18px;
    color: #7c7f83;
    padding-bottom: 14px;
    font-weight: 500;
}

.total_invited_div {
    font-size: 16px;
    color: #7c7f83;
}

.Tob_button {
    margin-bottom: 27px;
    width: 50%;

}

.color_grey {
    color: #7C7F83;
}

.Tob_button .active {
    border-bottom: 2px solid #226920;
}

.table_profile_img {
    border-radius: 50%;
    height: 28px;
    width: 28px;
    margin-right: 10px;
}

.row_name {
    font-size: 12px;
    color: #7c7f83;
    font-weight: 400;
}

.admin_table {
    margin-top: 17px;
    width: 100%;
}

.edit_icon {
    margin-right: 8px;
}

.admin_table i {
    font-size: 15px !important;
}

.table_name_val {
    color: #7c7f83;
    font-size: 14px;
}

.name_val {
    font-weight: 600;
    font-size: 13px;
    padding-left: 2px;
}

.c-avatar {
    margin-top: 8px;
    position: relative;
    /*display: inline-block;*/
}

.c-avatar__status {
    width: 9px;
    height: 10px;
    background: #226920;
    border: 2px solid white;
    position: absolute;
    /*bottom: 2%;
    right: 71%;*/
    border-radius: 94%;
}


.client_appointment .subscribed_col .c-avatar__status,
.client_appointment .available_col .c-avatar__status,
.client_appointment .upcoming_appointment_table .c-avatar__status,
.admin_analytics .c-avatar__status,
.admin_dashboard .c-avatar__status {
    margin-left: -20px !important;
    margin-top: 18px;
}

.client_appointment .subscribed_col .c-avatar,
.nutrition_dashboard_col .next_appointment_table_coach .c-avatar,
.client_appointment .available_col .c-avatar {
    display: block;
}

.client_appointment .subscribed_col .photo_block,
.client_appointment .available_col .photo_block,
.nutrition_dashboard_active_users_div .photo_block,
.nutrition_dashboard_col .next_appointment_table_coach .photo_block,
.coach_calendar_table_appointment_history .photo_block,
.client_next_appointment_dashboard .photo_block {
    display: inline-block;
    float: left;
}


.nutrition_dashboard_active_users_div .c-avatar__status,
.nutrition_dashboard_col .next_appointment_table_coach .c-avatar__status {
    margin-left: -20px !important;
    margin-top: 24px;
}

.coach_users .leftblock .c-avatar__status {
    margin-left: -22px !important;
    margin-top: 38px;
}

.extra_css_admin>* {
    font-weight: 600;
    text-align: left;
}

.side_nav_class span {
    color: #7c7f83;
}

.pointer_eff {
    cursor: pointer;
}

.label_small {
    font-size: 10px;
}

.label_small_block {
    font-size: 10px;
    display: block;
}

.label_small_new {
    font-size: 10px;
    margin-left: 3px
}

.admin_table td {
    width: 44% !important;
}



.admin_manageusers_delete_confirm-overlay .popup-content {
    width: 25%;
}

.admin_manageusers_delete_confirm-overlay .popup_body {
    text-align: center;
}

.admin_manageusers_delete_confirm-overlay .popup_body input {
    width: 70px;
    outline: 0;
}

.admin_manageusers_user_edit-overlay h5,
.admin_manageusers_add_coach-overlay h5,
.admin_manageusers_add_client-overlay h5 {
    text-align: center;
}

.admin_manageusers_user_edit-overlay label,
.admin_manageusers_add_coach-overlay label,
.admin_manageusers_add_client-overlay label {
    width: 100px;
    margin: 15px;
}

.admin_manageusers_user_edit-overlay .action_button,
.admin_manageusers_add_coach-overlay .action_button,
.admin_manageusers_add_client-overlay .action_button {
    text-align: center;
}

.admin_manageusers_user_edit-overlay .popup_body,
.admin_manageusers_add_coach-overlay .popup_body,
.admin_manageusers_add_client-overlay .popup_body {
    margin-top: 20px;
    margin-bottom: 20px;
}

.admin_manageusers_user_edit-overlay input[type="button"],
.admin_manageusers_add_coach-overlay input[type="button"],
.admin_manageusers_add_client-overlay input[type="button"] {
    width: 80px;
    padding: 4px 10px;
}

input[type="button"] {
    color: #226920;
    border: 1px solid #226920;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 500;
}

.greenbutton {
    color: #ffffff !important;
    background-color: #226920;
}

.admin_billing_top_label {
    display: inline-block !important;
    margin-top: 23px !important;
    margin-left: 7px !important;
    border-bottom: 2px solid #80808040;
    padding-bottom: 10px;
}

.admin_billing_top_label span {
    font-size: 18px;
    color: #7C7F83;
    padding-bottom: 2px;
    font-weight: 500;
}


.admin_billing_top_label span.active {
    border-bottom: 2px solid #226920 !important;
    color: #226920 !important;
}

/* .commission_tab{
   margin-left: 24px !important;
   margin-top: 25px !important;
}*/

.display_inline {
    font-size: 13px;
    color: #7c7f83;
}

.money_billing {
    font-family: 'Playfair Display', serif;
    font-size: 22px;
    color: black;
    font-weight: 700;
}

.avail_bal {
    position: relative;
}

.withdraw_but {
    position: absolute;
    padding: 10px 58px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;

}

.last_commision_tab {
    background-color: #100422;
    border-radius: 8px;
    padding-left: 32px !important;
    padding-top: 15px !important;
}

.last_commision_tab span {
    color: white;
    font-size: 23px;
}

.last_commision_tab_button {
    float: right;
    background-color: #226920;
    border: none;
    color: white;
    padding: 10px 33px;
    border-radius: 8px;
    margin-right: 47px;
    margin-top: 15px;
}

.inner_tab {
    display: contents;
}

.background_change {
    background-color: white;
    border-radius: 12px;
}

.manage_plan_table {
    margin-left: 19px !important;
    margin-top: 66px !important;

    position: absolute;
    width: 1109px !important;

}

.tbody_manage_plan_table tr {
    height: 54px;
}

.tbody_manage_plan_table td {
    background-color: ghostwhite;
}

.last_class {
    text-align: revert;
}

.tbody_manage_plan_table td.last_class {
    text-align: center;
}

.last_class i {
    font-size: 14px !important;
}

.add_plan_label {
    color: #7c7f83;
    font-weight: 600;
}

.add_plan_inner_label {
    font-weight: 400;
}

.add_plan_input {

    border-radius: 6px;
    border-color: black;
}

.popup_error_add_plan {
    text-align: center;
    color: red;
    margin-bottom: 17px;
}



.pagination {
    margin-top: 50px;
    float: right;
}

.pagination .pagination_prev,
.pagination .pagination_next {
    border: 1px solid #7c7f83;
    padding: 2px 6px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
}

.pagination li a {
    margin-right: 10px;
    margin-left: 10px;
    text-decoration: none;
}

.pagination .disabled a {
    cursor: initial;
}

.pagination li.active {
    border: 1px solid #226920;
    background-color: #226920;
    border-radius: 7px;
}

.pagination li.active a {
    color: #ffffff;
}

.subscriber_div {
    margin-top: 33px !important;
}

.commission_popup .modal-header {
    border-bottom: none;
}

.preview_service_agreement_popup .modal-header {
    border-bottom: none;
}

.preview_service_agreement_popup .modal-footer {
    border-top: none;
    display: block;
}

.commission_popup .modal-footer {
    border-top: none;
    display: block;
}

.commission_popup .modal-content {
    /*width: 128%;
   height: 567px;*/
    border-radius: 28px;
}

.preview_service_agreement_popup .modal-content {
    width: 128%;

    border-radius: 28px;
}


/* .commission_popup .modal-dialog{
   margin-left: 361px
}

.preview_service_agreement_popup .modal-dialog{
   margin-left: 361px
}*/

.commission_popup {
    margin-top: 37px;

}

.commission_popup p {
    font-size: 13px;
    color: #7C7F83;
}

.section_1_commission {
    padding-top: 19px;
}

.commission_inner_popup_label {
    font-size: 13px;
    font-weight: 500;
}

.commission_inner_popup_select {
    font-size: 12px !important;
    background-color: #d3d3d352 !important;
    color: #7C7F83 !important;
}


.commission_inner_input[type=text] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
}

.commission_inner_input[type=password] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
}

.commission_inner_input[type=text]:focus {
    outline: none;
    border: 1px solid #7c7f83;

}

.commission_inner_input[type=password]:focus {
    outline: none;
    border: 1px solid #7c7f83;

}

.commission_inner_input[type=number] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
}

.commission_inner_input[type=password] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
}

.commission_inner_input[type=number]:focus {
    outline: none;
    border: 1px solid #7c7f83;

}

.add_category_inner_input[type=text] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
    margin-top: 8px;
}

.add_category_inner_input[type=text]:focus {
    outline: none;
    border: 1px solid #7c7f83;

}

.text_area_focus:focus {
    outline: none;
    border: 1px solid #7c7f83;
}

.text_area_focus {
    border-radius: 4px;
}

.contact_submit_button {
    float: left;
}


.add_category_inner_input[type=number] {
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 100%;
    height: 30px;
    padding-left: 10px;
    margin-top: 8px;
}

.add_category_inner_input[type=number]:focus {
    outline: none;
    border: 1px solid #7c7f83;
}

.modal .btn-primary.save_button {
    float: right;
    background-color: #226920 !important;
    border-color: #226920;
}

.modal .btn-secondary.close_button {
    color: black !important;
    background-color: white !important;
    font-weight: 500 !important;
    width: 87px !important;
}

.save_button_commission {
    float: right;
    background-color: #226920 !important;
}

.close_button_commission {
    color: black !important;
    background-color: white !important;
    font-weight: 500 !important;
    width: 87px !important;
}

.modal_action_button {
    float: right;
    background-color: #226920 !important;
    border-color: #226920 !important;
}

.modal_close_button {
    color: black !important;
    background-color: white !important;
    font-weight: 500 !important;
    width: 87px !important;
    border-color: #c4c6c9 !important;
}

.modal .btn-close {
    background-color: #e5e7eb;
    border-radius: 20px;
    font-size: 10px;
}


/* Pagination links */
.pagination_j span {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    cursor: pointer;
}

/* Style the active/current link */
.pagination_j span.active {
    background-color: #51cd51;
    color: white;
}

/* Add a grey background color on mouse-over */
.pagination_j span:hover:not(.active) {
    background-color: #ddd;
}

.inner_pagination_j {
    margin-left: 27% !important;
    margin-top: 20px !important;
}

.subscriber_div ul.pagination {
    justify-content: end;
}

.manage_plan_row ul.pagination {
    justify-content: end;
}

.adminpaymentmethods .paymentgateway {
    width: 350px;
    float: left;
    height: 105px;
    margin-top: 50px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-right: 15px;
}

.adminpaymentmethods .paymentgateway .first_column {
    width: 20%;
    float: left;
}

.adminpaymentmethods .paymentgateway .second_column {
    width: 65%;
    float: left;
}

.adminpaymentmethods .paymentgateway .third_column {
    width: 15%;
    float: right;
    margin-top: 30px;
}

.adminpaymentmethods .paymentgateway .third_column .fa::before {
    color: #226920;
    font-size: 40px;
}

.adminpaymentmethods .paymentgateway .title {
    font-weight: bold;
    font-size: 17px;
    padding: 0px 0 10px 0px;
}

.section_ele {
    background: #a7a7a714;
    padding: 28px;

}

.notif_heading {
    font-size: larger;

}

.row_1_not {
    padding-top: 24px;
}

.notification_info {
    color: #7c7f83;
}

.notification_heading_label {
    font-weight: 600;
    font-size: 13px;
    margin-bottom: 16px
}

.server_key_ios {
    width: 100%;
    border-radius: 5px;
    border-color: #00000047;
}

.server_key_android {
    width: 100%;
    border-radius: 5px;
    border-color: #00000047;

}

input.ios_pem_file_class {
    width: 100%;
    border-radius: 7px;
    border-color: #00000047;
    cursor: pointer;
}

.android_pem_file_class {
    width: 100%;
    border-radius: 7px;
    border-color: #00000047;
    cursor: pointer;
}

.save_settings_notifi {
    background-color: #226920;
    border: none;
    color: white;
    padding: 8px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    float: right;
    border-radius: 12px;


}







.adminpaymentmethods .paymentgateway .description {
    font-size: 14px;
}

.adminpaymentmethods .paymentgateway .logo img {
    width: 60px;
    margin-top: 20px;
}

.addpromotion .greenbutton {
    padding: 10px 40px;
}

.dummy_class {
    display: flex !important;
}



.col.dummy_class button {
    border: 1px solid #00000026;
    padding: 10px 17px 7px 9px;
    border-radius: 6px 0px 0px 6px;
    background: #e9ecef;
    border-right-style: none !important;
    cursor: pointer;
}

.col.dummy_class p {
    width: 78%;
    cursor: pointer;
    margin-bottom: 0px;
    text-align: center;
    border: 2px solid #80808040;
    padding-top: 7px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #7c7f83;
}


.manager_team_tab_div a {
    text-align: center;
}

.manager_team_tab_div_right {
    padding-top: 15px;
}

.manager_team_button {
    color: white !important;
    border-radius: 5px;
    border: none;
    margin-right: 0px;

}

.manage_team_users {
    background: #a7a7a714;
    padding: 20px;
}








.p_title {
    font-weight: 500;
    margin-bottom: 10px;
}

.whitebg {
    background-color: #ffffff;
}

.adminmarketing .intro {
    padding: 10px;
    border-radius: 15px;
    margin-bottom: 20px;
}

.modal-header {
    border-bottom: none !important;
}

.modal-footer {
    border-top: none !important;
    display: block !important;
}

/* .adminmarketing_modal_popup .modal-footer,
.coach_plan_modal_popup .modal-footer,
.coach_blog_modal_popup .modal-footer,
.client_reqplan_modal_popup .modal-footer,
.client_weekplan_ing_modal_popup .modal-footer,
.client_weekplan_swap_modal_popup .modal-footer,
.adminmessages_img_modal_popup .modal-footer,
.coachservice_category_delete .modal-footer,
.coachservice_delete .modal-footer,
.coach_document_delete .modal-footer,
.coach_questionnaire_delete .modal-footer,
.clientdashboard_weight_update .modal-footer,
.generate_food_diary_report_pop .modal-footer,
.client_report_questionnaire .modal-footer,
.client_weekplan_inputs_modal_popup .modal-footer,
.progressdiary_input_fields .modal-footer,
.confirm_delete .modal-footer,
.reschedule_client_popup .modal-footer,
.coach_users_labtest_addbiomarkers_popup .modal-footer, .admin_manageusers_user_edit_popup .modal-footer {
    display: block !important;
} */

.client_reqplan_modal_popup .modal-body .inner_popup_label {
    margin-top: 10px;
}

.modal-body .inner_popup_label {
    font-size: 13px;
    font-weight: 500;
}


.modal-body .inner_popup_select {
    float: left;
    margin-top: 6px;
    width: 100%;
    font-size: 12px !important;
    background-color: #d3d3d352 !important;
    color: #7c7f83 !important;
    text-align: left;
}

.modal-body .inner_popup_input {
    float: left;
    margin-top: 6px;
    width: 100%;
    background-color: #f8f8fa !important;
    outline: 0;
    border-radius: 15px;
    border: 1px solid #7c7f83;
    padding: 5px 0px 5px 10px;
    font-size: 14px;
}

.adminmarketing_modal_popup .modal-body .row,
.coach_plan_modal_popup .modal-body .row,
.coach_blog_modal_popup .modal-body .row {
    padding-top: 5px;
}

.adminmarketing_modal_popup .react-date-picker__wrapper {
    border: 0;
}

.add_manager_popup .modal-content {
    /* padding-left: 29px;
   padding-top: 29px; */
    padding: 16px;
}

.label_add_manager_div {
    width: 100%;
}

.input_add_manager_div {
    width: 90%;
    margin-top: 10px !important;
    margin-bottom: 15px;
}

.input_add_manager_div input {
    width: 100%;
    border: 2px solid #80808047;
    border-radius: 8px;
    padding: 5px;
}

.input_add_manager_div select {

    width: 100%;
}

.label_add_manager_div label {
    color: black !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.add_team_popup .modal-content {
    height: auto !important;
}

.search_button_manageTeam {
    float: none !important;
}

.err {
    color: red !important
}

.err_row {
    margin-top: 10px !important;
}

.admin_marketing_email_user .action_buttons,
.admin_marketing_invite_user .action_buttons {
    float: right;
    margin-top: 30px;
}

.admin_marketing_email_user .action_buttons .btn,
.admin_marketing_invite_user .action_buttons .btn {
    width: 165px;
}

.admin_marketing_email_user .action_buttons .normal_button {
    background-color: #fff;
    border: none;
    color: #333;
    font-weight: bold;
    outline: 0;
}

.admin_marketing_email_user .action_buttons .green_button,
.admin_marketing_invite_user .action_buttons .green_button,
.coach_users .notes_tab .action_buttons .green_button,
.coach_food_plans .action_buttons .green_button,
.coach_users .mealplan_tab .action_buttons .green_button,
.coach_users .labtest_tab .action_buttons .green_button,
.coach_blog .title_box .green_button,
.client_mealplan .action_buttons .green_button,
.google_fitness .action_buttons .green_button, .withingsorder .action_buttons .green_button {
    background-color: #226920;
    border-color: #226920;
}

.admin_marketing_email_user .action_buttons .btn:focus,
.admin_marketing_invite_user .action_buttons .btn:focus,
.admin_marketing_invite_user .modal_action_button:focus {
    box-shadow: none;
}

.admin_marketing_invite_user .inner_popup_input {
    background-color: #ffffff !important;
    border-color: #ddd0d0;
}

.chatmessages .messages {
    /* flex: 1 1 100%;*/
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
    padding: 10px 14px 10px 12px;
    width: 100%;
    height: 400px;
}

.chatmessages .message__sender_name {
    flex: 1 0;
    color: #6C7A92;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    padding-left: 14px;
    padding-right: 8px;
    margin-bottom: 0;
}

.chatmessages .message__sender_and_status {
    display: flex;
    margin: 0 0 4px 0;
}

.chatmessages .message__timestamp,
.chatmessages .message__status {
    opacity: 0.6;
    font-size: 12px;
    line-height: 17px;
    text-align: right;
    color: #4A4A4A;
}

.chatmessages .message__timestamp {
    width: 30px;
}

.chatmessages .message__text_and_date {
    display: flex;
}

.chatmessages .message__wrap {
    display: flex;
    flex-wrap: nowrap;
    margin: 0 0 16px 0;
    position: relative;
}

.chatmessages .message__content.type_chat {
    padding-left: 0;
}

.chatmessages .message__content {
    flex-wrap: nowrap;
    padding-left: 50px;
    position: relative;
}

.chatmessages .message__sender_and_status {
    display: flex;
    margin: 0 0 4px 0;
}

.chatmessages .message__text_wrap {
    border-radius: 22px 22px 22px 0;
    background-color: #FFFFFF;
    box-shadow: 0 3px 48px 0 #d8e5ff;
    overflow: hidden;
}

.chatmessages .message-menu {
    z-index: 120;
    width: 146px;
    border-radius: 14px;
    background-color: rgba(255, 255, 255, 0.9);
    box-shadow: 0 3px 48px 0 rgba(108, 122, 146, 0.6);
    position: absolute;
    display: none;
    backdrop-filter: blur(20px);
}

.chatmessages .message-menu li {
    height: 19px;
    width: 120px;
    color: #000000;
    font-size: 14px;
    line-height: 19px;
    margin: 15px 16px;
    cursor: pointer;
}

.chatmessages .message-menu.active {
    display: block;
}

.chatmessages .content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    flex: 1 1 595px;
    height: 100%;
    max-width: 100%;
    overflow: hidden;
    background-color: #ffffff;
    border: 1px solid lightgray;
}

.chatmessages .content__inner {
    width: 100%;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: hidden;
    margin-top: -1px;
}

.chatmessages .message__right {
    margin-left: auto;
    margin-right: 0;
    padding-left: 0;
}

.chatmessages .message__text a {
    color: #366BA9;
}

.chatmessages .message__text.you {
    color: #FFFFFF;
}

.chatmessages .message__text {
    overflow: hidden;
    white-space: pre-line;
    word-break: break-all;
    color: #000000;
    font-size: 14px;
    line-height: 19px;
    padding: 12px 16px 13px 16px;
    letter-spacing: 0;
    margin-bottom: 0;
}

.chatmessages .message__text_wrap.you {
    border-radius: 22px 22px 0 22px;
    background-color: #13b713;
    box-shadow: 1px 4px 19px -2px var(--brand);
    overflow: hidden;
}

.chatmessages .sidebar {
    flex: 1 0 303px;
    position: static;
    left: 100%;
}

.chatmessages .sidebar {
    width: 100%;
    height: 100%;
    z-index: 100;
}

.chatmessages .sidebar__inner {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    height: 100%;
}

.chatmessages .sidebar__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    overflow: hidden;
}

.chatmessages .sidebar__dilog_list {
    flex: 1 1 100%;
    text-align: left;
    overflow-y: scroll;
}

.chatmessages .sidebar__dilog_list .dialog__item.selected,
.chatmessages .sidebar__dilog_list .dialog__item:hover {
    background-color: #3978FC;
    box-shadow: 6px 0 4mm 8px rgb(57 120 252 / 40%);
}

.chatmessages .dialog__item_link {
    padding: 10px 16px 10px 7.3px;
    display: flex;
    flex-wrap: nowrap;
    text-decoration: none;
}

.chatmessages .dialog__avatar.m-user__img_1,
.chatmessages .message__avatar.m-user__img_1,
.chatmessages .user__avatar.m-user__img_1,
.client_message_dashboard .dialog__avatar.m-user__img_1 {
    color: #fff;
    background-color: #53C6A2;
}

.client_message_dashboard .dialog__avatar {
    display: block;
    float: left;
}

.chatmessages .message__avatar {
    transition: .3s;
}

.chatmessages .dialog__avatar.m-user__img_2,
.chatmessages .message__avatar.m-user__img_2,
.chatmessages .user__avatar.m-user__img_2,
.client_message_dashboard .dialog__avatar.m-user__img_2 {
    color: #fff;
    background-color: #FDD762;
}

.chatmessages .dialog__avatar.m-user__img_3,
.chatmessages .message__avatar.m-user__img_3,
.chatmessages .user__avatar.m-user__img_3,
.client_message_dashboard .dialog__avatar.m-user__img_3 {
    color: #fff;
    background-color: #9261D3;
}

.chatmessages .dialog__avatar.m-user__img_4,
.chatmessages .message__avatar.m-user__img_4,
.chatmessages .user__avatar.m-user__img_4,
.client_message_dashboard .dialog__avatar.m-user__img_4 {
    color: #fff;
    background-color: #43DCE7;
}

.chatmessages .dialog__avatar.m-user__img_5,
.chatmessages .message__avatar.m-user__img_5,
.chatmessages .user__avatar.m-user__img_5,
.client_message_dashboard .dialog__avatar.m-user__img_5 {
    color: #fff;
    background-color: #FFCC5A;
}

.chatmessages .dialog__avatar.m-user__img_6,
.chatmessages .message__avatar.m-user__img_6,
.chatmessages .user__avatar.m-user__img_6,
.client_message_dashboard .dialog__avatar.m-user__img_6 {
    color: #fff;
    background-color: #EA4398;
}

.chatmessages .dialog__avatar.m-user__img_7,
.chatmessages .message__avatar.m-user__img_7,
.chatmessages .user__avatar.m-user__img_7,
.client_message_dashboard .dialog__avatar.m-user__img_7 {
    color: #fff;
    background-color: #4A5DE1;
}

.chatmessages .dialog__avatar.m-user__img_8,
.chatmessages .message__avatar.m-user__img_8,
.chatmessages .user__avatar.m-user__img_8,
.client_message_dashboard .dialog__avatar.m-user__img_8 {
    color: #fff;
    background-color: #E95555;
}

.chatmessages .dialog__avatar.m-user__img_9,
.chatmessages .message__avatar.m-user__img_9,
.chatmessages .user__avatar.m-user__img_9,
.client_message_dashboard .dialog__avatar.m-user__img_9 {
    color: #fff;
    background-color: #7EDA54;
}

.chatmessages .dialog__avatar.m-user__img_10,
.chatmessages .message__avatar.m-user__img_10,
.chatmessages .user__avatar.m-user__img_10,
.client_message_dashboard .dialog__avatar.m-user__img_10 {
    color: #fff;
    background-color: #F9B647;
}

.chatmessages .dialog__avatar,
.chatmessages .message__avatar,
.chatmessages .user__avatar,
.client_message_dashboard .dialog__avatar {
    flex: 0 0 41px;
    width: 41px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    margin: 0 8px 0 0;
    position: relative;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.chatmessages .dialog__avatar .icons,
.chatmessages .message__avatar .icons,
.client_message_dashboard .dialog__avatar .icons {
    font-size: 16px !important;
    font-weight: 600;
    line-height: 22px;
    font-style: normal;
}

.chatmessages .dialog__info {
    flex: 1 1 100%;
    overflow: hidden;
}

.chatmessages .sidebar__dilog_list .dialog__item.selected .dialog__name,
.chatmessages .sidebar__dilog_list .dialog__item.selected .dialog__last_message,
.chatmessages .sidebar__dilog_list .dialog__item:hover .dialog__last_message,
.chatmessages .sidebar__dilog_list .dialog__item:hover .dialog__name {
    color: #FFFFFF;
}

.chatmessages .dialog__name {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0 10px 6px 0;
    letter-spacing: 0;
}

.chatmessages .dialog__last_message {
    display: block;
    width: 100%;
    font-size: 14px;
    line-height: 17px;
    color: #6C7A92;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: normal;
    margin-top: -2px;
}

.chatmessages .dialog_additional_info {
    flex: 0 0 100px;
    text-align: right;
}

.chatmessages .dialog__last_message_date {
    font-size: 12px;
    line-height: 14px;
    color: #4A4A4A;
    margin: 0 0 2px 0;
    display: inline-block;
}

.chatmessages .dialog_unread_counter {
    min-width: 23px;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    display: inline-block;
    height: 24px;
    width: 24px;
    border-radius: 12px;
    background-color: #00CC4C;
}

.chatmessages .dialog_unread_counter.hidden {
    display: none;
}

.chatmessages .sidebar__dilog_list {
    padding-left: 0;
}

.chatmessages .sidebar__dilog_list li {
    list-style: none;
    background-color: #fff;
    border-radius: 10px;
    margin-top: 10px;
}

.chatmessages .sidebar__dilog_list li.active {
    border: 1px solid #4CAF50;
    box-shadow: 5px 5px 10px 5px #4CAF50;
}

.chatmessages .dialog__avatar i,
.chatmessages .message__avatar i,
.chatmessages .user__avatar i,
.client_message_dashboard .dialog__avatar i {
    font-size: 57px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.chatmessages .message_attachment.loaded {
    position: static;
    visibility: visible;
}

.chatmessages .message__attachments_wtap {
    width: auto;
    height: auto;
}

.chatmessages .message__attachments_wtap img {
    width: 100%;
    height: auto;
}

.chatmessages .message_attachment {
    width: 100%;
    max-width: 328px;
    position: absolute;
    visibility: hidden;
    display: block;
    margin: 0 0 5px 0;
}

.chatmessages .dialog_title {
    width: 100%;
    /*background-color:#3978FC;*/
    background-color: #226920;
}

.chatmessages .dialog_title #dialogName {
    text-align: left;
    max-width: 160px;
    margin: 0 auto;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 47px;
    text-overflow: ellipsis;
    /*overflow: hidden;*/
    height: 48px;
    white-space: nowrap;
    transform: translate(-3px, 0%);
}

.chatmessages .dialog_title .dialog_menu {
    float: right;
    display: inline-block;
    margin-top: -35px;
    margin-right: 25px;
    cursor: pointer;
}

.chatmessages .dialog_title .dialog_menu .fa {
    color: #ffffff !important;
    padding: 0 10px;
}

.chatmessages .dialog_title .dialog_menu_content.true {
    display: initial;
}

.chatmessages .dialog_title .dialog_menu_content {
    border-radius: 5px;
    padding: 0px;
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 58px;
    text-align: center;
    overflow: auto;
    right: 0;
    margin-top: -15px;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 20%);
    z-index: 1;
    right: 60px;
}

.chatmessages .dialog_title .dialog_menu_content a {
    display: block;
    padding: 4px 33px;
    font-size: 15px;
    color: #9b9b9b;
    text-decoration: none;
    text-align: left;
}

.chatmessages .send_message {
    border-top: 1px solid #DADFE1;
    position: relative;
}

.chatmessages .message__actions {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px 10px;
    position: absolute;
}

.chatmessages .attach_btn {
    flex: 0 0 20px;
    overflow: hidden;
    color: #3978FC;
    cursor: pointer;
    transform: scale(1.5) scale(-1, 1) rotate(-134deg);
}

.chatmessages .attach_btn i {
    font-size: 22px;
    display: inline-block;
    vertical-align: middle;
}

.chatmessages .material-icons {
    /*font-family: 'Material Icons';*/
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
}

.chatmessages .attach_input {
    display: none;
}

.chatmessages .send_btn {
    cursor: pointer;
    position: absolute;
    right: 8px;
    top: 10px;
    background: transparent;
    border: none;
}

.chatmessages select,
.chatmessages button,
.chatmessages textarea,
.chatmessages input {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    outline: none !important;
}

.chatmessages .message_field {
    border: none;
    width: 100%;
    resize: none;
    padding: 15px 45px;
    font-size: 14px;
    line-height: 16px;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    height: 44px;
    overflow: hidden;
}

.chatmessages .attachments_preview {
    display: none;
    height: 96px;
    background-color: #fff;
    flex: 1 1 100%;
}

.chatmessages .attachments_preview .m-loading {
    position: relative;
}

.chatmessages .attachments_preview .img-close {
    position: absolute;
    right: 4px;
    z-index: 2;
    top: 8px;
    background-color: rgba(142, 143, 143, 0.7);
    display: none;
    cursor: pointer;
}

.chatmessages .attachment_preview__item {
    max-width: 290px;
    max-height: 80px;
    margin: 8px 4px;
}

.chatmessages .messages .welcome__message {
    text-align: center;
    margin-top: 20%;
}

.chatmessages .sidebar__dilog_list,
.chatmessages .messages,
.chatmessages .modal {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scrollbar-width: thin !important;
}

.chatmessages .group_chat__user_list,
.chatmessages .update_chat__user_list {
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    scrollbar-width: thin !important;
}

.chatmessages ::-webkit-scrollbar {
    width: 2.3px;
    border: 1px solid #D9E3F7;
}

.chatmessages .group_chat__user_list::-webkit-scrollbar,
.chatmessages .update_chat__user_list::-webkit-scrollbar {
    width: 2.5px;
    border: 1px solid #D9E3F7;
}

.chatmessages ::-webkit-scrollbar-thumb {
    background-color: #99A9C6;
}

.chatmessages ::-webkit-scrollbar-thumb:hover {
    background-color: #99A9C6;
    cursor: pointer;
}

.chatmessages .message__wrap .dialog_date_message {
    margin: 0 auto;
    background-color: #d9e3f7;
    border-radius: 15px;
    padding: 0px 20px;
    color: #6C7A92;
    font-size: 14px;
}

.chatmessages .messages .showmore {
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    border-radius: 15px;
    color: #0000ff;
    border: 1px solid;
    width: 120px;
    margin-bottom: 20px;
    cursor: pointer;
}

.chatmessages .messages .message__attachments_wtap img {
    cursor: pointer;
}

.bootstrp_color_btn {
    background-color: #4CAF50 !important;
    border-color: #4CAF50 !important;
}

.tag_info_template {
    display: grid;
}

.font_weight_500 {
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 2px;
}

.admindashboard .colR .container .wholesection1 .col .newusers_label {
    color: #000;
    font-weight: 600;
    font-size: 18px;
    border-bottom: 1px solid #666;
}

/*@media screen and (max-width:780px)
{
   .openNavwidth { width:18%; }

   .Container-enlarge { margin: 14px 14px 14px 10%; }
   .Container-shrink { margin: 14px 14px 14px 19%;}

   .sidenav-inner li { padding: 0 10px; }

   .sidenav-outer.closeNavwidth .sidenav-inner li{
       margin-left:5px;
       }  

   .sidenav-inner a {font-size: 14px;}

   #sidenav .sidenav_icon { margin-right: 10px; }

   .fa { font-size:16px!important; }
}*/

.sidenav-outer.openNavwidth .sidenav_icon {
    height: 50px !important;
    float: left;
}

.adminpayout .leftmenu,
.client_mealplan .leftmenu,
.chatmessages .leftmenu {
    width: 30%;
    float: left;
}

.adminpayout .rightmenu,
.client_mealplan .rightmenu,
.chatmessages .rightmenu {
    float: right;
}

.adminpayout .logo img {
    width: 35px;
}

.userdrp-content.true {
    display: initial;
}

.userdrp-content.false {
    display: none;
}

.coach_users .section_box .leftblock.shrink {
    width: 3%;
    float: left;
}

.coach_users .section_box .leftblock .user_header {
    margin-bottom: 10px;
    margin-top: 5px;
}

.coach_users .section_box .leftblock .user_header .p_title {
    float: left;
}

.coach_users .section_box .leftblock .user_data {
    background-color: #fff;
    padding: 10px;
    border-radius: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    clear: both;
}

.coach_users .section_box .leftblock .user_data.active {
    border: 1px solid #226920;
    box-shadow: 2px 3px 25px #51b848;
}

.coach_users .section_box .leftblock .user_data .profile_photo,
.coach_users .section_box .rightblock .user_info .profile_photo {
    min-height: 55px;
    float: left;
}

.coach_users .section_box .leftblock .user_data .profile_photo img,
.coach_users .section_box .rightblock .user_info .profile_photo img {
    width: 50px;
    height: 50px;
}

.coach_users .section_box .leftblock .user_data .profile_info .name,
.coach_users .section_box .rightblock .user_info .profile_info .name {
    font-weight: 500;
}

.coach_users .section_box .leftblock .user_data .profile_program {
    clear: both;
}

.coach_users .section_box .leftblock .user_data .profile_program .category_box {
    font-weight: 500;
    font-size: 14px;
}

.coach_users .section_box .rightblock.shrink {
    width: 96%;
}

.coach_users .section_box .rightblock .topcontent {
    background-color: #fff;
    border-radius: 15px;
    padding: 10px 10px 0 10px;
}

.coach_users .section_box .rightblock .header_box .input_fields {
    margin-top: 10px;
}

.coach_users .section_box .rightblock .header_box .input_fields .inner_label {
    color: #7C7F83;
    text-align: left;
}

.coach_users .section_box .rightblock .header_box .input_fields .input_value {
    font-weight: bold;
    text-align: left;
}

.coach_users .section_box .rightblock .user_info {
    padding-bottom: 20px;
    border-bottom: 1px solid #d3d3d3;
}

.coach_users .section_box .rightblock .tabmenu a {
    padding: 10px 20px;
    color: #7C7F83 !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
}

.coach_users .mealplan_tab .inner_tabmenu a,
.client_mealplan .inner_tabmenu a {
    float: left;
    padding: 10px 20px;
    color: #7C7F83 !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
}

.coach_users .section_box .rightblock .tabmenu .active {
    border-bottom: 2px solid #226920;
}

.coach_users .section_box .rightblock .tabmenu a.active {
    color: #226920 !important;
}

.coach_users .mealplan_tab .day_box {
    margin-top: 30px;
    margin-bottom: 20px;
}

.nutrition_dashboard_col2 {
    padding-top: 14px;
}

.nutrition_dashboard_active_users_div {
    display: grid;
}

.nutrition_dashboard_active_users_div_span {
    padding-top: 13px;
    padding-bottom: 13px;
    position: relative;
    background-color: white;
    margin-bottom: 9px;
    border-radius: 9px;

}

.nutrition_dashboard_active_users_div i {
    float: right;
    font-size: 16px !important;
    padding-top: 5px !important;
    margin-right: 6px;
}

.nutrition_dashboard_active_users_div .c-avatar__status {
    /* position: static;*/
}

.logged-in {
    color: #226920;
}

.nutrition_dashboard_active_users_div_span .logged-in {
    position: absolute;
    left: 27px;
    bottom: 11px;

}

.nutrition_dashboard_active_users_div_span_name {
    font-weight: 600;
    font-size: 13px;
}

.next_appointment_table_coach th {
    width: 36%;
    border-bottom: none;

}

.next_appointment_table_coach {
    margin-top: 22px !important;
}

.next_appointment_table_coach tr {
    border-color: transparent;
}

.desing_back {
    border-radius: 26px;
    background: #73ad2147;
    padding: 10px;
    color: #008021d1;

}

.design_yellow {
    border-radius: 26px;
    background: #e99a002e;
    color: #E99A00;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

}

.design_skyblue {
    border-radius: 26px;
    background: rgb(0 159 225 / 12%);
    color: rgb(0, 159, 225);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;

}

.desing_blue {
    border-radius: 26px;
    background: #218dad24;
    padding: 10px;
    color: #1db9ced1;

}

.desing_purple {
    border-radius: 26px;
    background: #4a21ad24;
    padding: 10px;
    color: #550080bf;

}

.desing_yellow {
    border-radius: 26px;
    background: rgba(233, 154, 0, 0.1);
    padding: 10px;
    color: #E99A00;

}

.nutrition_dashboard_active_users_div img {
    border-radius: 50%;
    height: 34px;
    width: 35px;
    margin-right: 10px;

}

.nutrition_dashboard_col .pos3innericon {
    margin-left: 32px;
}

.coach_users .title_box .leftmenu,
.coach_plans .title_box .leftmenu,
.coach_blog .title_box .leftmenu {
    float: left;
}

.coach_users .title_box .rightmenu,
.coach_plans .title_box .rightmenu,
.coach_blog .title_box .rightmenu {
    float: right;
}

.coach_users .title_box .rightmenu input {
    float: right;
    margin-right: 10px;
    font-family: 'FontAwesome';
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    outline: none;
}

.coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block .block_box {
    margin: 10px 0;
    background-color: #fff;
    border-radius: 15px;
    min-height: 200px;
    padding-left: 10px;
    padding-top: 10px;
}

.coach_users .section_box .rightblock .tabcontent .progress_tab .right_inner_block .block_box {
    margin: 10px 0;
    border-radius: 15px;
    min-height: 200px;
    padding-left: 10px;
    padding-top: 10px;
}

.coach_users .section_box .rightblock .progress_tab .program_box .read_box {
    border: 1px solid lightgray;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
}

.coach_users .section_box .rightblock .progress_tab .program_box .read_box .readcount {
    color: #226920;
    font-weight: bold;
    font-size: 14px;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box {
    display: flex;
    background: #fff;
    border-radius: 15px;
    padding: 10px;
    margin-bottom: 10px;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box .left_box {
    float: left;
    width: 50px;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box .left_box .read {
    margin: 10px;
    background-color: #e4ffe4;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    color: #333;
    font-weight: 500;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box .left_box .read .fa-check:before {
    color: #226920;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box .left_box .unread {
    margin: 10px;
    background-color: lightgray;
    border-radius: 15px;
    width: 30px;
    height: 30px;
    text-align: center;
    padding-top: 3px;
    color: #333;
    font-weight: 500;
}

.coach_users .section_box .rightblock .progress_tab .program_box .blog_box .right_box .title {
    color: #7c7f83;
    font-size: 14px;
}

.coach_users .section_box .rightblock .title_left {
    float: left;
}

.coach_users .section_box .rightblock .title_right {
    color: #7c7f83;
}

.coach_users .section_box .rightblock .labtest_tab .title_right {
    width: 50%;
}

.coach_users .section_box .rightblock .title_right .action_buttons {
    float: right;
}

.coach_users .section_box .rightblock .title_right .action_buttons button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.coach_users .section_box .rightblock .tabcontent .progress_tab .block_box .title_left {
    color: #7c7f83;
    font-weight: 500;
}

.coach_users .section_box .leftblock .users_list.show {
    display: block;
}

.coach_users .section_box .rightblock .tabcontent {
    margin-top: 20px;
    min-height: 500px;
}

.coach_users .section_box .rightblock .tabcontent .notes_tab .pagination {
    margin-top: 10px;
}

/* .coach_users .section_box .rightblock .tabcontent .notes_tab .pagination .pagenumbers{
   display: none;
}

.coach_users .section_box .rightblock .tabcontent .notes_tab .pagination .pagenumbers.pagination_prev, .coach_users .section_box .rightblock .tabcontent .notes_tab .pagination .pagenumbers.pagination_next{
   display: block;
}*/

.coach_users .section_box .rightblock .note_info.active {
    border: 1px solid #226920;
    box-shadow: #51b848;
    background-color: #f1f5f0 !important;
}

.top_coach_label {
    display: block !important;
    /* padding: 0 28px !important; */
}

.top_coach_span {
    margin-right: 69px !important;
    font-size: 18px;
    color: #7c7f83;
    font-weight: 600;
    cursor: pointer;
}

.top_coach_span.active {
    color: #4CAF50 !important;
    border-bottom: 2px solid #4CAF50;

}

.coach_service_div {
    padding: 29px;
}

.add_coach_service_category_popup .modal-content {
    height: 272px;
}
.delete_specialized_in_pop .modal-content {
   height: auto  !important;
}

.service_cat_err {
    margin-top: 11px;
    padding-left: 14px;
    color: red;
}

.coach_service_div .pointer_eff {
    font-size: 14px !important;
}

.coach_users .section_box .leftblock .users_list.hide {
    display: none;
}

.add_service_pop .modal-content {
    height: auto !important
}

.coach_service_div tr>th {
    width: 16%;
}

.coach_service_div tbody {
    border-color: transparent;
}

/* .coach_service_div tr>td {
   width: 20%;
} */

.top_billing_col {
    padding-left: 36px;
    padding-top: 21px;
}

.top_billing_coach_payment {
    background-color: #226920;
    border: none;
    color: white;
    padding: 7px 34px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    float: right;
    border-radius: 13px;
    margin-right: 51px;
    margin-top: 18px;
}

.billing_row {
    padding: 14px;
}

.billing_edit_i {
    font-size: 13px !important;
}

.billing_row tbody {
    border-color: transparent;
}


/*.add_billing_popup .modal-content {
 
   height: 420px !important;
} */

.add_billing_popup .modal-content .rdw-editor-wrapper {
    border: 1px solid #cecece;
}

.add_category_inner_input:read-only {
    border: 1px solid #b0b4cb6b !important;
    cursor: pointer;
}

.billing_row td {
    width: 16%;
}






.coach_users .section_box .notes_tab .rdw-editor-main {
    border: 1px solid lightgray;
    background-color: #ffffff;
    min-height: 300px;
}

.coach_users .section_box .notes_tab .notes_list {
    margin-top: 20px;
}

.coach_users .section_box .notes_tab .notes_list .note_info {
    background-color: #ffffff;
    border-radius: 15px;
    margin: 10px 0px;
    padding: 10px;
    clear: both;
    cursor: pointer;
}

.coach_users .section_box .notes_tab .notes_list .note_info .note_by {
    display: inline-block;
    font-weight: 500;
}

.coach_users .section_box .notes_tab .notes_list .note_info .note_edit_date {
    float: left;
    font-size: 12px;
    color: #7c7f83;
    padding-left: 5px;
}

.coach_users .section_box .notes_tab .notes_list .note_info .note_msg {
    padding: 5px;
}

.coach_users .section_box .notes_tab .notes_list .note_info .note_edit_by {
    float: right;
    font-size: 12px;
    color: #7c7f83;
}

.coach_plans .title_box .rightmenu input {
    margin-left: 20px;
}

.coach_plans .tbody .colorbox {
    display: block;
    width: auto;
}

.coach_blog .tbody .colorbox {
    display: inline;
    width: auto;
}

.coach_plans .tbody td {
    margin: 20px;
}

.coach_plans .tbody {
    vertical-align: middle;
}

.confirm_delete .modal-dialog {
    padding-top: 137px;
}

.coach_payments_top_row {
    display: inline-block !important;
    padding-left: 1%;
    margin-top: 18px !important;
}

.coach_payments_top_row span {
    font-size: 18px;
    color: #7c7f83;
    cursor: pointer;
    border-bottom: 2px solid #cecece;
    font-weight: 500;
    padding: 10px 20px;
}

.coach_payments_top_row span.active {
    color: #226920 !important;
    border-bottom: 2px solid #226920;
}

.payment_inner_top_row {
    padding-top: 32px;
    padding-left: 17px;
    height: auto;
}

.payment_earning_top {
    background-color: white;
    border-radius: 6px;
    padding: 10px;
    height: 125px;
}

.chart_top_label {
    padding-bottom: 18px;
    font-size: 17px;
    color: black;
    font-weight: 600;


}

.payment_select_drop {
    float: right;
}

.second_section_table {
    padding-left: 5px;
    display: block !important;
    margin-top: 20px;
}

.second_section_payment_span {
    color: black;
    font-weight: 600;
}




.export_payment_button {
    float: right !important;
    position: static;
    width: 135px !important;
}

.export_doc_button {
    float: right !important;
    position: static;

}

.table_row_payment_history {
    margin-top: 27px;
}

.table_row_payment_history tbody {
    border-color: transparent;
}

.required::after {
    content: "*";
    color: red;
}

.questionnaire_top_row {
    padding: 19px;
}

.questionnaire_top_button {
    float: right;
}


.top_span_questionnaire_head {
    font-size: 21px;
    font-weight: 500;
}

.questionnaire_top_button {
    margin-top: 0px;

}

/* .questionnaire_name_col{
   width: 30%;
} */

.top_quesionnaire_row {
    padding: 18px
}

.edit_tools {
    padding: 18px
}

/* .questionnaire_but_col{
   width: 30%;
} */

.coach_food_plans .plan_data {
    background-color: #fff;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    margin-top: 20px;
}

.coach_food_plans .plan_data .left_block {
    width: 50%;
    float: left;
}

.coach_food_plans .plan_data .right_block {
    width: 50%;
}

.coach_food_plans .plan_data .left_block .title,
.coach_food_plans .plan_data .right_block .title {
    font-weight: 500;
    margin-bottom: 10px;
}

.coach_food_plans .plan_data .right_block .goal_chart {
    width: 60%;
}

.coach_food_plans .plan_data .right_block .goal_chart div {
    float: left;
    display: block;
}

.coach_food_plans .plan_data .right_block .goal_chart .input {
    display: block;
    height: 12px;
    border-radius: 10px;
}

.coach_food_plans .plan_data .right_block .goal_chart .label {
    font-size: 12px;
}

.coach_food_plans .plan_data .right_block .goal_chart .carb_chart {
    width: 60%;
}

.coach_food_plans .plan_data .right_block .goal_chart .carb_chart .input {
    border: 1px solid #51b848;
    /* background-color: #51b848;  */
}

.coach_food_plans .plan_data .right_block .goal_chart .carb_chart .label {
    color: #51b848;
}

.coach_food_plans .recipes_library .recipes,
.coach_users .mealplan_tab .recipes_library .recipes {
    margin-top: 20px;
}

.coach_food_plans .selected_recipe .recipes,
.client_mealplan .selected_recipe .recipes {
    margin-top: 0;
}

.coach_food_plans .recipes .recipe_box,
.coach_users .mealplan_tab .recipes .recipe_box,
.client_mealplan .recipes .recipe_box,
.client_weekplan .favorites_tab .recipes .recipe_box {
    float: left;
    height: 140px;
    width: 150px;
    overflow: hidden;
    margin-top: 10px;
    padding: 5px;
    position: relative;
}

.client_weekplan .dietplan_tab .recipes .recipe_box {
    float: left;
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-top: 10px;
    padding: 5px;
    position: relative;
}

.client_weekplan .dietplan_tab .mob_recipes .recipe_box,.client_mealplan .mob_recipes .recipe_box {
    float: none !important;
    width: auto;
    /* min-height: 230px !important; */
}

.client_weekplan .dietplan_tab .mob_recipes.responsiveTable td .tdBefore,.client_mealplan .mob_recipes.responsiveTable td .tdBefore {
    top: 50%;
}

.coach_food_plans .recipes .img,
.coach_users .mealplan_tab .recipes .img,
.client_mealplan .recipes .img,
.client_weekplan .favorites_tab .recipes .img {
    height: 90px;
    display: flex;
    /* cursor: pointer; */
}

.client_weekplan .dietplan_tab .recipes .img {
    height: 130px;
    display: flex;
    /* cursor: pointer; */
}

.coach_food_plans .recipes img,
.coach_users .mealplan_tab .recipes img,
.client_mealplan .recipes img,
.client_weekplan .favorites_tab .recipes img {
    width: 135px;
    display: flex;
}

.client_weekplan .dietplan_tab .recipes img {
    width: 200px;
    display: flex;
}

.coach_food_plans .recipes .title,
.coach_users .mealplan_tab .recipes .title,
.client_mealplan .recipes .title,
.client_weekplan .favorites_tab .recipes .title,
.client_weekplan .dietplan_tab .recipes .title {
    font-weight: 500;
    font-size: 14px;
}

.coach_food_plans .recipes .viewdata,
.coach_users .mealplan_tab .recipes .viewdata,
.client_mealplan .recipes .viewdata,
.client_weekplan .favorites_tab .recipes .viewdata,
.client_weekplan .dietplan_tab .recipes .viewdata {
    position: absolute;
    z-index: 0;
    right: 15px;
    top: 10px;
    color: #333 !important;
    background-color: #fff;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
}

.client_weekplan .favorites_tab .recipes .viewheart,
.client_weekplan .dietplan_tab .recipes .viewheart {
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 10px;
    color: #333 !important;
    background-color: #fff;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
    padding-top: 0;
}

.client_weekplan .favorites_tab .recipes .viewheart::before,
.client_weekplan .dietplan_tab .recipes .viewheart::before {
    font-size: 12px;
    color: lightcoral;
}

.client_weekplan .favorites_tab .recipes .viewheart.fav::before,
.client_weekplan .dietplan_tab .recipes .viewheart.fav::before {
    color: red;
}

.client_weekplan .favorites_tab .rightmenu .pagination {
    margin-top: 10px;
}

.list_questionnaire_row {
    padding: 19px;
}

.add_ques_err {
    margin-top: 14px;
    color: red;
    font-weight: 500;
}

.add_ques_succ {
    margin-top: 14px;
    color: rgb(94, 190, 94);
    font-weight: 500;
}

.send_qu_icon {
    margin-left: 6px;
}

.btn:focus,
.btn:active {
    outline: none !important;
    box-shadow: none !important;
}

.save_button_commission.btn {
    border-color: transparent !important;
}

.list_questionnaire_row tbody {
    border-bottom: transparent;
}



.coach_food_plans .plan_data .right_block .goal_chart .pro_chart {
    width: 20%;
    margin-left: -2px;
}

.coach_food_plans .plan_data .right_block .goal_chart .pro_chart .input {
    border: 1px solid orange;
    border-left: 0;
    /*background-color: orange;*/
}

.coach_food_plans .plan_data .right_block .goal_chart .pro_chart .label {
    color: orange;
}

.coach_food_plans .plan_data .right_block .goal_chart .fat_chart {
    width: 20%;
    margin-left: -2px;
}

.coach_food_plans .plan_data .right_block .goal_chart .fat_chart .input {
    border: 1px solid purple;
    border-left: 0;
    /*background-color: purple;*/
}

.coach_food_plans .plan_data .right_block .goal_chart .fat_chart .label {
    color: purple;
}

.coach_food_plans .day_box,
.coach_users .mealplan_tab .day_box,
.client_mealplan .day_box {
    background-color: #ffffff;
    margin-top: 20px;
}

.coach_food_plans .day_box .box,
.coach_users .mealplan_tab .day_box .box {
    width: 100px;
    text-align: center;
    margin: 20px;
}

.coach_food_plans .day_box .box .card,
.coach_users .mealplan_tab .day_box .box .card,
.client_mealplan .day_box .box .card {
    cursor: pointer;
}

.coach_users .mealplan_tab .day_box .box .card,
.client_mealplan .day_box .box .card {
    width: 70px;
    text-align: center;
    margin: 10px;
}

.coach_food_plans .day_box .box .day,
.coach_users .mealplan_tab .day_box .box .day,
.client_mealplan .day_box .box .day {
    font-weight: 500;
}

.coach_food_plans .day_box .box .card.active,
.coach_users .mealplan_tab .day_box .box .card.active,
.client_mealplan .day_box .box .card.active {
    color: #ffffff;
    background-color: #51b848 !important;
}

.coach_food_plans .day_box .left_arrow,
.coach_food_plans .day_box .right_arrow,
.coach_users .mealplan_tab .day_box .left_arrow,
.coach_users .mealplan_tab .day_box .right_arrow,
.client_mealplan .day_box .left_arrow,
.client_mealplan .day_box .right_arrow {
    margin: 20px;
    padding: 12px;
    cursor: pointer;
}

.coach_food_plans .day_box .left_arrow::before,
.coach_users .mealplan_tab .day_box .left_arrow::before,
.client_mealplan .day_box .left_arrow::before {
    content: "<";
}

.coach_food_plans .day_box .right_arrow::before,
.coach_users .mealplan_tab .day_box .right_arrow::before,
.client_mealplan .day_box .right_arrow::before {
    content: ">";
}

.coach_food_plans .recipes_library,
.coach_users .mealplan_tab .recipes_library {
    margin-top: 20px;
    border-bottom: 1px solid lightgray;
}

.coach_plan_recipe_modal_popup .recipe_data .title {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
}

.coach_documents_top_row {
    display: inline !important;
    padding-left: 17px;
}

.coach_documents_top_span {
    font-size: 19px;
    color: #7c7f83;
    font-weight: 500;
}

.caoch_doc_span.active {
    font-size: 18px !important;
}

.serviceagreement_table {
    padding-top: 29px;
}

.serviceagreement_table th {
    width: 45% !important;
}

.icon_tag_size {
    font-size: 15px !important;
}

.serviceagreement_table tbody {
    border-color: transparent;
}

/*.add_service_agreement_popup .modal-content{
   width: 153%;
}

.add_service_agreement_popup .modal-content {
   height: 488px !important;
}

.preview_service_agreement_popup .modal-content{
   width: 153%;  
}

.preview_service_agreement_popup .modal-content{
   height: auto !important;  
}*/

.eye_icon {
    margin-left: 8px;
}

.react-form-builder-preview.float-left.is-editing .form-group {
    margin-bottom: 20px;
}

.react-form-builder-preview.float-left.is-editing .form-group label {
    padding-left: 2px
}

.account_setting_row_top {
    margin-top: 44px !important;
    padding-left: 20px;
}

.top_label_account_settings {
    font-size: 20px;
    font-weight: 500;
}

.save_profile_button {
    float: right;
    background-color: #4CAF50;
    /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
}

.save_button_profile {
    margin-top: 0px !important;
    padding: 4px 28px !important;
    margin-right: 0px !important
}

.inner_profile_label {
    font-weight: 500;
    font-size: 13px;
    margin-top:10px;
}

.profile .add_category_inner_input[type=text]{
    margin-top:0 !important;
}

.inner_profile_label_top {
    font-weight: 500;
}

.second_innerprofile_row {
    margin-top: 27px !important;
}

.inner_col_style_profile {
    background-color: white;
    padding-top: 23px;
    padding-bottom: 23px;
    border-radius: 10px;
    margin-right: 18px;
    border: 1px solid #e2e2e2;
}

.old_password_row {
    margin-top: 22px !important;
}

.old_password_input {
    width: 90% !important;
    margin-left: 11px;
}

.profile_overall {
    background-color: #f5f5f57d !important;
    padding-top: 2px !important;
}

.profile_input {
    background-color: #d3d3d359;
}

.profile .pass_err {
   color: red;
   border: 1px solid red;
   border-radius: 20px;
   padding: 5px;
   text-align: center;
   clear: both;
}

.profile_photo .lkjDVC {
    min-inline-size: auto;
    height: 160px;
    border: dashed 2px #4CAF50;
    background-color: #0080000d;
}

.profile_photo .lkjDVC .file-types {
    overflow: inherit;
}

.info_profile {
    font-size: 13px !important;
    padding-left: 7px;
    cursor: pointer;
}


/* .coach_plan_recipe_modal_popup .recipe_data .nutrition_data div{
   width: 85px;
   float: left;
   display: flex;
   border: 1px solid lightgray;
   padding: 10px;
   border-radius: 15px;
   margin: 5px;
   font-weight: 500;
} */


.coach_food_plans .recipes_library .leftmenu .title,
.coach_users .mealplan_tab .recipes_library .leftmenu .title {
    float: left;
}

.coach_food_plans .recipes_library .rightmenu,
.coach_users .mealplan_tab .recipes_library .rightmenu {
    float: right;
    display: flex;
}

.coach_food_plans .recipes_library .rightmenu input,
.coach_users .mealplan_tab .recipes_library .rightmenu input {
    margin-left: 10px;
    border-radius: 15px;
}

.coach_food_plans .plan_data .right_block .goal_chart .goal_over {
    border: 1px solid red !important;
    background: red !important;
}

.delete_account_but {
    width: 75% !important;
    margin-left: 63px !important;
    margin-top: 45px !important;
    font-weight: 500 !important;
}

.overall_service_top_menu {
    border-bottom: 2px solid lightgray;
    padding-bottom: 1px;
}

.service_top_row {
    width: 26% !important;
    padding-left: 0px !important;
}

.service_top_row_button {
    padding: 10px 6px !important;
}


.service_over_div {
    padding-top: 0px !important;
}

.caoch_doc_row {

    padding-left: 29px !important;
    padding-right: 0px !important;
    padding-bottom: 0px !important;
    padding-top: 18px !important;
    width: 46%;

}

.agreement_top {

    display: contents !important;
}

.client_food_diary_section {
    background-color: #80808021;
    padding: 13px;
    height: 2000px;
}

.client_food_diary_top_label {
    font-size: 19px;
    font-weight: 500;
}

.client_food_diary_first_row {
    margin-bottom: 40px;
    display: block;
}

.client_food_diary_first_row .react-datepicker-wrapper {
    width: 94%;

}

.client_food_diary_first_row .react-datepicker-wrapper input {
    float: right
}

.client_food_diary_first_row .react-datepicker__month-container {
    float: right
}

.react-datepicker-popper {
    left: 44% !important;
}

.pick_date_apppointment_by_client .react-datepicker__input-container input {
    border: 1px solid #7c7f83;
    background-color: #d3d3d359;
}



.pick_date_apppointment_by_client .react-datepicker-popper {
    left: 0% !important;
}

.client_food_diary_first_row span {
    width: 20%;
}

.client_food_diary_second_row {
    height: 600px;
}

.client_food_diary_second_row canvas {

    height: 300px !important;
    width: 300px !important;
    margin-top: 30px;
    margin-left: 88px;

}

.nutrition_pie_chart {
    background-color: white;
    padding-top: 37px;
    border-radius: 16px;
}

.pie_chart_heading {
    font-size: 26px;
    font-weight: 500;
    margin-top: 8px;
    text-align: center;
}

.pie_chart_bottom_div {
    display: grid;
    padding-left: 20px;
    padding-right: 20px;
    font-weight: 500;
}

.dotcarbs,
.dotfat,
.dotprotein {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: inline-block;
}

.dotcarbs {
    background-color: #226920;
}

.dotfat {
    background-color: #009fe1;
}

.dotprotein {
    background-color: #c71a35;
}

.dotcalories {
    height: 25px;
    width: 25px;
    background-color: rgb(0, 0, 0);
    border-radius: 50%;
    display: inline-block;
}

.pie_chart_bottom_div_label {
    font-size: 18px;
    padding-left: 20px;
}

.pie_chart_bottom_div_data {
    font-size: 23px;
    float: right;
}

.pie_chart_bottom_div_data span {
    padding-right: 9px;
    font-size: 18px;
}

.food_diary_info_right {
    background-color: white;
    margin-left: 17px;
    border-radius: 16px;
}

.food_diary_info_right_first_row {
    display: block !important;
}

.food_diary_info_right_row table {
    border-bottom: transparent;

}

.food_diary_info_right_first_row {
    margin-left: 27px !important;
}



.food_diary_info_right_first_row span {
    font-size: 15px;
    font-weight: 500;
    margin-left: 40px;
}

.food_diary_info_right_second_row {
    display: block;
}

.food_diary_info_right_second_row .heading {
    font-size: 10px;
    font-weight: 500;
}

.daily_goal_row {
    background-color: rosybrown !important;
}

.add_food_info {
    line-height: 50px;

}

.add_food_button_progress {
    float: right !important;
    background-color: #226920 !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    border-color: transparent !important;
}

.add_food_info {
    margin-top: 42px !important;
}

.add_food_info span {
    font-weight: 500;
}

.reciepe_logo_food_diary {
    width: 50px;
    height: 38px;
}

.reciepe_logo_food_diary_list {
    width: 65px;
    margin-left: 7px;
    height: 55px;

}

.add_food_info table {
    border-bottom: transparent !important;
}

.food_diary_modal_top_row {
    display: block !important;
    /* border-bottom: 1px solid; */
}

.row.food_diary_modal_top_row span {
    padding-right: 5px !important;
    font-weight: 500;
    cursor: pointer;
}

.row.food_diary_modal_top_row span.active {
    color: #226920;
    border-bottom: 1px solid #226920;
    padding-bottom: 2px;
}

.search_library_food_row {
    margin-top: 29px !important;
    display: block !important;
}

.search_library_food_row label {
    float: left;
    width: auto;
}

.search_library_food_row input {
    width: 50% !important;
}

.search_library_food_row button {
    width: 100px;
    margin-right: 25px;
    margin-top: 2px;
}


.recipe_list_row_food_diary {
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
}

.recipe_list_row_food_diary_heading {
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.food_diary_popup .modal-content {
    min-height: 523px;
    height: auto !important;
}

.coach_users_labtest_addbiomarkers_popup .modal-content {
    min-height: 300px;
    height: auto !important;
}

/* .food_diary_popup .modal-dialog {
    margin-left: 284px;
} */

.library_list_food_diary_ul {
    list-style: none;
    margin-top: 28px;
    padding-left: 0 !important;
}

.library_list_food_diary_ul_span {
    font-size: 17px;
    font-weight: 500;
    margin-left: 6px;
    display: inline-block;
    width: 225px;
}

.library_list_food_diary_ul_kcal {
    margin-left: 2px;
    font-size: 15px;
    font-weight: 500;
    float: right;
    padding-top: 14px !important;

}

.library_list_food_diary_ul_kcal_i {
    color: #ff5200e6 !important;
    margin-right: 7px;
}

.library_list_food_diary_ul li {
    margin-bottom: 12px;
    display: inline-block;
    width: 95% !important;
}

.table_list_span {
    padding-left: 4px;
}

.rosy_blue {
    color: #dd3d2c;
}


.react-datepicker__input-container input {
    box-sizing: border-box;
    border: 3px solid #ccc;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    outline: none;
    border-radius: 9px;
    caret-color: transparent;
    cursor: pointer;
    font-weight: 500;
}


.add_list_col {
    width: 30% !important;
    margin-right: 9px !important;
}

.chart_col {
    width: 68% !important;
    background-color: white;
}

.add_progress_button {
    width: 19% !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
}

.add_list_col_span {
    width: 80% !important;
    padding-top: 6px;
    color: black;
    font-weight: 500;
}

.add_list_col_row {
    margin-bottom: 9px;
    background-color: white;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 7px;
}

.chart_top_label_progres_diary {
    text-align: center;
    color: #7c7f83;
    font-weight: 500;
}

.chart_row {
    padding-top: 2px;
    border: 2px solid #80808036;
    border-radius: 11px;
    width: 90% !important;
    margin-left: 42px !important;
    margin-bottom: 18px !important;
    background-color: #0080000d;
    ;
}

.inner_progress_data {
    padding-top: 15px;
    color: #7c7f83;
    font-weight: 500;
}

.trash_icon_inner_progress {
    float: right;
    padding-top: 5px;

}

.add_button_progress {
    background-color: #226920 !important;
    border-color: #226920 !important;
}

.actionmsg {
    color: darkblue;
    padding: 5px;
}

.chart_payment_col {
    background-color: white;
    border-radius: 17px;
    padding-top: 12px;
}

.coach_blog_modal_popup .texteditor {
    border: 1px solid lightgray;
}

.top_row_heading_client_dashboard {
    font-size: 20px;
    font-weight: 500;
}

.todays_activity_button {
    background-color: black !important;
    border-color: black !important;
}

.view_activity_button {
    background-color: #226920 !important;
    border-color: #226920 !important;
}

.top_button_col button {
    float: right;
}

.view_activity_button {
    /*margin-left: 40px;*/
    float: right;
}

.client_dashboard_bar {
    width: 50% !important;
}

.client_dashboard_bar_row {
    margin-top: 39px !important;
}

.client_dashboard_bar_inner {
    height: 140px;
    padding-top: 44px;
}

.calories_info_client_dashboard {
    background-color: white;
    border-radius: 11px;
    padding-top: 6px;
}

.calories_info_client_dashboard .calories_remaining {
    color: #808080ab;
    font-weight: 500;
    margin-right: 6px;
}

.calories_info_client_dashboard .change_a_tag {
    font-weight: 500;
    font-size: 16px;
    color: rgb(0, 159, 225);
    text-decoration: none;
    margin-right: 10px;
}

.calories_remaining_number {
    font-size: 24px;
    color: #4CAF50;
    font-weight: 500;
}

.calories_info_client_dashboard_frow {
    display: inline-block !important;
}

.calories_info_client_dashboard_srow span {
    width: 20% !important
}

.calories_info_client_dashboard_srow .equal {
    width: 1% !important;
}

.calories_info_client_dashboard_srow .food {
    width: 13% !important;
}

.calories_info_client_dashboard_srow {
    margin-top: 8px !important;
}

.calories_info_client_dashboard_srow .label {
    color: #7c7f83;
    font-weight: 500;
}

.rosy_goal {
    background-color: #bc8f8f26;
    border-radius: 7px;
}

.calories_info_client_dashboard_srow .black {
    color: black;
}

.calories_info_client_dashboard_srow .yellow {
    color: #ff9800;
}

.calories_info_client_dashboard_srow .sky_blue {
    color: rgb(0, 159, 225);
}

.calories_info_client_dashboard_srow .purple {
    color: #673ab7;
}

.calories_info_client_dashboard_srow .green {
    color: #4CAF50;
}

#progressbar {
    background-color: #00000012;
    border-radius: 13px;
    /* (height of inner div) / 2 + padding */
    padding: -1px;
    width: 70%;
    margin-top: 13px;
}

#progressbar>div {
    background-color: #673ab7;
    width: 20%;
    /* Adjust with JavaScript */
    height: 12px;
    border-radius: 10px;
}

.report_info_row {
    margin-top: 35px !important;
}

.circle_info_progress_info {
    padding-left: 22px;
}

.circle_info_progress_info .label_top {
    font-size: 15px;
    font-weight: 500;
}

.circle_info_progress_info select {
    padding: 0px 9px !important;
    font-size: 15px;
}

.circle_info_progress_info_col {
    background-color: white;
    border-radius: 9px;
    padding-top: 18px;
    margin-right: 12px;
    padding-bottom: 10px;
}

.client_circle_progress {
    margin-top: 20px;
}

.calories_progress .CircularProgressbar-path {
    stroke: #ff9800 !important;
}

.calories_progress .CircularProgressbar-text {
    fill: #ff9800 !important;
}


.fat_progress .CircularProgressbar-path {
    stroke: #009FE1 !important;
}

.fat_progress .CircularProgressbar-text {
    fill: #ff9800 !important;
}

.carb_progress .CircularProgressbar-path {
    stroke: #226920 !important;
}

.carb_progress .CircularProgressbar-text {
    fill: #226920 !important;
}

.protein_progress .CircularProgressbar-path {
    stroke: #C71A35 !important;
}

.protein_progress .CircularProgressbar-text {
    fill: #e91e63 !important;
}

.progress_low_info {
    display: block !important;
    font-size: 9px;
    font-weight: 500;
    margin-top: 19px !important;
    color: #7c7f83;
}

.progress_low_info .target {
    margin-right: 10px;
}

.green {
    color: #4CAF50;
}

.yellow {
    color: #ff9800
}

.purple {
    color: #673ab7
}

.rose {
    color: #e91e63
}

.light_blue {
    color: #009FE1;
}

.dark_rose {
    color: #C71A35;
}

.client_next_appointment_dashboard {
    width: 70% !important;
}

.client_message_dashboard {
    width: 30% !important;
}

.client_dash_last_row {
    margin-top: 20px !important;
    padding-left: 12px !important;
}

.client_message_dashboard_inner {
    margin-bottom: 20px;
}

.client_message_dashboard_inner span a {
    text-decoration: none;
    color: #226920;
}

.client_message_dashboard_inner span a:hover {
    color: #226920;
}

.client_message_dashboard_inner .titlebox {
    float: left;
}

.client_message_dashboard_inner .viewall {
    font-weight: 600;
    float: right;
}

.client_next_appointment_dashboard td {
    border-radius: 0px !important;
    background-color: white !important;
}

.client_next_appointment_dashboard tbody {
    line-height: 44px !important;
}

.msg_row_client {
    display: block;

}

.msg_row_client .time {
    float: right;
}

.client_msg_div {
    margin-bottom: 19px;
    background-color: white;
    border-radius: 2px;
}

.msg_row_client .msg {
    padding-left: 40px;
}

.clientdashboard .client_message_dashboard .client_msg_div .msg {
    display: block;
    padding-left: 38px;
    font-size: 14px;
}

.clientdashboard .client_message_dashboard .inner_msg_div .name {
    width: 150px;
    display: inline-block;
    padding-right: 10px;
}

.clientdashboard .client_message_dashboard .inner_msg_div .fromhours {
    font-size: 12px;
}

.client_top_select_date {
    margin-right: 22px;
    margin-top: 2px;
}

.client_mealplan .coach_box {
    margin-top: 20px;
}

.client_weekplan .dietplan_tab .topcontent {
    background-color: #fff;
    padding: 10px;
    border-radius: 15px;
}

.client_weekplan .dietplan_tab .topcontent .inner_label {
    font-weight: 500;
    border-bottom: 1px solid lightgray;
    padding-bottom: 10px;
}

.client_weekplan .dietplan_tab .topcontent .inner_label .td_icon.action {
    float: right;
    background-color: #226920;
    border-radius: 15px;
    padding-bottom: 5px;
    padding-left: 3px;
    padding-right: 3px;
    cursor: pointer;
}

.client_weekplan .dietplan_tab .topcontent .inner_label .td_icon.action::before {
    color: #ffffff;
    margin: 5px;
}

.client_weekplan .dietplan_tab .topcontent .input_value {
    font-weight: bold;
    margin-top: 5px;
}

.client_weekplan .dietplan_tab .topcontent .header_box .input_fields {
    margin-top: 20px;
}

.client_weekplan .dietplan_tab .plan_filter_box {
    margin-top: 40px;
}

.keto_book_top_label {
    font-size: 28px;
    font-weight: 500;
}

.keto_book_info_col {
    width: 50% !important;
}

.keto_book_image_col {
    width: 50% !important;
}

.keto_book_info_col_head {
    font-size: 36px;
    font-weight: 500;
}

.keto_book_image_col img {
    width: -webkit-fill-available;
    border-radius: 12px;
}

.keto_book_img_info_row {

    margin-top: 58px !important
}

.keto_e_book_paragraph {
    color: #808080d1;
    font-weight: 500;
    font-size: 15px;
    margin-top: 20px;

}

.keto_link_button {
    background-color: #226920 !important;
    border-color: #226920 !important;
}

.target_goal_activity {
    float: right;
    font-weight: 500;
    padding-right: 13px;
    font-size: 11px;
    padding-top: 3px;
}

.client_mealplan .coach_box {
    margin-top: 20px;
}

.activity_left_label {
    color: #7c7f83 !important;
    font-weight: 500;
}

.changed_activity_row {
    margin-top: 16px !important;
    margin-bottom: 16px !important;
}

.changed_activity_row {
    background-color: #4caf501f;
    width: 94%;
    margin-left: 6px !important;
    border-radius: 7px;
    min-height: 33px;
    padding-top: 3px;
    color: #226920;
    font-weight: 500;
}

.changed_activity_row .value {

    float: right;
}

.low_level_activity_info {
    margin-top: 8px;
    padding-left: 25px;
    font-weight: 500;
}

.low_level_activity_value {
    margin-top: 8px;
    padding-left: 20px;
    font-size: 15px;
    color: #7c7f83;
    padding-top: 10px;
    padding-bottom: 10px;

}

.bar_div {
    padding-right: 17px;
}

.kcal_activity {
    margin-left: 17px;
}

.pie_chart_row_activity {
    width: 207px;
    margin-left: 8px;
    padding-bottom: 16px;
    border-bottom: 2px solid #7c7f83;
}

.micro_label {
    margin-bottom: 10px !important;
}

.dotcarbsactivity {
    height: 10px !important;
    width: 10px !important;
}

.dotcarbsactivitylabel {
    font-size: 15px !important;
}

.activity_dot_info {
    display: grid;
    margin-top: 10px;
    padding-left: 15px;
    line-height: 2.0;
    font-weight: 500;
}

.activyt_dot_data {
    float: right;
    padding-right: 21px;
}

.activities_date_range_row h5 {
    width: 50% !important;
}

.activities_date_range_row span {
    width: 20% !important;
    display: contents !important;
    float: right !important;
}

.activities_main_row {
    min-height: 244px;
    background-color: white;
    margin-left: 2px !important;
    border-radius: 13px;
    width: 100%;
    padding: 7px;
}

.top_level_select_week {

    height: 36px !important;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 7px !important;
}

.top_level_select_week .date_pick {
    float: right;
}

.date_pick .arrow {
    padding-left: 6px;
    cursor: pointer;
}

.top_level_row_week_heading {
    margin-bottom: 10px !important;
    height: 50px;
    padding-left: 75px !important;
    display: flex;
    gap: 2.0rem;
}

.shrink .top_level_row_week_heading{
   gap: 40px !important;
   padding-left: 88px !important;
}

.numberCircle {
    border-radius: 50%;
    width: 29px;
    height: 32px;
    padding-top: 6px;
    background: #009fe1;
    color: white;
    text-align: center;
    font-size: 12px;
}

.top_level_row_week_heading_data {
    margin-top: -67px !important;
}

.col_activity_label {
    width: 14% !important;
    display: grid !important;
    font-size: 9px;
    font-weight: 600;
    padding-top: 30px;
}

.col_activity_tick {
    width: 84% !important;
    padding-top: 30px;
}

.second_tick {
    margin-top: 25px !important;
}

.col_activity_tick i {
    color: #4CAF50 !important;
    width: 7% !important;
    /* margin-right: 34px; */
}

.col_activity_tick .lastone {
    margin-right: 0px !important;
}

.col_activity_tick .second_row {

    margin-top: 17px;
    padding-top: 5px;

}

.col_activity_tick .third_row {
    padding-top: 26px;
}

.col_activity_tick .row {
    min-height: 23px;
    display: flex;
    gap: 2rem;
}

.top_row_heading_subscription {
    font-size: 22px;
    font-weight: 500;

}

.subscription_plan_row {
    margin-top: 40px !important;
}

.subscription_plan_row .plan_col {
    min-height: 300px;
    background-color: white;
    border-radius: 15px;
    margin-bottom: 11px;
    margin-right: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid #d3d3d3;
}

.subscription_plan_row .plan_col.active{
   border: 3px solid #226920;
}

.p_tag_label_top_subs {
    border-bottom: 1px solid #8080806e;
    /* padding-left: 87px; */
    font-size: 15px;
    font-weight: 500;
    text-align: center;
}

.plan_col .amount_row {
    min-height: 100px;
    padding: 17px;
    text-align: center;
}

.plan_col .amount_value {
    min-height: 43px;
    font-size: 30px;
    padding-left: 44px;
    padding-right: 44px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #7839ff17;
    border-radius: 17px;
    color: #7839ff;
    font-weight: 500;
}

.plan_col .list-unstyled li:before {
    content: '✓';
    margin-right: 13px;
    font-size: 14px;
}

.plan_col .list-unstyled li {
    /* padding-left: 40px; */
    color: #7c7f83;
    font-weight: 500;
    margin-bottom: 10px;

}

.plan_col .plan_info {
     min-height: 150px; 
    text-align: center;
}

.plan_col .due_date {
    color: #7c7f83;
    font-weight: 500;
    text-align: center;
}

.notes_by_coach_top_row_heading {
    font-size: 20px;
    font-weight: 500;
}

.table_section_row_client_notes {
    padding-left: 4px !important;
    margin-top: 22px !important;

}

.view_blog_list_eye_icon {
    font-size: 15px !important;
    cursor: pointer;
}

.blog_pagination {
    float: right;
}

.blog_title_top_row {
    padding-left: 10px;
    font-size: 19px;
    font-weight: 500;
    /* display: initial !important; */
    margin-top: 24px !important;
    background-color: #fff;
}

.blog_content_row {
    padding-left: 11px;
}

.blog_title_top_row .title {
    float: left;
    width: 85%;
    padding: 10px;
}

.blog_title_top_row .x_mark {
    float: right;
    cursor: pointer;
    width: auto;
    color: #999;
}

.blog_title_top_row .x_mark .fa {
    border: 1px solid;
    border-radius: 20px;
    padding: 1px;
}

.client_weekplan .library_tab .filter_reciepe_button {
    width: 140px !important;
    float: left;
}

.library_top_row_meal_plan {
    margin-top: 20px;
    padding-left: 13px;
}

/* .search_reciepe_div {
    width: 84% !important;
} */

.search_reciepe_div input {
    width: 50% !important;
    float: right;
}

.search_reciepe_div button {
    float: right;
    margin-left: 20px;
    height: 36px;
    margin-top: 3px;
    background-color: #226920 !important;
}

.library_content_button_row {
    margin-top: 20px !important;
}

.pagination_row_library_search {
    float: right;
    padding-top: 20px !important;
}

.sidepanel {
    padding-left: 20px;
    position: absolute;
    z-index: 1;
    width: 0px;
    left: -100%;
    top: 250px;
    background-color: #f4fdf3;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    border-radius: 22px;
    height: 500px;
    border: 2px solid #7c7f83;
}

.sidepanel::-webkit-scrollbar {
    display: none;
}

.sidepanel {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.admin_table_div_analytics::-webkit-scrollbar {
    display: none;
}

.admin_table_div_analytics {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}



.sidepanel a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidepanel a:hover {
    color: #51b848;
}

.sidepanel .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
}

.reset_filter_button,
.apply_filter_buttoncss,
.clear_filter_button {
    background-color: #226920;
    /* Green */
    border: none;
    color: white;
    padding: 2px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
}

.two_col_check {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    width: 90%;
}

.two_col_check span {
    flex: 0 0 100%;
}

.two_col_check div#checkboxes {
    flex: 0 0 50%;
}

.sidepanel .ingtitle {
    background-color: #80808042;
    border-radius: 11px;
    /* padding-left: 115px; */
    margin-bottom: 20px;
    text-align: center;
}

.reset_filter_button {
    width: 30%;
    border-radius: 4px;
}

.apply_filter_button {
    width: 30%;
    border-radius: 4px;
}

.clear_filter_button {
    width: 30%;
    border-radius: 4px;
}

.meal_type_error {
    padding-left: 20px;
}

.client_weekplan_ing_modal_popup .changeingredients .meatcheckbox,
.client_weekplan_ing_modal_popup .changeingredients .vegcheckbox,
.client_weekplan_ing_modal_popup .changeingredients .otherscheckbox {
    display: inline-block;
    margin-top: 10px;
}

.client_weekplan_ing_modal_popup .changeingredients .meatcheckbox .ingtitle,
.client_weekplan_ing_modal_popup .changeingredients .vegcheckbox .ingtitle,
.client_weekplan_ing_modal_popup .changeingredients .otherscheckbox .ingtitle {
    display: block;
    font-weight: bold;
}

.client_weekplan_ing_modal_popup .changeingredients #checkboxes {
    float: left;
    margin: 10px;
    display: block;
}

.add_member_coach_heading {
    font-size: 19px;
    font-weight: 500;
}

.input_rows_add_client {

    margin-top: 20px !important;
}

.half_width_col {
    width: 50% !important;
}

.gender_row_add_client {
    width: 50% !important;
    line-height: 2.5;
}

.gender_row_add_client input {
    margin-right: 12px;
}

.add_member_button_row button {
    width: 20% !important;
    float: right;
    background-color: #226920;
    border: #226920;
}

.contact_submit_button{
   background-color: #226920 !important;
   border: #226920 !important;
   margin-left: 20%;
   width: 150px;
   margin-top: 20px;
}

.add_member_button_row button:hover, .contact_submit_button:hover {
    background-color: #226920;
    border: #226920;
}

.add_member_button_row button:focus, .contact_submit_button:focus {
    background-color: #226920;
    border: #226920;
}

.err_msg_add_client {
    color: red;
    font-weight: 500;
}

.client_top_appointment_row_span {
    font-size: 19px;
    font-weight: 500;
}

.upcoming_appointment_table_row {
    margin-top: 25px !important;
    background-color: white;
    border-radius: 20px;
    padding-top: 20px;
    padding-left: 20px;
}

.upcoming_appointment_table_row_span {
    margin-bottom: 20px;
}

.upcoming_appointment_table>thead {
    border-style: hidden;
}

.upcoming_appointment_table>tbody {
    border-style: hidden;
}

.upcoming_appointment_table>tbody>tr {
    border-style: hidden;
}

.upcoming_appointment_table tr {
    border-style: hidden !important;
    border-color: transparent;
}

.upcoming_appointment_table {

    border-collapse: separate;
    border-spacing: 0 1em;
    width: 100%;
}

.upcoming_appointment_table tbody tr {
    background-color: #a7a7a714;
}

.upcoming_appointment_table td {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.upcoming_appointment_table_row_span {

    font-size: 15px;
    font-weight: 500;
}

.take_appointment_button {
    float: none !important;
    margin-top: 4px;

}

.appointment_available_td {
    padding: 10px !important;
    padding-top: 16px !important;
}

.appointment_available_td .desing_back {
    padding: 7px !important;
}

.upcoming_appointment_table .c-avatar__status {
    /*right: 74% !important;*/
}

.service_book_appointment_amount {
    border: 2px solid #80808078;
    border-radius: 5px;
    padding-left: 6px;
}

.book_appointment_popup .modal-content {
    /*height: inherit !important;
    width: 700px;
    right: 13%;*/
    padding: 15px;
}

.book_appointment_popup {
    margin-top: 0px !important;
}

.small_btm_book_apoinment {
    margin-bottom: 20px;
}

.reschedule_client_popup  .slots_list_by_client
{
   padding-bottom: 15px !important;
}
.create_appointment_popup  .slots_list_by_client
{
   padding-bottom: 15px !important;
}

.slots_list_by_client {
    background-color: #8bc34a14;
    border-radius: 7px;
    margin-bottom: 14px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding-top: 13px;
    padding-bottom: 0px;
    margin-right: 10px !important;
    /*height: 54px;*/
    border: 2px solid #00800063 !important;
}



.clicked_slot_active {
    background-color: #008000c7;
    color: white;
}

.hide_show_tick_book_a {
    visibility: hidden;
}

.coach_plan_recipe_modal_popup .pie_chart_bottom_div_label,
.pie_chart_bottom_div_data {
    font-size: 13px;
}

.coach_plan_recipe_modal_popup .dotcarbs {
    height: 13px;
    width: 13px;
}

.coach_plan_recipe_modal_popup .dotfat {
    height: 13px;
    width: 13px;
}

.coach_plan_recipe_modal_popup .dotprotein {
    height: 13px;
    width: 13px;
}

.coach_plan_recipe_modal_popup .dotcalories {
    height: 13px;
    width: 13px;
}

.client_weekplan .week_plan_recipes{
    margin-top: 30px;
    background-color: #fff;
    border-radius: 15px;
    padding: 10px;
}

.client_mealplan .week_plan_recipes {
   margin-top: 10px;
   background-color: #fff;
   border-radius: 15px;
   padding: 10px;
}

.client_weekplan .week_plan_recipes .recipes .header,.client_mealplan .week_plan_recipes .recipes .header {
    text-align: center;
    font-weight: 500;
}

.client_weekplan .week_plan_recipes .recipes .dayheader, .client_mealplan .week_plan_recipes .recipes .dayheader {
    display: contents;
}

.client_weekplan .week_plan_recipes .recipes .daycol, .client_mealplan .week_plan_recipes .recipes .daycol {
    text-align: center;
    margin-top: 70px;
    font-weight: 500;
    max-width: 50px !important;
}

.client_weekplan .plan_filter_box .weekselect {
    z-index: 100;
    width: 115px;
}

.client_weekplan .week_plan_recipes .recipe_box .kcal {
    float: left;
    color: #ff7600;
}

.client_weekplan .week_plan_recipes .recipe_box .swaprecipe {
    float: right;
    color: #179d0b;
}

.client_weekplan .week_plan_recipes .recipe_box .kcal,
.client_weekplan .week_plan_recipes .recipe_box .swaprecipe {
    font-size: 13px;
    font-weight: 500;
}

.client_weekplan_swap_modal_popup .swaptabmenu .col {
    border: 1px solid lightgray;
}

.client_weekplan_swap_modal_popup .swaptabmenu a {
    padding: 10px 20px;
    color: #7C7F83 !important;
    text-decoration: none;
    font-weight: 500;
    font-size: 18px;
}

.client_weekplan_swap_modal_popup .swaptabmenu a.active {
    color: #51b848 !important;
}

.client_weekplan_swap_modal_popup .search_box {
    text-align: center;
}

.client_weekplan_swap_modal_popup .search_box input {
    margin-left: 10px;
}

.client_weekplan_swap_modal_popup .menubox {
    margin-top: 20px;
    margin-bottom: 10px;
}

.client_weekplan_swap_modal_popup .recipelist_box {
    padding-top: 10px;
}

.client_weekplan_swap_modal_popup .recipelist_box .img {
    width: 100px;
    float: left;
    margin-right: 10px;
}

.client_weekplan_swap_modal_popup .recipelist_box img {
    width: 100px;
    height: 80px;
}

.client_weekplan_swap_modal_popup .recipelist_box li.recipe {
    clear: both;
    min-height: 90px;
    list-style-type: none;
    cursor: pointer;
}

.client_weekplan_swap_modal_popup .recipelist_box .title {
    font-weight: 500;
}

.client_weekplan_swap_modal_popup .recipelist_box .kcal {
    color: #51b848;
    font-weight: 500;
}

.client_weekplan_swap_modal_popup .recipelist_box .pagination {
    margin-top: 0;
    margin-bottom: 10px;
}

.client_weekplan_swap_modal_popup .recipelist_box .recipe.selected {
    border: 2px solid #51b848;
    border-radius: 15px;
    padding: 3px;
    margin-top: -10px;
}

.client_weekplan .dietplan_tab .recipes .title {
    max-height: 40px;
    overflow: hidden;
}

@media print {

    .shopping_print .shopping_box {
        margin: 10px;
        border: 1px solid lightgray;
        padding: 10px;
        border-radius: 15px;
        width: 300px;
        float: left;
    }

    .week_plan_recipes .recipes div.daycol {
        text-align: center;
        margin-top: 70px;
        font-weight: 500;
        width: 10px !important;
    }

    .week_plan_recipes .recipes .recipe_box {
        float: left;
        height: 250px;
        width: 170px;
        overflow: hidden;
        margin-top: 10px;
        padding: 5px;
        position: relative;
    }

    .week_plan_recipes .recipes .viewdata {
        position: absolute;
        z-index: 1;
        right: 15px;
        top: 10px;
        color: #333 !important;
        background-color: #fff;
        padding: 5px;
        border-radius: 15px;
        cursor: pointer;
    }

    .week_plan_recipes .recipes .viewheart {
        position: absolute;
        z-index: 1;
        left: 15px;
        top: 10px;
        color: #333 !important;
        background-color: #fff;
        padding: 5px;
        border-radius: 15px;
        cursor: pointer;
        padding-top: 0;
    }

    .week_plan_recipes .recipes .img {
        height: 130px;
        display: flex;
        cursor: pointer;
    }

    .week_plan_recipes .recipes img {
        width: 150px;
        display: flex;
    }

    .week_plan_recipes .recipes .title {
        max-height: 50px;
        overflow: hidden;
    }

    .week_plan_recipes .recipe_box .kcal,
    .week_plan_recipes .recipe_box .swaprecipe {
        font-size: 13px;
        font-weight: 500;
    }

    .week_plan_recipes .recipe_box .swaprecipe {
        float: right;
        color: #179d0b;
    }

    .week_plan_recipes .recipe_box .kcal {
        float: left;
        color: #ff7600;
    }
}


@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    /* Light grey */
    border-top: 10px solid #383636;
    /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}



.spinner-container {
    position: absolute;
    top: 50%;
    left: 50%;
}

.show_packages_popup .modal-content {
    height: auto !important;
    /*right: 20%;*/
    min-height: 400px;
}

.session_package_list .p_tag_label_top_subs {
    border-bottom: none;
}

.session_package_list .plan_col {
    background-color: #d3d3d330;
    border-radius: 20px;
    padding: 30px;
    margin-right: 7px;
    margin-top: 12px;
}

.session_package_list .p_tag_label_top_subs {
    color: black;
    font-size: 16x;
    padding-left: 0px !important;
    text-align: center !important;

}

.pie_chart_kcal_data {
    text-align: center;
    font-weight: 500;

}

.service_details_name {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 10px;
    border-radius: 8px;
    font-weight: 500;
    width: 140px !important;
    display: inline-block;
}
.service_details_name_80 {
   font-size: 15px;
   font-weight: 300;
   margin-bottom: 10px;
   border-radius: 8px;
   font-weight: 500;
   width: 55% !important;
   display: inline-block;
}

#semiTransparenDivLoader {
    width: 100%;
    height: 100%;

    /*-Lets Center the Spinner-*/
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    /*Centering my shade */


    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
    display: show;
}

#semiTransparenDivLoader::after {
    content: '';
    display: block;
    position: absolute;
    left: 48%;
    top: 40%;
    width: 80px;
    height: 80px;
    border-style: solid;
    border: 5px solid lightgrey;
    border-top-color: #4caf50;
    border-width: 7px;
    border-radius: 50%;
    -webkit-animation: spin .8s linear infinite;

    /* Lets make it go round */
    animation: spin .8s linear infinite;
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.NavigateQuestionnare {
    cursor: pointer;
}

.top_questionnaire_row_client {
    margin-top: 20px;
    display: inline;
}

.top_questionnaire_row_client_span {
    font-weight: 500;
    font-size: 18px;
    width: 97% !important;
}

.top_questionnaire_row_client_span_x {
    float: right;
    width: 0% !important;
    cursor: pointer;
}

.form_load_div input[type=submit] {

    border-color: #4CAF50;

}



.coach_availability .regular_availability .table-drag-select td.cell-selected {
    background-color: #4a9148;
    color: #ffffff;
}

.coach_availability .regular_availability .table-drag-select td.cell-being-selected {
    background-color: #4a9148;
}

.coach_availability .regular_availability .table-drag-select td {
    border: 1px solid lightgray;
    background-color: #fff;
    line-height: 1em;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 10%;
    padding-bottom: 25px;
}

.coach_availability .regular_availability .table-drag-select tr:first-child td:first-child {
    /* Remove empty space at top of table */
    border-top: 0;
    border-left: 0;
    line-height: 1rem;
    background-color: transparent;
}

.coach_availability .regular_availability .reset_btn {
    float: right;
    margin: 10px;
}

.coach_availability .regular_availability .reset_btn button {
    padding: 0 20px;
    border: 1px solid;
}

.view_questionnaire {
    cursor: pointer;
}

.client_close_x {
    width: 100% !important;
}

.client_close_x i {
    float: right !important;
}

.coach_meal_plan_food_diary {
    display: inline-block !important;
    padding-top: 25px;
}

.coach_meal_plan_food_diary .col_1 {

    width: 100%;
}

/* .coach_meal_plan_food_diary select {
    width: 163% !important;
} */

.top_meal_label {
    width: 100%;
}

.top_meal_label .float_right {
    float: right;
    padding-right: 11px;
    font-weight: 500;
}

.top_meal_label .float_left {
    color: #7c7f83 !important;
    font-weight: 500;
}

.top_week_label_meal {
    width: 100%;
    line-height: 2.5;
    padding-left: 36px;
}

.top_week_label_meal span {
    margin-right: 22px;
    font-size: 11px;
    font-weight: 500;

}

.bf_l_block {
    width: 13% !important;
    display: grid !important;
    line-height: 4.5;
    font-size: 9px;
    font-weight: 500;
}

.bf_l_block_tick .circle {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding: 4px;
    background: #226920;
    color: white;
    text-align: center;
    font: 6px Arial, sans-serif;
    margin-right: 15px;
}

.bf_l_block_tick .circle_purple {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding: 4px;
    background: #009fe1;
    color: white;
    text-align: center;
    font: 6px Arial, sans-serif;
    margin-right: 15px;
}

.bf_l_block_tick .circle_rose {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding: 4px;
    background: #e91e63;
    color: white;
    text-align: center;
    font: 6px Arial, sans-serif;
    margin-right: 15px;
}

.bf_l_block_tick {
    padding-top: 43px;
    display: block;

}

.tick_meal_block {
    display: block;
    border-bottom: 2px solid #7c7f83;
    margin-bottom: 25px;
}

.circle_first {
    display: flex;
    height: 19px;
}

.second_cir {
    margin-top: 22px;
}

.third_cir {
    margin-top: 22px;
}

.meal_plan_dot {
    padding-left: 0px !important;
}

.purple_back {
    background-color: #009fe1 !important;
}

.rose_back {
    background-color: #c71a35 !important;
}

.circle_transparent {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    padding: 4px;
    background-color: transparent !important;
    color: transparent !important;
    margin-right: 15px;
    font: 6px Arial, sans-serif;
    text-align: center;
}

span.notif {

    position: relative;
    display: inline-block;
    height: 25px;
    width: 50px;
    background-repeat: no-repeat;
    background-size: contain;
    text-decoration: none;
    right: -2px;
    top: 10px;
    cursor: pointer;

}

.userdrptag .num {
    position: absolute;
    right: 12px;
    top: -20px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    padding: 5px 10px;
    background-color: #226920;
    border-radius: 20px;
}

#userDropdownnot {
    /*right: 106px;*/
    padding-top: 16px;
    padding-bottom: 13px;
}

.notification_drop_changes .mark_as_read {
    padding-right: 10px;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    float: right;
    padding-top: 10px;
}

#userDropdownnot a {
    color: #7c7f83 !important;
    text-decoration: none !important;
}

.coach_availability .day_availability .rbc-day-slot .rbc-event {
    background-color: #4a9148;
    border: 1px solid;
}

.slots_available_label {
    margin-bottom: 17px;
    font-weight: 600;
}

.meeting_description_input {
    margin-top: 0px !important;
    width: 70% !important;
    margin-left: 10px;
}

.adminmanageusers .rightmenu button,
.adminpayout .rightmenu button,
.adminmanageteam .rightmenu button,
.adminlabtest .rightmenu button {
    color: #226920;
    border: 1px solid #226920;
    border-radius: 10px;
    font-weight: 500;
}

.last_commision_tab_button_add_plan {
    float: right;
    background-color: #226920;
    border: none;
    color: white;
    border-radius: 8px;
}

@media screen and (min-width: 480px) {

   .login_card .tfa_section input{
       width: 45px;
   }

    .admin_manageusers_user_edit_popup label,
    .admin_manageusers_add_coach_popup label,
    .admin_manageusers_add_client_popup label {
        width: 100%;
    }

    .coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs label {
        width: 160px;
        overflow: hidden;
    }
}

@media screen and (min-width: 576px) {

    .container,
    .container-sm {
        max-width: 100% !important;
    }
}

@media screen and (min-width:641px) {

   .coach_users .section_box .leftblock .user_header .slide_icon {
       float: right;
       margin-right: 10px;
   }

    .card_view_blog_container {
        width: 33% !important;
    }

    .client_weekplan .dietplan_tab .mob_recipes,.client_mealplan .mob_recipes {
        display: none;
    }

    .manager_dashboard .list_team_heading_row_name .teamname {
        width: 80%;
    }

    .manager_dashboard .list_team_heading_row_name .teamsize {
        width: auto;
        float: right;
    }

    .coach_users .labtest_tab .labtest_sample_table .hidetext {
        display: none;
    }
}

@media screen and (max-width: 460px) {
    .add_data_popup {
        display: block !important;
    }

    .list_row_add_data {
        width: 100% !important;
    }

    .second_tab_add_data {
        width: 100% !important;
    }

    .profile_info .add_data_coach {
        margin-top: 30px;
    }
}

/* @media screen and (max-width:768px) and (min-width:320px) {
   .add_food_journal .add_food_journal_plus_div img
   {
       width: 12% !important;
   }
} */




@media screen and (min-width:768px) and (max-width:1024px) {

   .videomessages .call_state .local, .videomessages .call_state .remote{
       width:48%;
   }

    .chatmessages .dialog_additional_info {
        flex: auto;
    }

    .admin_billing_top_label span{
        padding-bottom: 2px;
    }

/*      .add_food_journal .food_journal_list_div .list_col{
       width: 45% !important;
    } */

}

@media only screen and (min-width: 480px) and (max-width: 731px)  {
/*     .add_food_journal .food_journal_list_div .list_col{
       width: 45% !important;
    } */
}

@media only screen and (min-width: 1025px) and (max-width: 2560px)  {
/*    .add_food_journal .food_journal_list_div .list_col{
       width: 30% !important;
    } */
}

@media screen and (min-width:768px) and (max-width:1024px) {



    .adminmanageusers .rightmenu button,
    .adminpayout .rightmenu button,
    .adminmanageteam .rightmenu button,
    .adminlabtest .rightmenu button {
        float: right;
        margin: 10px;
        padding: 5px 15px;
    }

    .adminpayout .rightmenu button {
        margin-top: 0;
    }

    .commission_tab div {
        min-height: 100px !important;
        height: auto;
        width: 100%;
        margin-right: 5px;
        margin-top: 20px;
    }

    .last_commision_tab_button_add_plan {
        padding: 10px 33px;
        margin-top: 15px;
        width: 150px;
    }

    .adminbilling_commission.container .row.commission_tab {
        margin: 5px;
    }

    .adminmanageusers .tabmenu a,
    .adminmarketing .tabmenu a,
    .coach_plans .tabmenu a,
    .coach_food_plans .tabmenu a,
    .coach_plan_recipe_modal_popup .recipetabmenu a,
    .client_weekplan .menubox .tabmenu a,
    .client_agreement_docs .menubox .tabmenu a,
    .coach_availability .tabmenu a,
    .adminmanageteam .tabmenu a,
    .adminlabtest .tabmenu a,
    .coach_food_plans .recipe_library_tab a,
    .user_recipe_library .recipe_library_tab a {
        padding: 10px 10px;
    }

    .clientdashboard .client_dashboard_bar {
        width: 100% !important;
    }

    .clientdashboard .pos3innericon {
        margin-left: 0px;
    }

    .clientdashboard .circle_info_progress_info {
        display: inline-block;
    }

    .clientdashboard .circle_info_progress_info_col {
        width: 45%;
        float: left;
    }

    .clientdashboard .client_dashboard_bar_inner {
        width: 100%;
    }

    .clientdashboard .report_info_row .colRpos3 {
        display: flex;
    }

    .clientdashboard .pos3inner {
        margin-right: 10px;
    }

    .clientdashboard .report_info_row {
        margin: 0 !important;
        margin-top: 31px !important;
    }

    .client_next_appointment_dashboard {
        width: 100% !important;
    }

    .client_message_dashboard {
        width: 100% !important;
    }

    .add_list_col {
        width: 100% !important;
    }

    .chart_col {
        width: 100% !important;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .right_inner_block {
        width: 100%;
    }

    .adminmanageteam .rightmenu input {
        margin-top: 7px;
    }

    .clientdashboard .col.top_button_col {
        flex: none;
    }

    .client_weekplan .dietplan_tab .recipes .recipe_box {
        height: 250px;
        width: 100px;
    }
}

@media screen and (min-width:768px) {

   .videomessages .videochat .opponent_name {
       left: 200px;
       top: 244px;
   }   

    .openNavwidth {
        width: 18%;
    }

    .Container-enlarge {
        margin: 14px 14px 14px 9%;
    }

    .Container-shrink {
        margin: 14px 14px 14px 18%;
    }

    .sidenav-inner li {
        padding: 0 16px;
    }

    .sidenav-inner a {
        font-size: 12px;
        word-break: break-word;
    }

    #sidenav .sidenav_icon {
        margin-right: 10px;
    }

    .fa {
        font-size: 20px !important;
    }

    .adminmanageteam .rightmenu input {
        width: 320px;
    }

    .adminmanageusers .tabmenu,
    .adminlabtest .tabmenu {
        width: 50%;
        display: inline-block;
    }

    .adminmanageusers .rightmenu,
    .adminlabtest .rightmenu {
        float: right;
        width: 50%;
    }

    .chatmessages .sidebar {
        flex: 1 0 303px;
        position: static;
        left: 100%;
        left: 100%;
        float: left;
        width: 30%;
        max-height: 500px;
        overflow: auto;
    }

    /* .show_packages_popup .modal-content {
       width: 188% !important;
    } */

    .show_packages_popup .modal-content {
        width: 150% !important;
    }

    .session_package_list .plan_col {
        width: 47% !important;
    }

    .buy_service_popup_client .modal-dialog {
        width: 450px;
        right: 113px;
    }

    .session_package_list .plan_col .amount_row {
        padding-left: 48px;
    }

    .session_package_list .plan_col .plan_info {
        /* padding-left: 59px; */
        /* height: 0px !important; */
        text-align: center;
    }

    .session_package_list .low_level_button_subscriptions {
        /* padding-left: 125px; */
        margin-top: 115px;
        text-align: center;
    }

    .low_level_button_subscriptions {
        /* padding-left: 94px; */
        margin-top: 20px;
    }

    .session_package_list {
        margin-left: 20px !important;
    }

    /* .plan_col .amount_value {
       margin-left: 50px;
   } */

    .over_all_calendar_div {
        display: flex;
    }

    .coach_calendar_col1 {
        width: 70% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .coach_calendar_col2 {
        width: 30% !important;
        margin-left: 20px;
    }

    .colR.nutrition_dashboard_col {
        width: 73%;
    }

    .col.nutrition_dashboard_col2 {
        width: 27%;
    }

    .coachpayments .payment_section_avail {
        display: flex;
        height: 100px;
    }

    .coachpayments .payment_earning_top {
        width: 250px;
    }

    .end_payment_earning {
        margin-left: 28px;
    }

    /* #calendar_caoch_tab_menu .num {
        right: -5px;
    } */

    .slots_list_by_client {
        width: 31% !important;
        padding-left: 34px !important;
    }

    .react-form-builder-preview.float-left {
        width: 75% !important;
        display: inline-block !important;
    }

    .react-form-builder-toolbar.float-right {
        width: 25% !important;
        float: right;
    }

    .colRpos3 {
        display: flex;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block .left_sub_inner_block {
        width: 49%;
        float: left;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block .right_sub_inner_block {
        width: 49%;
        float: left;
        margin-left: 8px;
    }

    .coach_users .section_box .leftblock.enlarge {
        width: 19%;
        float: left;
    }

    .coach_users .section_box .rightblock.enlarge {
        width: 81%;
    }

    .coach_users .section_box .rightblock .tabcontent .notes_tab .left_inner_block {
        width: 50%;
        float: left;
        padding-right: 20px;
    }

    .coach_users .section_box .rightblock .tabcontent .notes_tab .right_inner_block {
        width: 50%;
        float: left;
    }

    /* .top_level_row_week_heading .numberCircle {
        margin-right: 30px;
        display: block;
        float: left;
    } */

    .admin_billing_top_label span {
        margin-right: 11px;
    }

    .manager_team_tab_div {
        width: 50% !important;
    }

    /* .login_card {
        max-width: 500px;
    } */

    #userDropdownnot {
        width: 400px;
    }

    .clientreport .list_div_report {
        width: 50%;
    }

    .coach_plan_recipe_modal_popup .recipe_data .img {
        /* float: left;
       width: 50%; */
    }

    /* .coach_plan_recipe_modal_popup .recipe_data img {
        width: 100%;
    } */

    .coach_plan_recipe_modal_popup .recipe_data .nutrition_data {
        /* margin-left: 20px;
        float: right;
        width: 45%; */
        padding: 40px;
        background-color: #d3d3d321;
        border-radius: 7px;
    }

    .coach_plan_recipe_modal_popup .modal-content {
        /* width: 150% !important;
       margin-left: -90px !important; */
    }

    .client_weekplan .dietplan_tab .topcontent .inner_label {
        width: 80%;
    }

    .client_weekplan .library_tab .sidepanel {
        width: 400px;
        left: 27%;
        top: 50%;
        height: auto;
    }

    .subscription_plan_row .plan_col {
        width: 30%;
    }

    .detailed_row_blog .col2 {
        width: 30%;
        float: right;
    }

    .detailed_row_blog .col1 {
        width: 70%;
        word-break: break-all;
        padding: 10px;
    }

    .chatmessages .dialog__info {
        overflow: visible !important;
    }

    .chatmessages .dialog__name {
        white-space: normal;
    }

    .videomessages .videochat #localVideo,
    .videomessages .videochat #remoteVideo {
        width: 100%;
        height: 280px;
    }

    .coach_users .section_box .rightblock {
       float: right;
       padding-left: 10px;
       border-left: 1px solid lightgray;
   }

   .coach_users .section_box .leftblock .users_list{
       height: 100%;
       overflow-y: scroll;
   }
   
}


@media screen and (min-width:1024px) {

   .videomessages .call_state .local, .videomessages .call_state .remote{
       width:48%;
   }

    .payment_inner_top_row {
        display: block;
    }

    .payment_inner_top_row .col_50 {
        width: 50%;
        float: left;
    }

    .payment_inner_top_row .chart_payment_col {

        float: right;
    }


    .openNavwidth {
        width: 16%;
    }

    .Container-enlarge {
        margin: 14px 14px 14px 9%;
    }

    .Container-shrink {
        margin: 14px 14px 14px 16%;
    }

    .sidenav-inner a {
        font-size: 18px;
        word-break: break-word;
    }

    #sidenav .sidenav_icon {
        margin-right: 20px;
    }

    .adminmanageusers .rightmenu button,
    .adminpayout .rightmenu button,
    .adminmanageteam .rightmenu button,
    .adminlabtest .rightmenu button {
        float: right;
        margin-right: 10px;
        padding: 5px 30px;
    }

    .commission_tab div {
        height: auto;
        min-height: 100px !important;
        width: 61%;
        margin-right: 5px;
    }

    .onhold_stripe_div_card {

        width: 19% !important;

    }


    .last_commision_tab_button_add_plan {
        padding: 10px 33px;
        margin-right: 47px;
        margin-top: 15px;
        width: 150px;
    }

    .adminmanageusers .tabmenu a,
    .adminmarketing .tabmenu a,
    .coach_plans .tabmenu a,
    .coach_food_plans .tabmenu a,
    .coach_plan_recipe_modal_popup .recipetabmenu a,
    .client_weekplan .menubox .tabmenu a,
    .client_agreement_docs .menubox .tabmenu a,
    .coach_availability .tabmenu a,
    .adminmanageteam .tabmenu a,
    .adminlabtest .tabmenu a,
    .coach_food_plans .recipe_library_tab a,
    .user_recipe_library .recipe_library_tab a {
        padding: 10px 20px;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block {
        width: 62%;
        float: left;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .right_inner_block {
        width: 38%;
        float: left;
        padding-left: 20px;
    }

    .client_weekplan .dietplan_tab .recipes .recipe_box {
        height: 250px;
        width: 150px;
    }

    .client_food_diary_first_row .col_1 {
        width: 50%;
        float: left;
    }

    .client_food_diary_first_row .col_2 {
        /* width: 50%; */
        float: right;
    }

}

@media screen and (min-width:1220px) {
    .client_weekplan .dietplan_tab .recipes .recipe_box {
        height: 200px;
        width: 200px;
    }
}

@media screen and (min-width:2560px) {
    .client_dash_last_row {
        margin-top: 60px !important;
    }
}





@media screen and (max-width:1020px) {

    .payment_inner_top_row {
        display: block;
    }

    .payment_inner_top_row .col_50 {
        width: 100% !important;
    }

    .payment_inner_top_row .chart_payment_col {
        margin-top: 100px;
    }

}



@media screen and (max-width:767px) {

   .videomessages .videochat .opponent_name {
       left: 250px;
       top: 244px;
   }

    .login_screen_section .top_login_row .img_div {
        display: none;
    }

    .videomessages .call_state .local, .videomessages .call_state .remote{
       width:100%;
    }

    .login_card {
        width: 100% !important;
    }

    #root .header {
        display: none;
    }

    .adminmanageusers .rightmenu button,
    .adminpayout .rightmenu button,
    .adminmanageteam .rightmenu button,
    .adminlabtest .rightmenu button {
        margin: 5px;
        padding: 5px 5px;
    }

    .adminpayout .rightmenu button {
        margin-top: 0;
    }

    .adminmanageusers .tabmenu,
    .adminlabtest .tabmenu {
        display: inline-block;
    }

    .dashboard .container {
        padding-left: 0;
        padding-right: 0;
    }

    .adminmanageusers .rightmenu,
    .adminlabtest .rightmenu {
        /* width: 52%;*/
        float: right;
    }

    .popup-content.admin_manageusers_delete_confirm-content,
    .popup-content.admin_manageusers_add_coach-content,
    .popup-content.admin_manageusers_user_edit-content,
    .popup-content.admin_manageusers_add_client-content {
        width: 90%;
    }

    .admin_billing_top_label span {
        font-size: 15px;
    }

    .last_commision_tab_button_add_plan {
        padding: 10px;
        margin-top: 15px;
        width: 120px;
    }

    .commission_tab div {
        height: 125px !important;
        width: 100%;
        margin-right: 5px;
        margin-top: 20px;
    }

    .adminbilling_commission.container .row.commission_tab {
        display: block;
        margin: 5px;
    }

    .adminmanageusers .tabmenu a,
    .adminmarketing .tabmenu a,
    .coach_plans .tabmenu a,
    .coach_food_plans .tabmenu a,
    .coach_plan_recipe_modal_popup .recipetabmenu a,
    .client_weekplan .menubox .tabmenu a,
    .client_agreement_docs .menubox .tabmenu a,
    .coach_availability .tabmenu a,
    .adminmanageteam .tabmenu a,
    .adminlabtest .tabmenu a,
    .coach_food_plans .recipe_library_tab a,
    .user_recipe_library .recipe_library_tab a {
        padding: 10px 10px;
    }

    .adminmarketing_modal_popup .modal-dialog {
        width: 90%;
    }

    .adminmanageteam .col {
        flex: none;
        width: 100% !important;
    }

    .adminmanageteam .rightmenu input {
        width: 200px;
    }

    .adminpayout .rightmenu input {
        float: left;
    }

    .profile.container .col {
        flex: none;
    }

    .commission_popup .modal-dialog {
        width: 85%;
    }

    .modal-dialog {
        width: 85%;
    }

    .add_service_agreement_popup .modal-dialog {
        width: 95%;
    }

    .session_package_list .plan_col .amount_row {
        padding-left: 0;
        text-align: center;
    }

    .session_package_list .plan_col .plan_info {
        padding-left: 0;
        /* height: 0px !important; */
        text-align: center;
    }

    .session_package_list .low_level_button_subscriptions {
        text-align: center;
        margin-top: 115px;
        padding-left: 0;
    }

    .low_level_button_subscriptions {
        padding-left: 0;
        margin-top: 20px;
    }

    .book_appointment_popup .modal-dialog,
    .show_packages_popup .modal-dialog {
        width: 95%;
    }

    .coach_calendar_col1 {
        width: 100% !important;
        padding: 0 !important;
        margin: 0 !important;
    }

    .coach_calendar_col2 {
        margin-top: 20px;
    }

    .coachservices .menubox .tabmenu,
    .coachdocuments .menubox .tabmenu {
        width: 100%;
        display: inline-block;
    }

    .coach_plans .title_box {
        display: inline-block;
        margin-bottom: 10px;
    }

    .coachpayments .connect_stripe_coach_div {
        width: 80%;
        background-color: white;
        height: 158px;
        margin-left: 40px;
        margin-top: 8%;
        border-radius: 10px;
        padding-top: 10px;
    }

    .coachpayments .payment_inner_top_row {
        display: initial;
    }


    .coachpayments .payment_earning_top {
        margin-bottom: 10px;
    }

    /* #calendar_caoch_tab_menu .num {
        right: -17px;
    } */

    .slots_list_by_client {
        width: 45% !important;
        padding-left: 20px !important;
        padding-bottom: 10px !important;
    }

    .reponsive_change_top_nav .second_row_notifi {
        padding-left: 0px;
        padding-right: 0px;
    }

    .reponsive_change_top_nav {
        min-height: 77px;
        padding-top: 14px !important;
    }

    .container-mobile-menu {
        position: relative;
        display: block;
    }

    .container-mobile-menu h1 {
        margin-bottom: 2rem;
    }

    .menu-items {
        text-align: center;
        position: absolute;
        margin-left: -1rem;
        list-style: none;
        opacity: 0;
        font-size: 2rem;
        border: 2px solid #303d40;
        background-color: #303d40;
        border-radius: 0.25em;
        /* transform: translateX(-10%);
  transition: transform 100ms ease-in-out, opacity 200ms; */
        transition: width 2s, height 4s;
        transition-timing-function: ease-in-out !important;
        z-index: 2;
        font-size: 15px;
        display: none;
        padding-left: 0px;
    }

    .menu-items li {
        color: white;
        padding: 0.5rem;
        padding-left: 10px;
        text-align: left;
        margin-bottom: 20px;
    }

    .menu-items li a {
        color: white;
        text-decoration: none;
    }

    .menu-items li a:hover {
        color: rgb(174, 174, 174);
    }

    .menu-items i {
        margin-right: 6px;
    }

    .check {
        position: absolute;
        top: 16px;
        left: 6px;
        height: 3rem;
        width: 3rem;
        opacity: 0;
        z-index: 3;
    }

    .check:hover {
        cursor: pointer;
    }

    .check:checked~.menu-items {
        opacity: 1;
        display: inherit;
    }

    .check:checked~.reponsive_logo {
        display: block;
    }

    .close_mobile_menu {
        position: absolute;
        top: 12px;
        right: 3px;
        font-size: 19px;
        color: white !important;
    }

    .ham-menu {
        height: 34px;
        margin-top: 8px;
        width: 3rem;
        position: absolute;
        top: 18px;
        left: 6px;
        padding: 0.5rem;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        background-color: #4caf50;
        border-radius: 0.25em;
    }

    .ham-menu .line {
        background-color: white;
        border-radius: 1em;
        width: 2rem;
        height: 2px;
    }

    .ham-menu .line1 {
        transform-origin: 0% 0%;
        transition: transform 100ms ease-in-out;
    }

    .ham-menu .line3 {
        transform-origin: 0% 100%;
        transition: transform 100ms ease-in-out;
    }

    .reponsive_change_top_nav {
        margin-left: 43px;
    }

    .reponsive_change_top_nav .first_row_notifi input {
        width: 138px;
    }

    .client_dashboard_bar_inner {
        width: 216%;
    }

    .calories_info_client_dashboard_frow {
        font-size: 13px
    }

    .calories_info_client_dashboard .change_a_tag {
        margin-right: 0px;
    }

    .report_info_row .colRpos3 {
        display: block;
    }

    .calories_info_client_dashboard_srow {
        padding-left: 23px;
    }

    #progressbar {
        width: 100%;
    }

    .circle_info_progress_info {
        display: contents !important;
    }

    .client_dash_last_row {
        display: contents !important;
    }

    .client_next_appointment_dashboard {
        width: 100% !important;
    }

    .clientdashboard .appointment_available_td .design_skyblue {
        font-size: 9px;
    }

    .clientdashboard .appointment_available_td .design_yellow {
        font-size: 9px;
    }

    .clientdashboard .client_next_appointment_dashboard {
        margin-top: 20px;
    }

    .clientdashboard .client_message_dashboard {
        width: 100% !important;
    }

    .clientdashboard .report_info_row {
        margin: 0 !important;
        margin-top: 10px !important;
    }

    .clientdashboard .report_info_row .colRpos3 {
        margin-left: 0px;

    }

    .list_div_report_top_row {
        margin-left: 0px !important;

    }

    .list_div_report {
        width: 100% !important;
    }

    .generate_food_diary_report_pop .react-datepicker-wrapper {
        margin-left: 12px;
    }

    .generate_food_diary_report_pop .to_date_div .react-datepicker-wrapper {
        margin-left: 31px;
    }

    .subscription_plan_row {
        display: block !important;
    }

    .plan_col {
        width: 100% !important;
    }

    /* .p_tag_label_top_subs{
      padding-left: 31%;
  } */
    .plan_col .amount_row {
        /* padding-left: 31%  !important; */
    }

    .low_level_button_subscriptions {
        /* padding-left: 38% */
    }

    .keto_book_info_col {
        width: 100% !important;
    }

    .keto_book_image_col {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .add_list_col {
        width: 100% !important;
    }

    .client_food_diary_first_row .react-datepicker {
        right: 35% !important;
        top: -14px;
    }

    .chart_col {
        width: 100% !important;
    }

    .chart_row {
        margin-left: 15px !important;
    }

    .client_food_diary_second_row canvas {
        /* height: 50% !important;
      width: 100% !important;
      margin-left: 0;    */
        margin: 0 auto;
        width: 100%;
    }

    .food_diary_info_right {
        margin-left: 0 !important;
        margin-top: 20px !important;
    }

    .input_rows_add_client {
        display: inline-block !important;
    }

    .add_member_button_row button {
        padding-left: 28%;
        padding-right: 44%;
        padding-top: 0px;
        padding-bottom: 0px;
        display: grid;
    }

    .card_view_row {
        display: block !important;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .right_inner_block {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block .left_sub_inner_block {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .progress_tab .left_inner_block .right_sub_inner_block {
        width: 100%;
    }

    .coach_users .section_box .leftblock.enlarge {
        width: 100%;
        margin-bottom: 20px;
    }

    .coach_users .section_box .leftblock .users_list{
       border: 1px solid lightgray;
       border-radius: 10px;
       padding: 5px;
    }

    .coach_users .section_box .rightblock.enlarge {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .notes_tab .left_inner_block {
        width: 100%;
    }

    .coach_users .section_box .rightblock .tabcontent .notes_tab .right_inner_block {
        width: 100%;
    }

    /* .top_level_row_week_heading .numberCircle {
        margin-right: 10px;
        display: block;
        float: left;
    } */

    .admindashboard .colR .container .wholesection1 .col {
        flex: none;
        padding: 20px;
    }

    .admin_billing_top_label span {
        margin-right: 5px;
    }

    .adminmarketing .referral_data .col {
        flex: none !important;
    }

    .adminmanageteam .rightmenu button {
        padding: 5px 10px;
    }

    /* .login_card {
        max-width: 80%;
    } */

    .admin_analytics .col.card_view_analytics {
        flex: none;
    }

    .admin_analytics .Tob_button .earning_tab {
        margin-bottom: 20px;
    }

    .admin_analytics .usertab .filter_row_analytics .col {
        flex: none;
        margin-top: 10px;
        float: left;
        max-width: fit-content;
    }

    .admin_analytics .usertab .filter_row_analytics .col {
        flex: none;
        margin-top: 10px;
        float: left;
    }

    .clientdashboard .client_dashboard_bar_row .col.calories_info_client_dashboard {
        flex: none;
    }

    #userDropdownnot {
        width: 400px;
    }

    .clientdashboard .col.top_button_col {
        flex: none;
    }

    .clientreport .list_div_report {
        width: 80%;
    }

    .client_weekplan .dietplan_tab .header_box .input_fields .col {
        flex: none;
    }

    .client_weekplan .dietplan_tab .topcontent .inner_label {
        width: 100%;
        margin-top: 25px;
    }

    .client_weekplan .library_tab .sidepanel {
        width: 80%;
        left: 10%;
        top: 50%;
        height: auto;
    }

    .subscription_plan_row .plan_col {
        width: 80% !important;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .detailed_row_blog .col2 {
        margin-bottom: 20px;
    }

    .chatmessages .sidebar {
        max-height: 300px;
        margin-bottom: 20px;
        border: 1px solid lightgreen;
        overflow: auto;
    }

    .videomessages .videochat #localVideo,
    .videomessages .videochat #remoteVideo {
        width: 100%;
        height: 280px;
    }

    .coach_users .section_box .rightblock {
       padding: 5px;
       border: 1px solid #226920;
       border-radius: 20px;
   }

   .coach_users .section_box .leftblock .users_list{
       max-height: 400px;
       overflow-y: scroll;
   }
   
}

@media screen and (max-width:640px) {
    .client_weekplan .dietplan_tab .recipes.desktop {
        display: none;
    }

    .client_mealplan .recipes.desktop {
       display: none;
   }

    .client_weekplan .library_tab .filter_reciepe_button {
        float: none;
    }

    .card_view_blog_container {
        width: 48% !important;
    }
}

@media screen and (max-width:480px) {

   .videomessages .videochat .opponent_name {
       left: 115px;
       top: 244px;
   }

   .login_card .tfa_section input{
       width: 30px;
       margin-bottom: 5px;
   }

    .top_header_name {
        display: inline-block;
        padding-bottom: 10px;
    }

    .coach_blog .title_box .leftmenu {
        float: none;
    }

    .coach_blog .title_box .search {
        width: 190px;
    }

    .upcoming_appointment_table tbody tr {
        border: 1px solid #999 !important;
    }

    .admin_billing_top_label span {
        margin-right: 0;
    }

    .adminmarketing .addpromotion {
        margin-bottom: 15px;
    }

    .upcoming_appointment_table_row {
        padding: 20px;
    }

    .client_appointment .upcoming_appointment_table_row .col {
        flex: none;
    }

    .plan_col .amount_row {
        min-height: 100px;
        padding: 17px 0px;
    }

    .buy_service_popup_client .session_package_list {
        margin-left: 10px;
        margin-right: 10px;
    }

    .add_food_journal .food_journal_list_div .list_col {
        width: 100% !important;
    }

    /* .coach_plan_recipe_modal_popup .recipe_data img {
       width: 100%;
   } */

    .coach_plan_recipe_modal_popup .recipe_data .nutrition_data {
        padding: 40px;
        background-color: #d3d3d321;
        border-radius: 7px;
    }

    .client_weekplan .dietplan_tab .header_box .input_fields .col {
        flex: none;
    }

    .card_view_blog_container {
        width: 95% !important;
    }

    .admin_manageusers_user_edit_popup label,
    .admin_manageusers_add_coach_popup label,
    .admin_manageusers_add_client_popup label {
        width: 120px;
    }

    .admin_manageusers_user_edit_popup .col,
    .admin_manageusers_add_coach_popup .col,
    .admin_manageusers_add_client_popup .col {
        flex: none !important;
    }

    .admin_manageusers_user_edit_popup .useredit_timezone,
    .admin_manageusers_add_coach_popup .coachadd_timezone,
    .admin_manageusers_add_client_popup .clientadd_timezone {
        display: inline-block;
        width: 190px;
    }

    .admin_manageusers_user_edit_popup .err span,
    .admin_manageusers_add_coach_popup .err span,
    .admin_manageusers_add_client_popup .err span {
        text-align: center;
        display: block;
    }

    .coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs label {
        width: 100%;
    }
}

@media screen and (max-width:375px) {

    .login_screen_section .top_login_row .initial_row {

        border: none !important;
    }

    .reponsive_change_top_nav .first_row_notifi {
        max-width: 40% !important;
    }

    .reponsive_change_top_nav .second_row_notifi .userdrptag {
        width: 104%;
    }

    .coach_plans .title_box .rightmenu input {
        margin-left: 1px;
        width: 140px;
    }

    .coach_blog .title_box .search {
        width: 140px;
        margin-right: 2px;
    }

    /* .top_level_row_week_heading .numberCircle {
        margin-right: 1px;
        display: block;
        float: left;
    } */

    .admin_billing_top_label span {
        padding-bottom: 0;
    }

    #userDropdownnot {
        width: 300px;
    }

    .client_weekplan .dietplan_tab .plan_filter_box .col {
        margin-bottom: 10px;
    }

    .food_diary_table table th,
    .food_diary_table table td {
        padding: .5rem .3rem !important;
    }

    .add_food_journal .food_journal_list_div .list_col {
        width: 100% !important;
    }
}

@media screen and (max-width:320px) {

    .login_screen_section .top_login_row .initial_row {
        padding: 0px !important;
        border: none !important;
    }

    .adminpaymentmethods .paymentgateway {
        width: 100%;
    }

    .clientdashboard .col.top_button_col .connected_devices_button {
        margin-bottom: 10px;
    }

    .add_food_journal .food_journal_list_div .list_col {
        width: 100% !important;
    }
 
   
}

@media screen and (max-width:300px) {

    .client_food_diary_first_row .col_2 .fif_calendar_icon {
        left: 84% !important;
        top: 25px !important;
    }
}

.client_dashcoach_appointment {
    bottom: 9% !important;
}

.coach_dash_my_appointment {
    /* right: 76% !important;*/
}

.pick_date_label_book_appointment {
    font-size: 16px !important;
    margin-bottom: 7px;
    margin-right: 28px;
}

.coach_appointment_modal_popup label {
    /*float: right;*/
    width: 122px;
    font-weight: 500;
    border: 1px solid green;
    border-radius: 15px;
    margin-right: 20px;
}

.coach_appointment_modal_popup .row {
    margin: 10px;
}

.coach_appointment_modal_popup .row .cancel_btn {
    margin: 0 auto;
    width: auto;
}

.coach_appointment_modal_popup .timeval {
    color: green;
    width: auto;
    padding: 0;
    font-weight: 500;
}

.pick_date_apppointment_by_client .react-datepicker-wrapper {
    /*width: 70% !important;*/
    width: auto !important;
    display: inline-block !important;
    /*margin-left: 10px;*/
}

.alert_book_popup {
    top: 30% !important;
}

.alert_book_popup .bold {
    font-weight: 600;
}

.top_service_appoint_div {
    border: 2px solid #226920;
    border-radius: 14px;
    padding: 10px;
}

.meeting_title_label {
    width: 105px !important;
}

.action_appointment_button {
    height: 29px;
    padding-top: 1px !important;
    margin-top: 4px;
}

.date_time_avaialble_td {
    padding-top: 15px !important;
}

.join_appointment_button {
    margin-left: 9px;
}

.subscribed_col {
    border-right: 1px solid #8080805e;
}

.add_intake_form_input {
    margin-bottom: 10px;
}

.first_col_mail_temp {
    width: 70% !important;
    padding-top: 5px;
}

.second_col_mail_temp {
    width: 30% !important;
}

.first_col_mail_temp span {
    font-size: 18px;
    font-weight: 500;
}

.create_new_template_button {
    float: right;
}

.border_content {
    border: 1px solid lightgray
}

.border_content iframe {
    min-width: 100% !important;
}

.client_appointment .subscribed_col,
.client_appointment .available_col {
    border: 1px solid #8080805e;
    border-radius: 20px;
    padding: 10px;
    margin: 10px;
}

.bootstrp_color_btn {
    background-color: #226920 !important;
    border-color: #226920 !important;
}

.tag_info_template {
    display: grid;
}

.font_weight_500 {
    font-weight: 500;
    padding-top: 2px;
    padding-bottom: 2px;
}

.clientreport .list_div_report {
    height: 64px;
    padding: 10px;
    border: 2px solid #80808054;
    border-radius: 12px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.label_tag_report {
    font-size: 15px;
    font-weight: 500;
}

.list_div_report_top_row {
    /* display: grid !important;
    margin-left: 212px !important;
    margin-top: 100px !important; */
    display: inline !important;
}

.generate_food_diary_report_pop .react-datepicker-wrapper {
    width: 30%;
    margin-left: 20px;
}

.generate_food_diary_report_pop .from_date_div,
.to_date_div {

    margin-bottom: 20px;
}

.generate_food_diary_report_pop .to_date_div .react-datepicker-wrapper {
    margin-left: 36px;
}

.generate_food_diary_report_pop .react-datepicker-popper {
    left: 0% !important;
}

.generate_food_diary_report_pop_pdf .chart_row {
    margin-left: 25px !important;
    margin-bottom: 50px !important;
}

.generate_food_diary_report_pop_pdf .modal-dialog {
    padding-top: 21px !important;
}

.info_details_progress {
    display: grid;

    margin-bottom: 50px !important;
    border: 2px solid #ffb0006e;

    border-radius: 12px;
    font-weight: 500;
    padding-left: 9px;
    padding-top: 5px;
    padding-bottom: 5px;
    color: #7c7f83;
    max-width: 676px;

}

.info_details_progress .input_fields {
    margin-top: 20px;
}

.top_label_qn {
    font-weight: 500;
    border-bottom: 2px solid #80808047;
}

.remove_modal_width .modal-dialog {

    width: 100%;


}

.personal_info_report_row .top_span {
    margin-bottom: 4px;
}

.report_user_info {
    border-bottom: 2px solid #80808047;
}

.form_row_report {
    width: 88%;
    margin-left: 30px;
}

.additional_info_report_div {
    margin-top: 4px;
}

.top_label_title_report {
    margin-left: 32px;
}

.report_chart_row .col_1 {
    width: 34% !important;

    padding-top: 115px !important;
    font-weight: 500;

}

.report_chart_row .col_2 {
    width: 66% !important;
    padding-top: 2px;
    border: 2px solid #80808036;
    border-radius: 11px;
    background-color: #0080000d;
    margin-top: 20px;
}

.report_chart_row .single_col {
    border: 2px solid #80808059;
    border-radius: 8px;
}

.report_chart_row {
    background-color: transparent;
    border-color: transparent;
}

.submitted_qns_pop .modal-dialog {
    padding-top: 21px !important;
}

.h5_report {
    color: black;
    font-weight: 500;
}

.personal_info_report_row .top_span .inner_label {
    color: rgb(26, 145, 192);
    margin-right: 20px;
}

.personal_info_report_row .top_span .input_value {
    color: #303d40;
    font-weight: 400;
    margin-left: 2px;
}

.additional_info_row .inner_label {
    color: rgb(26, 145, 192);
}

.additional_info_row .input_value {
    color: #303d40;
    font-weight: 400;
}

.top_report_chart_row_head {
    font-weight: 500;
    padding-left: 27px;
    border-bottom: 1px solid #808080cf;
    margin-bottom: 9px;
    margin-left: 3px !important;
}

.welcome_mail_div {
    min-width: 90%;
    min-height: 250px;
    background-color: #8080801f;
    border-radius: 10px;
    padding-top: 8px;
    padding-left: 8px;
}

.fnameerr_admin_coach .col1 {
    width: 50%;
    padding-left: 0px;
}

.fnameerr_admin_coach .col2 {
    width: 50%;
    padding-left: 0px;
}

/* @media screen and (min-width:375px){

   .reponsive_change_top_nav .first_row_notifi input {
       width: 138px;
   }

  .clientdashboard .todays_activity_button 
  {
      margin-top: 20px;
  }
  .client_dashboard_bar_inner{
      width: 216%;
  }
  .calories_info_client_dashboard_frow{
      font-size: 13px;
      padding-left: 20px;
  }
  .calories_info_client_dashboard .change_a_tag{
      margin-right: 0px;
  }
  .report_info_row .colRpos3{
      display: block;
  }
  .calories_info_client_dashboard_srow{
      padding-left: 23px;
  }
  #progressbar{
      width: 100%;
  }
  .circle_info_progress_info{
      display: contents !important;
  }
  .client_dash_last_row{
      display: contents  !important;
  }
  .client_next_appointment_dashboard {
      width: 100%  !important;
  }
  .clientdashboard .appointment_available_td .design_skyblue{
      font-size: 9px;
  }
  .clientdashboard .appointment_available_td .design_yellow{
      font-size: 9px;
  }
  .clientdashboard .client_next_appointment_dashboard{
      margin-top: 20px;
  }
  .clientdashboard .client_message_dashboard{
      width: 100%  !important;
  }
  .clientdashboard .report_info_row{
   margin: 0 !important;
   margin-top: 10px  !important;
}
.clientdashboard .report_info_row .colRpos3{
   margin-left: 0px;

}

} */

/* @media screen and (min-width:425px){

   .reponsive_change_top_nav .first_row_notifi input {
       width: 138px;
   }

  .clientdashboard .todays_activity_button 
  {
      margin-top: 20px;
  }
  .client_dashboard_bar_inner{
      width: 216%;
  }
  .calories_info_client_dashboard_frow{
      font-size: 13px
  }
  .calories_info_client_dashboard .change_a_tag{
      margin-right: 0px;
  }
  .report_info_row .colRpos3{
      display: block;
  }
  .calories_info_client_dashboard_srow{
      padding-left: 23px;
  }
  #progressbar{
      width: 100%;
  }
  .circle_info_progress_info{
      display: contents !important;
  }
  .client_dash_last_row{
      display: contents  !important;
  }
  .client_next_appointment_dashboard {
      width: 100%  !important;
  }
  .clientdashboard .appointment_available_td .design_skyblue{
      font-size: 9px;
  }
  .clientdashboard .appointment_available_td .design_yellow{
      font-size: 9px;
  }
  .clientdashboard .client_next_appointment_dashboard{
      margin-top: 20px;
  }
  .clientdashboard .client_message_dashboard{
      width: 100%  !important;
  }
  .clientdashboard .report_info_row{
   margin: 0 !important;
   margin-top: 10px  !important;
}
.clientdashboard .report_info_row .colRpos3{
   margin-left: 0px;

}

} */

/* @media screen and (min-width:768px){

   .clientdashboard .todays_activity_button {
       margin-top: 10px;
   }
   .clientdashboard .client_dashboard_bar 
   {
       width: 100% !important;
   }
   .clientdashboard .pos3innericon{
       margin-left: 0px;
   }
   .clientdashboard .circle_info_progress_info{
       display: inline;
   }
   .clientdashboard .circle_info_progress_info_col{
       width: 50%;
   }
   



   

   

} */

.fnameerr_admin_coach_comision {
    display: block !important;
    padding-left: 14px;
}

.notification_settings_success {
    padding-left: 10px;
    color: green;
}

.promotion_err_name {
    padding-left: 15px;
    font-size: 14px;
}

.service_err_field {
    font-size: 13px;
}

/*.add_agreement_popup .modal-dialog{
right: 122px;
}*/

/*.book_client_popup .modal-dialog {
    right: -74px;
}*/

.food_diary_client_popup .modal-dialog {
    right: -13px;
    min-width: 50%  !important;
    /* width: 480px; */
}

.admin_appointment_head_row span {
    font-size: 18px;
    font-weight: 500;

}

.form_err {
    text-align: center;
    color: red;
}

.analytics_top_label {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 10px;

}

.analytics_top_description {
    font-size: 14px;
    color: #7c7f83;
}

.top_row_analytics_div {
    margin-bottom: 12px;
}

.card_view_analytics {

    background-color: white;
    border-radius: 12px;
    margin-right: 7px;



}

.admin_table_div_analytics {
    min-height: 300px;
    max-height: 338px;
    overflow: scroll;
}

.top_row_head_analytics {
    background-color: #226920;
    border-radius: 7px;
    padding-top: 4px;
    padding-bottom: 4px;
    color: white;
    font-size: 12px;
}

.admin_manageusers_add_coach-overlay .coachadd_timezone,
.admin_manageusers_add_client-overlay .clientadd_timezone,
.admin_manageusers_user_edit-overlay .useredit_timezone {
    padding-top: 10px;
}

.card_top_row {
    margin-bottom: 10px;
}

.list_live_consulation {

    display: grid;
    margin-top: 13px !important;
    background-color: #d3d3d340;
    width: 95% !important;
    margin-left: 7px;
    border-radius: 4px;
    color: black;
    font-weight: 500;
    padding-left: 14px;
    padding-bottom: 10px;
    padding-top: 10px;

}


.filter_analytics_button {
    padding-top: 5px !important;
    padding-right: 20px !important;
    padding-bottom: 5px !important;
    padding-left: 20px !important;
}

.filter_row_analytics {
    width: 100% !important;
    padding-left: 24px !important;


}

.date_filter_analytics_col .react-datepicker-popper {
    left: 0px !important;
}


.chart_row_analytics_top_head {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
    padding-left: 24px !important;
}

.revenue_chart_div canvas {
    margin-top: 10px;
}

.revenue_chart_heading {

    font-size: 15px;
    font-weight: 500;
}

.top_heading_row_revenue select {
    padding: 1px 25px !important;
}

.second_row_revenue_analytics {
    margin-top: 10px !important;
}

.last_row_add_member {
    margin-bottom: 20px;
}

.client_add_member_option {
    margin-right: 9px;

}

.client_add_member_option input {
    margin-right: 5px;
}

.top_header_name {
    margin-right: 17px;
}

.calendar_icon_div {
    position: relative;
}

.calendar_icon_div i {
    position: absolute;
    left: 183px;
    top: 1px;
}

.fif_label_progress {
    width: 15% !important;
}

.over_div_fif {
    position: relative
}

.fif_icon {
    position: absolute;
    left: 805px;
    z-index: 2;
    top: 4px;
}

.fif_pos {
    position: absolute
}

.fif_change_report {

    position: relative;
    z-index: 1;
    top: 0px;
    left: 11px;

}

.to_fif_change {
    left: 29px;
}

.analytics_fif {
    z-index: 99;
    left: -7px;
    top: 4px;

}

#overlay {
    position: fixed;
    /* Sit on top of the page content */
    display: block;
    /* Hidden by default */
    width: 100%;
    /* Full width (cover the whole page) */
    height: 100%;
    /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Black background with opacity */
    z-index: 2;
    /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer;
}

.premim_plan_upgrade {
    height: 235px;
}

.a_href_premium {
    border-color: #4caf50 !important;
    background-color: #4caf50 !important;
}

.coach_food_plans .day_box .box .card.filled,
.coach_users .mealplan_tab .day_box .box .card.filled {
    background-color: #05d1b570;
}

.admindashboard .wholesection1 .viewall {
    font-weight: 600;
    float: right;
    margin-top: 7px;
}

.admindashboard .wholesection1 .viewall a {
    color: #226920;
    text-decoration: none;
}

.coach_calendar_col1 .rbc-btn-group .rbc-active {
    color: #ffffff !important;
    background-color: #000000 !important;
}

.coach_calendar_col1 .rbc-btn-group button {
    margin-right: 9px !important;
    border-radius: 11px !important;
}

.top_row_calendar {

    margin-bottom: 35px;
}

.col1_top_row_book {
    padding-top: 20px;
}

.upcoming_apointment_top_label_calendar {
    font-size: 20px;
    color: #808080;
    font-weight: 500;
    padding-left: 20px;
}

/* .list_card_calendar_status {
    right: 86% !important;
}*/

.coach_calendar .list_card_calendar_status {
    margin-left: 30px;
    bottom: 5px;
}

.calendar_profile_img {
    height: 44px !important;
    width: 44px !important;
}

.appointment_calendar_card {
    display: grid;
    padding-left: 22px !important;
    background-color: #ffffff;
    border-radius: 11px;
    padding-top: 23px;
    padding-bottom: 25px;
    margin-bottom: 20px;
}

.appointment_calendar_card .c-avatar .photo_block {
    width: 50px;
    float: left;
}

.appointment_calendar_card .type {
    /*font-weight: 500;*/
}

.appointment_calendar_card .date {
    color: #226920;
}

.coach_calendar_col2 {
    border: 1px solid #226920;
    border-radius: 20px;
}

.coach_calendar_col2 .name_val {
    font-size: inherit;
}

.appointment_calendar_card .program {
    margin-top: 8px;
    color: #7c7f83;
}

.coach_calendar_col2 .appointment_calendar_card .program {
    color: #7c7f83;
    font-size: 12px;
    border: 1px solid lightgray;
    display: inline-block;
    border-radius: 12px;
    padding: 0 10px;
    margin-top: 0;
}

.coach_calendar_col2 .appointment_calendar_card .c-avatar {
    margin-bottom: 10px;
}

.join_call_button_calendar {
    background-color: white;
    color: #226920;
    border: 1px solid #7c7f83;
    font-weight: 500;
    margin: 0 auto;
    margin-top: 10px;
}



.join_call_button_calendar:hover {
    background-color: #226920;
    color: #fff !important;
}

.join_call_button_calendar:hover a,
.join_call_button_calendar a:hover,
.client_appointment .join_appointment_button a,
.client_appointment .join_appointment_button a:hover,
.coach_calendar .join_appointment_button a,
.coach_calendar .join_appointment_button a:hover {
    color: #fff !important;
    text-decoration: none;
}

.coach_calendar .coach_calendar_table_appointment_history a button{
    color: #fff;
    padding: 20px;
   border-radius: 5px !important;
   font-size: 15px;
   vertical-align: bottom;
}

.background_green {
    background-color: #226920 !important;
}

.input_fields_coach_create_appointment select {
    height: 30px !important;
    margin-left: 10px;
}

.input_fields_coach_create_appointment .react-datepicker-wrapper input {
    height: 30px !important;
    width: 92%;
    border: 1px solid #cecece !important;
    margin-left: 10px;
}

.input_fields_coach_create_appointment {
    padding-left: 10px;
    padding-top: 20px;
}

.input_fields_coach_create_appointment_date {
    padding-left: 0px !important;
}

.create_appointment_popup .modal-content {
    /* width: 700px;*/
}

.input_fields_coach_create_appointment_date .calendar_icon_div i {
    position: relative;
    left: 10px;
    top: 15px;
}

.create_appointment_popup .small_btm_book_apoinment {
    margin-top: 20px;
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.create_appointment_h4 {
    padding-left: 10px;
}

.select_date_time_appoint_label {
    padding-left: 20px !important;
}

.calendar_icon_coach_appointment {
    top: 33px;
    text-align: right;
    left: -50px;
}

.select_meeting_type_client select {
    width: 200px;
    /* margin-left: 34px;*/
}

.meeting_title_label,
.meeting_type_caoch_book_label {
    font-size: 16px;
    margin-right: 20px;
}

.pick_date_client {
    margin-right: 16px !important;
}

.pick_date_row_client {
    margin-top: 20px !important;
}

.meeting_description_coach_input {
    width: 90% !important;
    margin-left: 10px !important;
}

.reschedule_client_popup {
    display: block;
    /* padding-left: 24%; */
}

.reschedule_trigger_button {
    margin-right: 4px;
}

.join_button_appointment_span {
    display: flex;
    padding-left: 40px;

}

.join_button_appointment_span i {
    border: 1px solid #cecece;
    padding: 9px;
    border-radius: 6px;
    color: #226920 !important;
    cursor: pointer;
}

.inner_copy_appointment {
    padding-top: 19px;
}

.join_button_appointment_span button {
    margin-left: 15px !important;
}

.notification_drop_changes {
    max-height: 400px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.notification_drop_changes::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.notification_drop_changes {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.fif_calendar_icon {
    cursor: pointer;
}

.report_date .react-datepicker-popper {
    z-index: 3;
}

.client_food_diary_first_row .react-datepicker {
    right: -113%;
    top: -14px;
}

.top_row_filter_calendar {
    margin-bottom: 25px;
}

.filter_calendar_label {
    font-size: 13px;
    font-weight: 500;
}

.top_row_filter_calendar .searchWrapper {
    border-radius: 7px;
}

.apply_filter_calendar {
    padding: 8px 22px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
}

.apply_filter_calendar_col {
    padding-top: 25px;
}

.top_row_filter_calendar .optionContainer {
    background-color: white;
    color: black;
}

.top_row_filter_calendar .optionContainer .highlight {
    background-color: #226920;
    color: white;
}

.top_row_filter_calendar .multiSelectContainer li:hover {
    background-color: #226920;
    color: white;
}

.top_row_filter_calendar .multiSelectContainer .chip {
    background-color: #226920;
}

.top_row_filter_calendar .optionContainer {
    font-size: 13px;
}

#userDropdown {
    z-index: 3;
}

#userDropdownnot {
    z-index: 3;
}

.contact_form_inputs {
    width: 60% !important;
    margin-left: 10px !important;
    margin-bottom: 10px;
    margin-top: 8px !important;

}

/* .videomessages .videochat #localVideo,
.videomessages .videochat #remoteVideo {
    padding: 10px;
    border: 1px solid #226920;
    background-color: #ffffff;
    float: left;
    margin-right:10px;
} */

/*.videomessages .videochat #localVideo, .videomessages .videochat #remoteVideo{    
   padding: 10px;
   border: 1px solid #999;
   background-color: #999;
}

.videomessages .videochat #localVideo.video_minimize{
   width:20%;
   float: right;
}

.videomessages .videochat #localVideo.video_enlarge{
   width:80%;
   float: right;
}

.videomessages .videochat #remoteVideo{
   width:100%;
   padding: 10px;
   border: 1px solid #999;
   background-color: #999;
}*/

.videomessages .status_msg {
    font-weight: 500;
    text-align: center;
    color: #226920;
}

.videomessages .actionbuttons button{
   padding: 10px;
   margin: 10px;
   width: 100px;
}

.videomessages .actionbuttons button.stop_btn {
    background-color: #ff1a1a;
    border-color: #ff1a1a;
}

.videomessages .actionbuttons button.stop_btn:active{
   background-color: #ff1a1a;
   border-color: #ff1a1a;
}

.videomessages .actionbuttons button.mute_btn {
   background-color: #227920;
   border-color: #227920;
}

.videomessages .actionbuttons button.mute_btn:active{
  background-color: #227920;
  border-color: #227920;
}

.videomessages .actionbuttons .fa:before {
    color: #ffffff;
}

.videomessages .videochat .opponent_name {
   position: relative;    
   border: 1px solid;
   padding: 10px;
   border-radius: 15px;
   background-color: #999;
   color: #fff;
   text-align: center;    
}

.over_all_row_contact {
    padding-left: 15px;
}

.top_span_contact_enquiry .label {
    font-weight: 500;
    float: left;
    width: 80px;
}

.login_logo_img {
    width: 200px !important;
}

.login_card {
    /* background-color: #80808040;
    border-radius: 20px;
    height: auto;
    margin: 0 auto;
    margin-top: 10%; */
    width: 60%;
}

.login_input {
    width: 75% !important;
    border-radius: 7px !important;
    height: 40px !important;
}

.login_inner_card {
    text-align: center;
    padding: 30px 0px;
}

.login_but {
    background-color: #226920 !important;
    border-color: #226920 !important;
    padding-left: 44px !important;
    padding-right: 44px !important;

}

.login_but_div {
    padding-left: 0px;
    margin-top: 0rem !important;

}

.change_pass_div {
    padding-left: 10px !important;
}

.login_inner_card .field.mt-5>label {
    margin-bottom: 10px;
}

.submitted_client_questionnaire {
    float: left !important;
}

.client_question_span_client {
    display: flex;
}

.client_question_span_client a {
    padding-left: 20px;
    padding-top: 8px;
}

.flex {
    display: flex;
}

.row_checkbox_div {
    display: block !important;
    margin-top: 10px;
    margin-bottom: 10px;
}

.row_checkbox_div input {
    width: 10%;
}

.row_checkbox_div label {
    display: inline;
}

.list_team_heading_row {
    margin-top: 10px !important;
}

.list_team_heading_row_name {
    margin-top: 20px !important;
    display: inline !important;
}

.manager_dashboard .list_team_heading_row_name .teamname {
    float: left;
    font-size: 17px;
    font-weight: 500;
}

.manager_dashboard .list_team_heading_row_name .teamsize {
    font-size: 17px;
    font-weight: 500;
}

.manager_dashboard .memberslist {
    margin-top: 10px !important;
    margin-left: 7px;
}

.list_team_heading_row span {
    font-size: 17px;
    /* font-weight: 500;  */
}

.overall_top_row_manager {
    margin-top: 20px;
}

.radio_edit_user_manager {
    width: 2% !important;
    margin-left: 17px;
}

.radio_edit_user_manager_span {
    width: 90% !important;
}

.radio_row_gender_manager {
    display: block !important;
}

/* .manager_appointment_list_admin td{
   display: contents;
} */
.in_page_design_relative {
    position: relative;
    top: 9px;


}

.pick_date_apppointment_by_client .calendar_icon_div i {
    position: absolute;
    left: -25px;
    top: 0;
    pointer-events: none;
}

.dietplan_tab .headerError {
    color: red;
}

.dietplan_tab .ing_more_Error{
   color: red;
   font-weight: bold;
   border: 1px solid #999;
   border-radius: 20px;
   padding: 10px;
   text-align: left;
   margin-top: 20px;
}

.checkbox_div_manager_access .col_1 {
    width: 20%;
    display: inline-block;
}

.checkbox_div_manager_access .col_2 {
    width: 80%;
    display: inline;
}

.checkbox_div_manager_access .col_1 label {
    font-weight: 600;
}


.book_appointment_popup .pick_date_apppointment_by_client .react-datepicker__tab-loop,
.reschedule_client_popup .pick_date_apppointment_by_client .react-datepicker__tab-loop {
    float: left;
}

.regular_availability .requiredError {
    color: red;
    padding: 10px;
    text-align: center;
    /* border: 1px solid #333; */
    border-radius: 20px;
    margin-bottom: 10px;
}

.coach_calendar .appointments.rbc-calendar {
    display: block;
}

.joinlink {
    color: blue;
}

.display_block {
    display: block;
}

.float_right {
    float: right;
}

#calendar_caoch_tab_menu {
    position: relative;
}

#calendar_caoch_tab_menu .num {

    position: absolute;
    top: 10px;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    padding: 5px 10px;
    background-color: #226920;
    border-radius: 20px;
    right: -10px;

}

.coach_calendar_table_appointment_history .c-avatar {
    display: block;
}

.coach_calendar_table_appointment_history .c-avatar__status {
    /*right: 82% !important;
   top: 37%;*/
    margin-top: 20px;
    margin-left: -20px;
}

.side-navli .msgnew {
    color: #ff6d05;
}

/* .connected_devices_button {
    margin-left: 22px;
} */

.client_dash_top_butt {
    border-radius: 8px !important;
}

.client_dash_upcoming_cal {
    font-size: 14px !important;
    margin-right: 8px;
}

.online_client_dash_up {

    left: 18%;
    top: 63%;

}

.client_dash_profile {
    position: relative;
}

.online_client_dash {
    position: absolute;
    top: 61%;
    left: 62%;
}

.client_food_diary_first_row .col_2 .fif_calendar_icon {
    left: 93%;
    position: relative;
    z-index: 2;
    top: -9px;
}

.image_upload_blog_div .img_upload_show {
    width: 50%;
}

.image_show_div {
    position: relative;
    margin-top: 30px;
}

.cross_image_blog_show {
    position: absolute;
    right: 50%;
    cursor: pointer;
}

.card-container {
    width: 300px;
    height: 440px;
    background-color: #fff;
    border-radius: 8px;

    box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.card-image img {
    height: 220px;
    width: 100%;
    border-radius: 8px 8px 0 0;
    background-size: cover;
}

.card-image {
    margin-top: 20px;
}

.card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    min-height: 200px;
}

.card-badge {
    text-transform: uppercase;
    background-color: #fff;
    color: #fff;
    padding: 2px 8px;
    border-radius: 70px;
    margin: 0;
    font-size: 12px;
}

.card-badge-blue {
    background-color: #226920;
    margin-top: 8px;
}

.card-badge-purple {
    background-color: #3d1d94;
}

.card-badge-pink {
    background-color: #c62bcb;
}

.card-body h1 {
    font-size: 16px;
    margin: 8px 0;
}

.card-subtitle {
    width: 100%;
    word-break: break-all;

    font-size: 14px;
}

.card-body p {
    font-size: 14px;
    margin: 8px 0 16px 0;
}

.card-author {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.card-author p {
    margin: 0 16px;
    font-size: 12px;
}

.card-author p:last-child {
    color: #888;
}

.card-author img {
    border-radius: 50%;
    height: 48px;
    width: 48px !important;
    margin-top: auto;
}

.card_view_row {
    display: flex;
    padding-left: 10px;
    margin-top: 10px;

}

.card-container {
    width: 30% !important;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.container_card_inner {
    width: 100%;
}

.detailed_row_blog {
    margin-top: 10px !important;
    background-color: #fff;
    padding-bottom: 10px;
    display: inline-block !important;
    width: 100%;
    margin-left: 0 !important;
}

.detailed_row_blog .col2 img {
    width: 100%;
}

.card_view_blog_container {
    cursor: pointer;
    min-height: 350px;
    background-color: white;
    border-radius: 23px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding-left: 0px !important;
    padding-right: 0px !important;
    padding-bottom: 20px !important;
    float: left;
}

.card_image_blog {
    max-height: 300px;
}

.card_image_blog img {
    width: 100%;
    height: 300px;
}

.blog_title {
    font-size: 16px;
}

.blog_info_block {
    padding-left: 10px;
    margin-top: 10px;
}

.service_agreement_available_tags {
    display: inline-grid;
    font-weight: 600;
}

.pdf_download_agreement_td {
    padding-top: 15px !important;
}

.pointer_pdf_i {
    cursor: pointer;
}

.preview_service_agreement_popup .modal-body {
    word-break: break-all;
}

#gatewayenable_1, #gatewayenable_2, #gatewayenable_3 {
    cursor: pointer;
}

.gateway_row {
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #80808054;
    border-radius: 12px;
    margin-bottom: 15px;
    display: flex;
}

.gateway_row img {
    /* height: 50px !important; */
    width: 50px !important;
}

.gateway_row .title {
    font-weight: 500;
    font-size: 15px;
}

.gateway_row .desc {
    font-size: 12px;
}

.gateway_choose_radio {
    cursor: pointer;
    float: right;
    width: 19px;
    height: 19px;
    margin-top: 14px;
}

.gateway_image_div {
    width: 20%;
}

.gateway_title_desc_div {
    width: 57%;
}

.gateway_radio_div {
    width: 20%;
}

.plan_details_div_client_sub {
    display: grid;
}

.plan_details_div_client_sub .plan_span {
    margin-bottom: 10px;
}

.plan_details_div_client_sub .plan_label {
    font-weight: 500;
}

.connect_stripe_coach_div {
    width: 54%;
    background-color: white;
    height: 158px;
    margin-left: 18%;
    margin-top: 8%;
    border-radius: 10px;
    padding-top: 10px;
}

.connect_stripe_coach_div h5 {
    text-align: center;
}

.connect_with_stripe_button {
    background-color: #226920 !important;
    width: 60% !important;
    border-radius: 25px !important;
    margin-left: 21%;
    margin-top: 4%;

}



.coach_plans .trow.not_fulfilled_recipe {
    background-color: #fafad2;
}

.coach_plans .color_explanation {
    font-size: 12px;
}

.coach_plans .color_explanation #demo_color {
    display: inline-block;
    width: 50px;
    height: 20px;
    background-color: #fafad2;
    border: 1px solid #999;
    border-radius: 10px;
    vertical-align: bottom;
}

.coach_plans .color_explanation #no_color {
   display: inline-block;
   width: 50px;
   height: 20px;
   background-color: #fff;
   border: 1px solid #999;
   border-radius: 10px;
   vertical-align: bottom;
}

.videomessages .videochat.hide {
    display: none;
}

.coachpayments .row.table_row_payment_history {
    display: inline;
}

.coach_calendar .coach_calendar_table_appointment_history tr {
    border-style: solid !important;
}

.coachquestionnaire_add .top_billing_coach_payment,
.coachquestionnaire_edit .top_billing_coach_payment,
.coachquestionnaire_list .top_billing_coach_payment {
    padding: 10px 20px !important;
}

.react-form-builder .react-form-builder-toolbar {
    margin-top: 0 !important;
}

.coachquestionnaire_list .top_billing_coach_payment {
    height: 45px;
    margin-right: 0;
}

.adminmanageteam .rightmenu button {
    background-color: #226920 !important;
}

.userdrptag .notification_drop_changes .notification_list {
    max-height: 150px;
    overflow: auto;
    width: 100%;
}

.plan_expiry_coach {
    color: gray;
    font-weight: 600;
}

.admin_analytics .usertab .Tob_button {
    width: 100% !important;
    display: inline-block;
}

.admin_analytics .usertab .Tob_button .earning_tab {
    float: left;
}

.admin_analytics .usertab .users_chart {
    background-color: #fff;
    margin-bottom: 20px;
}

.admin_analytics .usertab .Tob_button span {
    padding-bottom: 2px !important;
}


.admin_analytics .usertab .filter_analytics_button {
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
    margin-right: 5px;
    margin-top: 5px;
}

.admin_analytics .usertab .commission_inner_input[type=text] {
    width: auto;
}

.coach_add_member.confirm_delete .btn-secondary {
    float: right;
}

.delete_payment_but {
    margin-top: 20px !important;

}

.allowed_clients_sub_row {
    margin-top: 20px !important;
}

.allowed_clients_sub_row span {
    font-weight: 500;
}

.centered_subscription {
    padding-left: 0px !important;
    text-align: center;
}

.cancel_subscription_but {
    margin-top: 7px;
}

.plan_expires_coach {
    color: red;
    font-weight: 500;
}

.plan_expires_coach.active, .plan_col .due_date.active{
   color: #226920;
   border: 1px solid;
   border-radius: 20px;
   padding: 5px;
}

.subscription_plan_row .active_plan {
    border: 2px solid #80808073;
}

.coach_plan_recipe_modal_popup .recipe_data img {
    width: 100%;
}

.low_level_button_subscriptions {
    text-align: center;
    margin-bottom: 20px;
}

.client_ebook .keto_book_img_info_row {
    display: inline-block;
}

.client_ebook .keto_book_img_info_row .keto_book_image_col {
    float: right;
}

.food_diary_client_popup .search_library_food_row input {
    margin-top: 0;
}

.food_diary_client_popup .coach_meal_plan_food_diary {
    display: block !important;
}

.food_diary_client_popup .coach_meal_plan_food_diary .inner_popup_select {
    margin-bottom: 20px;
}

.client_food_diary .food_diary_table .table_list_span {
    display: inline-block;
    line-height: 1em;
}

.client_subscription_promo_div,
.client_sub_pay_choose .promo_title {
    font-weight: 500;
    font-size: 15px;

}

.client_sub_pay_choose {
    margin-top: 15px;
}

.client_subscription_promo_div .apply_promo_button {

    border: 1px solid #226920 !important;
    border-radius: 10px;
    padding: 5px 10px;
    font-weight: 500;
    color: #ffffff !important;
    background-color: #226920 !important;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;

}

.client_sub_promo_list_container .promo_details_public {
    width: 60% !important;
    /* display: flex;
   justify-content: center;
   align-items: center; */
    min-height: 60px;
    padding-top: 7px;
}

.client_sub_promo_list_container .promo_details_public_button {
    width: 40% !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.client_sub_promo_list_container_top .promo_details_public_row {
    border: 1px solid gray;
    border-radius: 12px;
    margin-bottom: 10px;
    background-color: #0080000f;
}

.container-mobile-menu .fa {
    color: #226920 !important;
}

.client_subscription_promo_div .promo_code_input {
    width: 50% !important;
}

.client_sub_order_details .order_details_row1 {
    display: flex;
}

.order_details_row1 .order_details_col1 {
    width: 50%;
}

.order_details_row1 .order_details_col2 {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.final_amount_row {
    font-weight: 600;
}

.available_promo_list_container {
    margin-top: 15px;
}

.order_details {
    border-bottom: 1px solid black;
}

.client_sub_promo_list_container_top .promo_details_public p {
    margin: 0;
}

.client_sub_promo_list_container_top .promo_details_public .short_info {
    font-size: 11px;
}

#container-adjust .navbar {
    display: block !important;
}

.mobile_menu .mobile_menu_logo {
    display: flex;
    height: 75px;
    margin-left: 5px;
    background: white;
}

.container-mobile-menu img {
    position: absolute;
    z-index: 3;
    width: 200px;
    top: 0px !important;
}

.container-mobile-menu .menu-items {
    min-width: 250px;
}

.container-mobile-menu .fa.close_mobile_menu {
    border: 1px solid #226920;
    border-radius: 30px;
    padding: 2px;
}

.manager_edit_coach .modal-body input {
    margin-bottom: 20px;
}

.manager_edit_coach .modal-body .radio_edit_user_manager_span {
    margin-right: 20px;
}

.manager_edit_coach .modal-body .radio_edit_user_manager {
    width: auto !important;
    margin-right: 5px;
}

.admin_table_div_analytics .admin_table .payment_th_td_center {
    text-align: center;
}

.label_add_manager_div .gender_manager_span {
    margin-right: 10px;
}

.gender_manager_err {
    padding-left: 25px !important;
}

.access_sett_icon {
    margin-right: 8px;
}

.manager_manageappointments .upcoming_appointment_table tr {
    border-style: solid !important;
}

.coach_users #users_list .profile_program .category_box {
    display: block !important;
}

.address_row_manage_users input {
    width: 80%;

}

.edit_address_manager {
    width: 95% !important;
    margin-left: 10px !important;

}

.err_border {
    box-sizing: border-box !important;
    border: 1px solid red !important;
    outline: none !important;
}

#err_border {
    box-sizing: border-box !important;
    border: 1px solid red !important;
    outline: none !important;
}

.login_reacptcha {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.add_food_journal .add_food_journal_plus_div {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 25px;
    gap: 1rem;
    width: 100%;
    margin-top: 25px;
    background-color: white;
    padding-bottom: 20px;
    border-radius: 8px;

}

.add_food_journal .add_food_journal_plus_div img {
    width: 7%;
    border: 1px solid #80808099;
    border-radius: 70px;
    padding: 23px;
    cursor: pointer;
}
.add_food_journal .add_food_journal_plus_div .fa-plus
{
   border: 1px solid #8bc34a;
   border-radius: 50%;
   padding: 23px;
   cursor: pointer;

}

.add_food_journal .add_food_journal_plus_div p {
    font-size: 17px;
    font-weight: 600;
}

.add_food_journal .add_food_journal_top_head span {
    font-size: 20px;
    font-weight: 600;
}

.add_food_journal .add_food_journal_top_head select {

    padding: 5px;
    border-radius: 6px;
    float: right;
    font-size: 13px;
    border-color: #8080805e;
    cursor: pointer;

}

.add_food_journal .add_food_journal_top_head select:focus {
    border-color: #8080805e;
}

/* .add_food_journal .food_journal_list_div{
   display: flex;
   flex-direction: row;
   gap: 2rem;
   flex-wrap: wrap;

}
.add_food_journal .food_journal_list_div .list_col img{
   width: 50%;
   height: 80%;

} */

/* .add_food_journal .food_journal_list_div .list_col{
   max-width: 30%;
} */
.add_food_journal .food_journal_list_div {
    width: 100%;
}

/*  .add_food_journal .food_journal_list_div .list_col {
    width: 31%;
    float: left;
    margin-right: 20px;
    background-color: white;
    margin-bottom: 20px;
    position: relative;

} */

/*  .add_food_journal .food_journal_list_div .list_col .fa-trash {
    position: absolute;
    top: 11px;
    color: #226920 !important;
    right: 6px;
    background: #fff;
    padding: 6px;
    border-radius: 11px;
    font-size: 15px !important;

} */

/*  .add_food_journal .food_journal_list_div .list_col .fa-edit {

    position: absolute;
    color: green !important;
    top: 10px;
    left: 10px;
    background: #fff;
    padding: 6px;
    border-radius: 11px;
    font-size: 15px !important;

} */



.add_food_journal .food_journal_list_div .list_col .img_food_jour img {
    /* width: 100%;
    border-radius: 4px;
    height: 280px; */
}

.add_food_journal .food_journal_list_div .list_col .food_journal_details p {
    margin: 0;

}

.add_food_journal .food_journal_list_div .list_col .food_journal_details .type {
    font-weight: 600;
}

.add_food_journal .food_journal_list_div .list_col .food_journal_details .date {
    font-size: 12px;
    color: gray;
}

/*  .add_food_journal .food_journal_list_div .list_col .food_journal_details .desc {
    font-size: 13px;
    font-weight: 500;
    min-height: 180px;
    word-break: break-all;
} */


.add_food_journal .food_journal_list_div .list_col .food_journal_details .comments {


    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #8080801a;
    font-size: 12px;
    padding-left: 5px;
    border-radius: 2px;
    padding-right: 15px;
    margin-top: 20px;


}

.add_food_journal .food_journal_list_div .list_col .food_journal_details .comments span {
    float: right;
    cursor: pointer;
}

.add_food_journal .food_journal_list_div .list_col .add_input_div {
    margin-top: 10px;
    position: relative;
}

.add_food_journal .food_journal_list_div .list_col .add_input_div .fa-paper-plane {
    position: absolute;
    right: 0px;
    top: 0px;
    background: transparent;
    color: green !important;
    padding: 6px;
    border-radius: 11px;
    font-size: 15px !important;
}


.add_food_journal .food_journal_list_div .list_col .add_input_div input {
    width: 100%;
    box-sizing: border-box !important;
    border: 1px solid gray !important;
    outline: none !important;
    border-radius: 5px;
}

.address_row_manage_users input {
    width: 80%;
}

.edit_address_manager {
    width: 95% !important;
    margin-left: 10px !important;
}

.err_border {
    box-sizing: border-box !important;
    border: 1px solid red !important;
    outline: none !important;
}

.add_food_journal_popup .add_image_plus {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add_food_journal_popup .add_image_plus img {
    width: 12%;
    border: dashed 2px #0080007a;
    border-radius: 35px;
    padding: 11px;
    cursor: pointer;
}

.add_food_journal_popup .add_image_plus p {
    margin: 0;
    font-weight: 600;
}

.add_food_journal_popup .image_list img {
    border: 2px solid #ffa705;
    background-size: cover;
    border-radius: 50% 50% 50% 50%;
    width: 49px;
    height: 45px;
}

.add_food_journal_popup .image_list {
    background-color: #8080800d;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.add_food_journal_popup .image_list .fa-trash {
    float: right;
    margin-top: 15px
}

.add_food_journal_popup .image_list span {
    margin-left: 20px;
    font-weight: 500;
}

.add_food_journal_popup .desc_row textarea {
    width: 100%;
    box-sizing: border-box !important;
    border: 1px solid gray !important;
    outline: none !important;
    border-radius: 5px;

}

.add_food_journal_popup .date_meal_row .date {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add_food_journal_popup .date_meal_row .date .react-date-picker__wrapper {
    border-radius: 5px;
}

.add_food_journal_popup .date_meal_row .meal_type {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.add_food_journal_popup .desc_row {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.manager_contact_enquiry_modal .modal-footer {
    text-align: center;
}

.admin_contact_enquiry_modal .modal-footer {
    text-align: center;
}

.pagenumber_div_journal {
    width: 100%;
}

.food_journal_detail .food_journal_detail_top_row {
    display: flex;
    gap: 1rem;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #80808063;
}

.food_journal_detail .food_journal_detail_top_row .type {
    font-size: 20px;
    font-weight: 600;
}

.food_journal_detail .food_journal_detail_top_row .date {
    font-size: 15px;
    color: gray;
    padding-top: 6px;
}

.food_journal_detail .food_journal_detail_top_row .date {
    font-size: 15px;
    color: gray;
    padding-top: 6px;
}

.food_journal_detail .food_journal_detail_top_row .edit_icon {
    font-size: 15px !important;
    color: gray !important;
    padding-top: 10px !important;
}

.food_journal_detail .journal_detail_comment_row {
    display: flex;
    margin-top: 10px;

}

.food_journal_detail .journal_detail_comment_row .detail {
    width: 100%;

}

.food_journal_detail .journal_detail_comment_row .detail img {
    width: 100%;
    border-radius: 7px;

}

.food_journal_detail .journal_detail_comment_row .detail .desc {
    font-size: 13px;
    font-weight: 500;
    max-height: 120px;
    min-height: 80px;
    word-break: break-all;
    white-space: normal;
}

.food_journal_detail .journal_comment_row{
   border-top: 1px solid orange;
   margin-top: 10px;
}

.food_journal_detail .journal_comment_row .comments_list_row .comment_list p {
    margin: 0;
    margin-bottom: 10px;
}

.food_journal_detail .journal_comment_row .comments_list_row .comment_list {

    border-bottom: 1px solid #8080802b;
}

.food_journal_detail .journal_comment_row .comments_list_row {
    margin-top: 5px;
}

.food_journal_detail .journal_comment_row .comments_list_row .comment_list .comment_info span {

    width: 30%;

}

.food_journal_detail .journal_comment_row .comments_list_row .comment_list .comment_info .date {

    float: right;

}

.food_journal_detail .journal_comment_row .comments_list_row .comment_list .comment_info .info {
    float: right;
    cursor: pointer;
    font-size: 15px !important;
    margin-right: 5px;
    padding-top: 6px;

}

.edit_journal_comment .add_input_comment_div {
    width: 100%;
    box-sizing: border-box !important;
    border: 1px solid gray !important;
    outline: none !important;
    border-radius: 5px;
}

.journal_comment_row .comment_label {
    font-weight: 500;
}

.meal_type_drop {
    border-radius: 5px;
}

.list_row_add_data .add_data_button {
    width: 100%;
    background-color: #8080803d !important;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-radius: 4px;
    color: black;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;

}

.list_row_add_data .active_button {
    background-color: #226920 !important;
    color: #fff !important;
}



.list_row_add_data .add_data_button i {
    float: right;
}

.list_row_add_data {
    display: flex;
    flex-direction: column;
    width: 37%;
    /* align-items: center; */
}

.add_activity_data_popup .data_type select {
    padding: 8px 9px !important;
    font-size: 15px;
    border-radius: 3px;
    width: 70%;
    outline: none;
    cursor: pointer;
}

.add_activity_data_popup .data_type label {
    font-weight: 600;
    margin-right: 5px;
}

.date_row_physical_activity {
    display: flex;
}

.date_row_physical_activity .date,
.month,
.year {
    width: 30%;
}

.date_row_physical_activity .month select {
    padding: 4px 10px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
}

.date_row_physical_activity .date select {
    padding: 4px 10px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
}

.date_row_physical_activity .year select {
    padding: 4px 10px;
    border-radius: 3px;
    outline: none;
    cursor: pointer;
}

.physical_activity .date_row {
    margin-top: 10px;
}

.physical_activity .date_row .date_label {
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    gap: 2.77rem;
}

.hematocrit_row .date_row {
    margin-top: 10px;
}

.hematocrit_row .date_row .date_label {
    font-weight: 500;
    margin-bottom: 10px;
    display: flex;
    gap: 8.77rem;
}

.physical_activity .activity_info_row {
    display: flex;
    gap: 1rem;
}

.physical_activity .activity_info_row .colum {
    width: 30%;
}

.physical_activity .activity_info_row .colum>* {
    width: 100%;
}

.physical_activity .activity_info_row .colum .label {
    font-weight: 500;
    margin-bottom: 10px;
}

.physical_activity .activity_info_row .colum select {
    border-radius: 3px;
    cursor: pointer;
}

.physical_activity .activity_info_row .colum input[type='number'] {
    border-radius: 3px;
    outline: none;
    border: 1px solid #7c7f83;
}

.physical_activity .notes_row .label {
    font-weight: 500;
}

.physical_activity .notes_row textarea {
    width: 100%;
    border-radius: 3px;
}

.hematocrit_row .input_row label {
    font-weight: 500;
    width: 180px;
    display: inline-block;

}

.hematocrit_row .input_row {
    margin-top: 20px;
}

.hematocrit_row .notes_row {
    margin-top: 20px;
}

.hematocrit_row .notes_row .label {
    font-weight: 500;
}

.hematocrit_row .notes_row textarea {
    width: 100%;
    border-radius: 3px;
}

.physical_activity .date_row .date_label .react-date-picker__wrapper {
    border-radius: 3px;
}

.physical_activity .colum select {
    border-radius: 3px;
}

.physical_activity .notes_row {
    margin-top: 10px;
}

.second_tab_add_data {
    width: 63%;
}

.add_data_popup {
    display: flex;
}

.second_tab_add_data .choose_type_tab {
    text-align: center;
    background-color: #8080801f;
    width: 70%;
    margin-left: 30px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 9px;
}

.second_tab_add_data .choose_type_tab .choose_type_tab_option {
    background-color: #fff;
    width: 80%;
    margin-left: 23px;
    font-weight: 600;
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 13px;
    cursor: pointer;

}

.physical_activity .field_top_row {
    margin-left: 10px;
    margin-bottom: 10px;
}

.physical_activity .field_top_row .label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
}

.physical_activity .field_top_row .centered_label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
    text-align: center;
}

.physical_activity .field_top_row .centered_label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
    text-align: center;
}

.physical_activity .field_top_row .left_right_label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;

}

.physical_activity .field_top_row .left_right_label .right {
    float: right;

}

.input-range__slider {
    background: #226920 !important;
}

.input-range__track--active {
    background: #226920 !important;
}

.physical_activity .field_top_row .slider_input {
    margin-top: 25px;
    margin-bottom: 25px;
}

.physical_activity .field_top_row .two_col_field {
    display: flex;
}

.physical_activity .field_top_row .two_col_field .col1,
.col2 {
    width: 50% !important;
}

.physical_activity .field_top_row .three_col_field {
    display: flex;
}

.physical_activity .field_top_row .three_col_field .col1,
.col2,
.col3 {
    width: 30%;
}






.physical_activity .field_top_row .label_info {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 5px;
}

.physical_activity .field_top_row .label_info_a {

    font-size: 13px;

}



.physical_activity .field_top_row .checkbox_field {
    display: flex;
    gap: 1rem;

}

.physical_activity .field_top_row .checkbox_field_in_line {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;

}



.physical_activity .field_top_row .checkbox_field .checkbox {
    display: flex;
    align-items: baseline;
    gap: 1rem;

}

.physical_activity .field_top_row .checkbox_field_in_line .checkbox {
    display: flex;
    align-items: baseline;
    gap: 1rem;

}


.physical_activity .field_top_row .field .checkbox_add_data_label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
}

.physical_activity .field_top_row .field {
    width: 100%;
}

.physical_activity .field_top_row .css-1yn2e29-InputColor {
    width: 100%;
}

.physical_activity .heading_popup {
    margin-left: 10px;
    margin-bottom: 10px;
}

.physical_activity .field_top_row .date_time_same {
    margin-bottom: 10px;
}

.physical_activity .field_top_row .field .react-date-picker,
.react-time-picker,
.raw_select,
.raw_input {
    width: 100%;
}

.physical_activity .field_top_row .field .react-date-picker__wrapper,
.react-time-picker__wrapper,
.raw_select {
    border-radius: 7px;
    border-color: #80808070 !important;
}

.physical_activity .field_top_row .field .raw_input,
.text_area_row {
    border-radius: 7px;
    outline: none;
    border: 1px solid #80808070 !important;
    width: 100% !important;
}

.physical_activity .field_top_row .field .raw_select {
    cursor: pointer;
    padding-top: 5x;
    padding-bottom: 5px;
}

.physical_activity .button_row {
    display: flex;
    gap: 1rem;
    padding-left: 10px;
}

.clientdashboard .top_button_col button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.coach_users .labtest_tab .title_block {
    margin-bottom: 20px;
    display: flex;
}

.coach_users .labtest_tab .title_left {
    width: 80%;
}

.coach_users .labtest_tab .title_left .title {
    float: left;
}

.coach_users .labtest_tab .title_left .testselect {
    margin-left: 20px;
    margin-right: 20px;
    width: 200px;
    float: left;
}

.coach_users .labtest_tab .labtest_sample_table .td_icon.action {
    margin-left: 5px;
}

.coach_users .labtest_tab .labtest_sample_table th {
    width: 20%;
}

.coach_users_labtest_addbiomarkers_popup .datepicker {
    /* width: 170px; */
    width: 100%;
}

.coach_users_labtest_addbiomarkers_popup .timepicker {
    /* width: 120px; */
    width: 100%;
}

.coach_users_labtest_addbiomarkers_popup .timepicker .react-time-picker__inputGroup__amPm {
    float: right;
}

.coach_users_labtest_addbiomarkers_popup .datepicker .react-date-picker__wrapper {
    padding: 2px;
    border-radius: 15px;
    border: 1px solid #7c7f83 !important;
}

.coach_users_labtest_addbiomarkers_popup .timepicker .react-time-picker__clock {
    display: none;
}

.coach_users_labtest_addbiomarkers_popup .timepicker .react-time-picker__wrapper {
    padding: 5px;
    border-radius: 15px;
    border: 1px solid #7c7f83 !important;
}

.coach_users_labtest_addbiomarkers_popup .row {
    margin-bottom: 10px;
}

.coach_users_labtest_addbiomarkers_popup .row label {
    margin-bottom: 5px;
}

.coach_users_labtest_addbiomarkers_popup .submenu {
    right: auto !important;
}

/* .coach_users_labtest_addbiomarkers_popup .submenu li{
   display: block;
} */

.coach_users_labtest_addbiomarkers_popup .submenu li input {
    margin-right: 5px;
}

.coach_users_labtest_addbiomarkers_popup .submenu .checkbox {
    display: inline-block;
}

.coach_users_labtest_addbiomarkers_popup .submenu .checkbox.active {
    color: #bfbcbc;
    display: inline-block;
}

.generate_data_report_pop .field_top_row .label {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 10px;
}

.generate_data_report_pop .field_top_row .field .raw_select {
    cursor: pointer;
    padding-top: 5x;
    padding-bottom: 5px;
}

.generate_data_report_pop .from_date_div_top_row {
    margin-top: 25px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_test_btn {
    width: 100%;
    border: 1px solid lightgray;
    color: #666;
    background-color: #fff;
    margin-bottom: 15px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests {
    background-color: #f8f8fa;
    margin: auto;
    padding-bottom: 20px;
    padding-top: 10px;
    border-radius: 15px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs {
    margin: 0 auto;
    border-top: 1px solid #cecece;
    padding-top: 20px;
    border-bottom: 1px solid #cecece;
    margin-bottom: 10px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs label {
    padding-top: 5px;
    padding-right: 10px;
    text-align: left;
    height: 30px;
    font-size: 14px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs input {
    border: 1px solid #cecece;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs .fa {
    cursor: pointer;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs .input-group-text {
    min-width: 70px;
    overflow: hidden;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests_inputs .fa-trash {
    padding-top: 10px;
    padding-left: 10px;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests .Dropdown_menu-right__ma-wo {
    left: auto;
}

.coach_users_labtest_addbiomarkers_popup .add_new_tests li:hover {
    background-color: #226920;
    color: #fff;
}

.profile_info .add_data_coach {
    float: right;
    position: relative;
    bottom: 24px;
    padding: 7px 31px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
}

.adminlabtest_add_subcategory_popup .category_label_subcategory {
    color: #6c757d;
}

.adminlabtest_add_subcategory_popup .category_label_subcategory label {
    padding-right: 10px;
}

.adminlabtest_add_popup select,
.adminlabtest_edit_popup select {
    margin-bottom: 10px !important;
}

.generate_report_button {
    padding: 4px 24px;
    border-radius: 4px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
    float: right;
    margin-right: 5px;
}

.generate_report_button_div {
    display: flex;
    justify-content: center;
}

.adminlabtest_cat_edit_popup label,
.adminlabtest_subcat_edit_popup label {
    padding-right: 10px;
}

.coach.add_service_pop .navigation_category {
    float: right;
}

.onhold_stripe_div_card_row {
    margin-top: 10px !important;
}

.stripe_avail_row {
    top: 45px;
}

.coach_users .labtest_tab .labtest_data_table .table .unit_label {
    display: inline-block;
    background: #fffcfc;
    border: 1px solid #cecece;
    padding: 3px;
    border-radius: 10px;
}

.coach_users .labtest_tab .labtest_data_table .table .unit_label label {
    vertical-align: middle;
    height: 20px;
    width: 90px;
    overflow: hidden;
}

.coach_users .labtest_tab .labtest_data_table .table .unit_label span.input-group-text {
    padding: 0 5px;
    float: right;
}

.coach_users .labtest_tab .labtest_data_table .table .date_section .fa-edit {
    padding-left: 5px;
}

.coach_users .labtest_tab .labtest_data_table .table .date_part th,
.coach_users .labtest_tab .labtest_data_table .table .date_part td {
    width: 100px;
}

.coach_users .labtest_tab .labtest_data_table .table td {
    vertical-align: middle;
    text-align: center;
}

.date_picker_payment_coach {
    width: 20% !important;     
}

.coachpayments .date_picker_payment_coach.sdate {
   float: left;
   margin-right: 75px;
}

.coachpayments .date_picker_payment_coach.edate {
    float: left;
}

.date_picker_payment_coach .react-date-picker__wrapper {
    border-radius: 4px;
}

.payment_coach_filter_button {
    /* width: 10% !important; */
    padding: 10px 58px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
    margin-right: 4px;
}

.coachpayments .payment_coach_filter_button, .admin_billing_payment_history .payment_coach_filter_button_admin, .admin_billing_refund_history .payment_coach_filter_button_admin{
   padding: 10px 20px;
   float: right;
   margin: 5px;
}

.payment_coach_filter_button_admin {
    /* width: 10% !important; */
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
    margin-right: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
}

.filter_div_admin_payment_history {
    display: flex;
    gap: 1rem;
    margin-bottom: 10px;
}

.admin_manageusers_user_edit_popup label,
.admin_manageusers_add_coach_popup label,
.admin_manageusers_add_client_popup label {
    padding-top: 20px;
    padding-right: 10px;
    text-align: left;
    height: 30px;
}

.admin_manageusers_user_edit_popup .err span,
.admin_manageusers_add_coach_popup .err span,
.admin_manageusers_add_client_popup .err span {
    font-size: 12px;
}

.coach_users .labtest_tab .labtest_data_table {
    display: flex;
}

.coach_users .labtest_tab .labtest_data_table .thead tr {
    background-color: #fff;
    position: sticky;
    top: 0;
}

.coach_users .labtest_tab .labtest_data_table .thead th {
    text-align: center;
}

.coach_users .labtest_tab .labtest_data_table tr.hdr {
    height: 60px;
}

/* .labtest_data_table tr.data{
   height:50px;
} */

.coach_users .labtest_tab .labtest_data_table tr.data td {
    height: 50px;
}

.coach_users .labtest_tab .labtest_data_table tr.empty_data {
    height: 50px;
}

.coach_users .labtest_tab .labtest_data_table td {
    min-width: 150px !important;
}

.coach_users .labtest_tab .labtest_data_table .scroll {
    overflow-x: scroll;
    border: 1px solid #cecece;
    border-radius: 10px;
}

.coach_users .labtest_tab .labtest_data_table td.test_title {
    width: 250px !important;
    display: inline-block;
    border-bottom: none;
}

.coach_users .labtest_tab .labtest_data_table .table {
    width: 30%;
}

.coach_users .labtest_tab .labtest_data_table .table h5 {
    padding: 0 0.5rem !important;
}

.coach_users .labtest_tab .title_right .export_disable {
    display: none;
}

.coach_users .labtest_tab .title_right .export_enable {
    display: initial;
    position: absolute;
    right: 0;
    z-index: 3;
    background-color: #fff;
    margin-top: 40px;
    margin-right: 50px;
    width: 120px;
    border: 1px solid #cecece;
}

.coach_users .labtest_tab .title_right .export_enable a {
    color: #666;
    text-decoration: none;
}

.coach_users .labtest_tab .title_right .export_enable li {
    list-style: none;
}

.import_recipe_div {
    display: flex;
    width: 100%;
    justify-content: flex-end;

}

.import_recipe_div .add_recipe_button {
    padding: 10px 58px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
}

.popup_import_div {
    display: flex;
    justify-content: center;
}

.popup_import_div .import_url_button {
    width: 70%;
    padding: 10px 58px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
}

.popup_import_div .popup_import_input {
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 95%;
    height: 30px;
    padding-left: 10px;
    margin-top: 8px;
}

.popup_import_err_div {
    padding-left: 13px;
    margin-top: 10px;

}

.popup_import_err_div .err_span {
    color: red;
}

.importurlsection .closediv i {
    cursor: pointer;
    float: right;
    padding-top: 15px;
}

.importurlsection .closediv .menubox {
    width: 90%;
}

.recipe_overview_section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.recipe_overview_section .input_row {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.recipe_overview_section .input_row .input_text {
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 95%;
    height: 30px;
    padding-left: 10px;
    margin-top: 8px;

}

.recipe_overview_section .input_row .select_drop {
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 95%;
    height: 30px;
    padding-left: 10px;
    margin-top: 8px;

}

.recipe_overview_section .input_row .title {
    font-weight: 500;
}

.recipe_overview_section .input_row .input_textarea {
    border-radius: 5px;
    border: 1px solid #7c7f83;
    width: 95%;
}

.recipe_overview_section .input_row .preview_recipe_img {
    max-width: 200px;
}

.recipe_action_section .save_recipe_button {
    padding: 10px 58px;
    border-radius: 8px;
    color: white;
    background-color: #226920;
    border: none;
    font-size: 15px;
}

.recipe_action_section {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
    width: 94%;
}

.imported_recipe_list .table_section {
    margin-top: 25px;
}

.td_recipe_img {
    object-fit: cover;
    height: 200px;
    width: 200px;

}

.import_recipe_list_aciton_i {
    font-size: 15px !important;
    margin-right: 7px;
    cursor: pointer;
}

.preview_imported_recipe {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.preview_imported_recipe_row .title,
.preview_imported_recipe_column .title {
    font-weight: 600;
}

.preview_imported_recipe_column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}






.coach_users .labtest_tab .labtest_data_table .export_checkbox {
    width: 10px;
    margin-top: -2px;
    vertical-align: middle;
    margin-right: 5px;
}

.recipe_img_preview_div img {
    max-width: 90%;
    border-radius: 15px;
    height: 200px;
}

.recipe_img_preview_div {
    display: flex;
    justify-content: center;
}

.payment_success_page {
    display: flex;
    justify-content: center;
    margin-top: 10%;

}

.payment_success_page .payment_success_card {

    width: 40%;
    /* height: 300px; */
    background-color: #8080802b;
    border-radius: 10px;
    min-height: 300px;
    height: auto;



}

.payment_success_page .payment_success_card .success_tick_row,
.logo_row,
.button_row {
    display: flex;
    justify-content: center;

}

.payment_success_page .payment_success_card .heading_row {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.payment_success_page .payment_success_card .content_row {
    padding-left: 16px;
    padding-right: 4px;
}

.coach_user_msg {
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin-top: 50px;
}


.adminlabtest_add_category_popup label {
    margin-right: 5px;
}

.recipe_library_tab .active {
    color: #226920 !important;
}

.popup_import_notes {

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}

.coach_users_labtest_addbiomarkers_popup .addtest_search {
    margin-left: 10px;
    display: block !important;
}

.coach_users_labtest_addbiomarkers_popup .addtest_search .fa-search {
    margin-left: 10px;
}

.exercise_video_container .heading_row {
    font-weight: 600;
    font-size: 20px;
}

.exercise_video_container .categorised_row {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
}

.exercise_video_container .categorised_row .title {
    font-weight: 600;
    font-size: 16px;
}

.exercise_video_container .categorised_row .title .view_link {
    text-decoration: none;
    font-size: 16px;
    margin-left: 15px;
    background: none !important;
    border: none;
    color: #069;

    cursor: pointer;
}

.exercise_video_container .categorised_row .card_row {
    cursor: pointer;
}


.exercise_video_container .categorised_row .card_row .fitness_thumbnail {
    width: 100%;
}

.exercise_video_container .categorised_row .card_row .video_info .mins {
    float: right;
}

.exercise_video_container .categorised_row .card_row .video_info .minsicon {
    font-size: 14px !important;
    margin-right: 3px;

}


.exercise_video_container .categorised_row .card_row .react-multi-carousel-track {
    gap: 1rem;
}

.exercise_video_container .categorised_row .carousel_row {
    margin-bottom: 10px;
}

.exercise_video_detail_container .top_row {
    display: flex;
}

.exercise_video_detail_container .video_row {
    margin-top: 10px;
}

.exercise_video_detail_container .video_desc_row {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.exercise_video_detail_container .video_details_row {
    display: flex;
    gap: 11.5rem;
}

.exercise_video_detail_container .video_details_row .list_div .title {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

.exercise_video_detail_container .video_desc_row .instructor {
    width: 20%;
}

.exercise_video_detail_container .video_desc_row .about {
    width: 80%;
}

.exercise_video_detail_container .video_desc_row .about .title {
    font-weight: 20px;
    font-weight: 600;
}

.exercise_video_detail_container .video_desc_row .instructor .title {
    font-weight: 20px;
    font-weight: 600;
}


.exercise_video_detail_container .top_row .video_info_col,
.button_col {
    width: 50%;
}

.exercise_video_detail_container .top_row .button_col button {
    float: right;
    background-color: #226920;
    border-color: #226920;
}

.exercise_video_detail_container .top_row .video_info_col .title {
    font-weight: 600;
    font-size: 20px;
}

.exercise_video_detail_container .top_row .video_info_col .inline_info_row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.exercise_video_detail_container .top_row .video_info_col .inline_info_row .rating .fa {
    font-size: 15px !important;
}

.exercise_video_detail_container .top_row .video_info_col .inline_info_row .rating .checked {
    color: orange !important;
}

.exercise_video_detail_container .top_row .video_info_col .inline_info_row .calories .fas {
    color: #ff0000b0 !important;
}

.view_all_videos_container .top_div_row {
    margin-bottom: 10px;
}

.view_all_videos_container .top_div_row button {
    background-color: #226920;
    border-color: #226920;
}

.view_all_videos_container .top_div_row .right_but {
    float: right;
}

.view_all_videos_container .head_row .cat_title {
    font-weight: 600;
    font-size: 20px;
    margin-right: 5px;
}

.view_all_videos_container .head_row .video_count {
    font-weight: 600;
    color: gray;
}

.view_all_videos_container .over_lay_filter_parent {
    position: relative;
}

.view_all_videos_container .over_lay_filter {
    position: relative;
    background-color: #0080000a;
    width: 100%;
    padding: 10px 10px 10px 10px;
    border-radius: 4px;

}

.view_all_videos_container .over_lay_filter_none {
    display: none;
}

.view_all_videos_container .over_lay_filter_show {
    display: block;
}

.view_all_videos_container .over_lay_filter .filter_types {
    display: flex;
    gap: 1rem
}

.view_all_videos_container .over_lay_filter .filter_types .category_div {
    width: 60%;
}

.view_all_videos_container .over_lay_filter .filter_types .difficulty {
    width: 20%;
}

.view_all_videos_container .over_lay_filter .filter_types .video_input {
    margin-right: 6px;
    cursor: pointer;
}

.view_all_videos_container .over_lay_filter .filter_types .category_div .category_list {
    display: inline-block;
}

.view_all_videos_container .over_lay_filter .filter_types .category_div .category_list .p_tag {
    float: left;
    width: 40%;
}

.view_all_videos_container .over_lay_filter .button_div button {
    background-color: #226920;
    border-color: #226920;
}

.view_all_videos_container .over_lay_filter .button_div .right {
    float: right;
}













.view_all_videos_container .video_all_list {
    display: inline-block;
    width: 100% !important;
}

.view_all_videos_container .video_all_list .video_list {
    width: 32%;
    margin-right: 8px;
    margin-bottom: 8px;
    float: left;
    cursor: pointer;
    min-width: 32%;
    max-width: 32%;
    min-height: 250px;
}

.view_all_videos_container .video_all_list .video_list .fitness_thumbnail {
    width: 100%;
}

.view_all_videos_container .video_all_list .video_list .video_info .title {
    font-weight: 600;
}

.view_all_videos_container .video_all_list .video_list .video_info .mins {
    float: right;
}

.view_all_videos_container .video_all_list .video_list .video_info .minsicon {
    font-size: 14px !important;
    margin-right: 3px;

}

.login_screen_section .top_login_row {

    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

}

.login_screen_section .top_login_row .initial_row {

    display: flex;
    justify-content: center;
    padding: 12px;
    align-items: center;
    justify-content: space-around;
    border: 1px solid grey;
    border-radius: 16px;
    box-shadow: 12px 12px 2px 1px rgb(106 106 122 / 20%);



}

/* .login_screen_section .top_login_row .img_div{
   
width: 30%;
} */
.login_screen_section .top_login_row .img_div img {
    max-height: fit-content;
    max-width: 480px;
    min-width: 400px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;

}

.login_screen_section .top_login_row img {
    width: 100%;
}

.google_fitness .title_left {
    float: left;
}

.google_fitness .title_right .action_buttons {
    float: right;
}

.google_fitness .title_right .action_buttons button {
    margin-right: 10px;
    margin-bottom: 10px;
}

.google_fitness .google_signin {
    margin-top: 10%;
    text-align: center;
}

.google_fitness .google_fitness_dashboard{
   margin-top:50px;
}

.google_fitness .google_fitness_dashboard .timeselect{
   width: 200px;
   float: right;    
   margin-top: 50px;
   margin-right: 10px;
}

.google_fitness .google_fitness_dashboard .timeselect .fa {
    float: left;
    margin-right: 10px;
    padding-top: 10px;
}

.main_container_quiz {
    /* background-image: url('https://stagingketodiet.opentestdrive.com/images/main-bg.jpg');
    height: 1500px;
    background-repeat: no-repeat;
    background-size: 100% auto; */
}

.main_container_quiz .top_logo {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.main_container_quiz .top_logo img {
    width: 30%;
    border-radius: 8px;
}

.main_container_quiz .quiz_form_container {
    display: flex;
    justify-content: center;
}

.main_container_quiz .quiz_form_container > div:first-child >div:last-child{
    text-align: center;
}

.main_container_quiz .quiz_form_container button.prev:disabled{
    display:none;
}

.main_container_quiz .quiz_form_container button.cf:disabled{
   opacity: 0.7;
}

.main_container_quiz .quiz_form_container > div:first-child {
width: 50%;
flex: 0 0 100%;
  }
  
.main_container_quiz .quiz_form_container > div:first-child >div:last-child button {
   color: #ffffff;
   border: 1px solid #226920;
   border-radius: 10px;
   font-weight: 500;
   margin-right: 10px;
   padding: 5px 30px;
   background-color:#226920 !important;
}
.main_container_quiz .quiz_form_container > div:first-child >div:last-child button:disabled {
   background-color: #80808073 !important;
}
   
   
.main_container_quiz .quiz_form_container .daily_activity .head_row {
    display: flex;
    justify-content: center;
    font-weight: 600;
}

.main_container_quiz .quiz_form_container .daily_activity .head_row .heading {
    font-size: 20px;
    color: black;
}

.main_container_quiz .quiz_form_container .daily_activity .sub_head_row .heading {
    color: gray;
    font-size: 15px;

}

.main_container_quiz .quiz_form_container .daily_activity .sub_head_row {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

/* .main_container_quiz .quiz_form_container .daily_activity .options_row .options {
 color: white;
 width: 35%;
 float: left;
 padding: 4px;
 margin: 2px;
 border-radius: 6px;
 margin-bottom: 20px;
 margin-right: 10px;
 border: 1px solid white;
} */
.main_container_quiz .quiz_form_container .daily_activity .options_row {
    margin-top: 20px;
}

.main_container_quiz .quiz_form_container .daily_activity .options_row .active {
    border: 2px solid #226920 !important;
}

.main_container_quiz .quiz_form_container .daily_activity .options_row .active p {
    color: #226920 !important;
}

.main_container_quiz .quiz_form_container .daily_activity .options_row .option_div {
    width: 100%;
    float: left;
    border: 1px solid gray;
    border-radius: 6px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-right: 10px;
    cursor: pointer;
    min-height: 75px;
    text-align: center;
    padding-top: 20px;

}

.main_container_quiz .quiz_form_container .daily_activity .options_row .option_div p {
    padding: 0;
    margin: 0;
    color: black;
    padding-left: 3px;

}

.go2150698616 span {
    display: none;
}

.main_container_quiz .quiz_form_container .meat .head_row {
    display: flex;
    justify-content: center;
}

.main_container_quiz .quiz_form_container .meat .head_row .heading {
    font-size: 20px;
    color: black;
}

.main_container_quiz .quiz_form_container .meat .sub_head_row .heading {
    color: #808080;
    font-size: 15px;
}

.main_container_quiz .quiz_form_container .meat .sub_head_row {
    display: flex;
    justify-content: center;
    margin-top: 5px;
}

.main_container_quiz .quiz_form_container .meat .options_div_parent {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    padding-left: 20px;
}

.main_container_quiz .quiz_form_container .meat .options_div {
    display: inline-block;
    width: 100%;
}


.main_container_quiz .quiz_form_container .meat .options_div .options {
    margin-right: 10px;
    margin-bottom: 10px;
    width: 130px;
    float: left;
    text-align: center;
    cursor: pointer;
    height: 150px;
    max-height: 150px;
}

.main_container_quiz .quiz_form_container .meat .options_div .options .img_div {
    border: 1px solid #666666;
    border-radius: 50%;
    padding: 18px;
    min-height: 140px;
    max-height: 140px;
}

.main_container_quiz .quiz_form_container .meat .options_div .options .active {
   border: 3px solid green !important;
}

.main_container_quiz .quiz_form_container .meat .options_div .options .img_div .title {
    color: black;
}

.go169520481 {
    flex-direction: row;
    justify-content: center;
}

.main_container_quiz .quiz_form_container .personal .heading_row {
    display: flex;
    justify-content: center;
    color: black;
    font-size: 25px;
    margin-bottom: 15px;
}

.main_container_quiz .quiz_form_container .personal .inputs_row .full {
    width: 100%;
    /* display: flex; */
    margin-bottom: 15px;
 }

.main_container_quiz .quiz_form_container .personal .inputs_row .full .dob {
   width: 100%;
 }
 
 .main_container_quiz .quiz_form_container .personal .inputs_row .full .dob .label {
      width: 25%;
 }

.main_container_quiz .quiz_form_container .personal .inputs_row .first,.second {
   width: 50%;
}

.google_fitness .google_fitness_dashboard .timeselect .fa{
   float: left;
   margin-right:10px;
   padding-top:10px;
}

.google_fitness .google_fitness_dashboard .sections{
   margin-top: 20px;
}

.google_fitness .google_fitness_dashboard .sections .col{
   border: 1px solid #9b9b9b;
   border-radius: 20px;
   padding: 10px;
   min-height: 150px;
}

.google_fitness .google_fitness_dashboard .sections .title{
   font-size: 18px;
   font-weight: bold;
   border-bottom: 1px solid lightgray;
   text-align: center;
}

.google_fitness .google_fitness_dashboard .sections .value{
   font-size: 24px;
   font-style: italic;
   color:gray;
   text-align: center;
   padding: 50px;
}

.main_container_quiz .quiz_form_container .personal .inputs_row .full .label {
    color: black;
    margin-right: 18px;
    width: 25%;
}

.main_container_quiz .react-date-picker__inputGroup >* {
   color: black;
}

.main_container_quiz .final_submit_button{
   display: flex;
   justify-content: center;
   margin-top: 20px;
}

.main_container_quiz .final_submit_button button{
   color: #226920;
   border: 1px solid #226920;
   border-radius: 10px;
   font-weight: 500;
   margin-right: 10px;
   padding: 5px 30px;
}
.main_container_quiz .final_submit_button .previous_button,.main_container_quiz .final_submit_button .next_button{
background-color:#226920 ;
color: #fff !important;
}

.main_container_quiz .final_submit_button .previous_button:disabled,.main_container_quiz .final_submit_button .next_button:disabled{
    background-color: #80808073 !important;
   border-color: #80808000;
    
}

.client_mealplan .week_box{
   margin-top: 30px;
} 

.client_mealplan .week_box .weekselect{
   width: 200px;
   float: right;
}
.go2392553372{
   display: none !important;
}
.add_spl_span{
   text-decoration: underline;
   color: cornflowerblue;
   cursor: pointer;
   padding-left:5px;
}
.coach_cat_container .coach_cat_headrow .heading{
   font-size: 18px;
   font-weight: 500;
}
.coach_cat_container .coach_cat_headrow {
   padding-left: 10px;
   margin-bottom: 20px;   
}
.coach_cat_container .coach_cat_headrow button{
   background-color: #226920;
   border: none;
   color: white;
   padding: 8px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   cursor: pointer;
   float: right;
   border-radius: 12px;
}

.login_card .tfa_section input{
   height: 45px;
   padding: 0;
   font-size: 24px;
   text-align: center;
   margin-right: 12px;
   text-transform: uppercase;
   color: #494949;
   font-family: SF Pro Text,SF Pro Icons,Helvetica Neue,Helvetica,Arial,sans-serif;
   border: 1px solid #d6d6d6;
   border-radius: 4px;
   background: #fff;
   background-clip: padding-box;
}

.login_card .tfa_section input:focus{
   outline: 0;
   box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}
.client_add_plan_popup .input_row {
   display: flex;
   margin-bottom: 15px;

   
}
.client_add_plan_popup .input_row .label_div{
   width: 25%;
}
.client_add_plan_popup .input_row .input_div{
   width: 80%;
}
.client_add_plan_popup .input_row .input_div input{
   width: 100%;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 5px;
   border: 1px solid #7c7f83;
   height: 30px;
   padding-left: 10px;
}
.client_add_plan_popup .input_row .input_div textarea{
   width: 100%;
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   border-radius: 5px;
   border: 1px solid #7c7f83;
   height: 150px;
   padding-left: 10px;  
}
.documents_directory .head_row button{
background-color: #226920;
border: none;
color: white;
padding: 8px 32px;
text-align: center;
text-decoration: none;
display: inline-block;
font-size: 16px;
margin: 4px 2px;
cursor: pointer;
float: right;
border-radius: 12px;
}
.admin_user_directory .head_row button{
   background-color: #226920;
   border: none;
   color: white;
   padding: 8px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   cursor: pointer;
   float: right;
   border-radius: 12px;
   }


.documents_directory .head_row {
  height: 90px;
   }
.documents_directory .head_row .title{
font-weight: 500;
font-size: 18px;
}



.documents_directory .collapsible {
   width: 30%;
   background-color: #777;
   color: white;
   cursor: pointer;
   padding: 18px;
   border: none;
   text-align: left;
   outline: none;
   font-size: 15px;
   margin-bottom: 20px;
   margin-right: 4px;
   border-radius: 4px;
 }

 .admin_user_directory .collapsible {
   width: 30%;
   background-color: #777;
   color: white;
   cursor: pointer;
   padding: 18px;
   border: none;
   text-align: left;
   outline: none;
   font-size: 15px;
   margin-bottom: 20px;
   margin-right: 4px;
   border-radius: 4px;
 }
 
 .documents_directory .active, .collapsible:hover {
   background-color: #555;
 }
 
 .documents_directory .pointer_none
 {
   cursor: default !important;
 }

 .documents_directory .directory_icon
 {
   float: right;
   color: white !important;
   margin-right: 6px;
 }
 .admin_user_directory .directory_icon
 {
   float: right;
   color: white !important;
   margin-right: 6px;
 }
 .admin_user_directory .pointer_none
 {
   cursor: default !important;
 }
 
 .documents_directory .content {
   padding: 0 18px;
   display: none;
   overflow: hidden;
   background-color: #f1f1f1;
 }

 .documents_directory .file_lists{
   list-style: none;
 }

 .documents_directory .file_lists .file_icon{
   margin-right: 5px;
 }
 .directory_list_files .directory_owner{
   font-weight: 500;
   font-size: 14px;
 }

.directory_list_files_popup #files-area{
   width: 30%;	
 }

.directory_list_files_popup .file-block{
   border-radius: 10px;
   background-color: rgba(144, 163, 203, 0.2);
   margin: 5px;
   color: initial;
   display: inline-flex;	
 }

.directory_list_files_popup .file-block > span.name{
   padding-right: 10px;
   width: max-content;
   display: flex;
   align-items: center;
}

.directory_list_files_popup .file-delete{
   display: flex;
   width: 24px;
   color: initial;
   background-color: #6eb4ff00;
   font-size: large;
   justify-content: center;
   margin-right: 3px;
   cursor: pointer;	
}

.directory_list_files_popup .file-delete:hover{
   background-color: rgba(144, 163, 203, 0.2);
   border-radius: 10px;
}

.directory_list_files_popup .file-delete> span{
   background-color: rgba(144, 163, 203, 0.2);
   border-radius: 10px;
}

.drag_drop_css { 
   border: 1px solid #1a2e19;
   height: 100px;
   border-style: dotted;
   text-align: center;
   border-radius: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.drag_drop_css_button{
   background-color: #226920;
   border: none;
   color: white;
   padding: 8px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   cursor: pointer;
   /* float: right; */
   border-radius: 12px;
}

.file_list_upload_directory{
   list-style: none;
}

.file_list_upload_directory > li{
   height: 35px;
}
.file_list_upload_directory .title{
   margin-right: 5px;
}
.file_list_upload_directory .fa-times{
   cursor: pointer;
}
.directory_list_files .file_lists{
   border: 1px solid #0080008f;
   height: 50px;
   border-radius: 4px;
   padding-left: 5px;
   cursor: pointer;
   padding-top: 10px;
   width: 80%;

}
.directory_list_files .file_lists .icons{
   float: right;
   margin-right: 10px;
}

.directory_list_files .file_lists .icons .fa-edit{
   margin-right: 5px;
}


#react-doc-viewer a#pdf-download {
   display: none;
}
#react-doc-viewer button#pdf-toggle-pagination{
   display: none;
}
.directory_lists_table .icon_file{
   margin-right: 5px;
   cursor: pointer;
}
.directory_lists_table .file_path{
   cursor: pointer;
}
.directory_lists_table tr:hover{
   background-color: #00800014;
}
.directory_lists_table .file_lists_tr{
   cursor: pointer;
}
.allow_permission .select_members .label{
   font-weight: 600;
   margin-bottom: 5px;
}
.allow_permission .select_members{
   margin-bottom: 15px;
}
.admin_user_directory .menubox .tabmenu{
   width: 100% !important;
}
.admin_user_directory .menubox .tabmenu button{
   float: right;
   background-color: #226920;
   border: none;
   color: white;
   padding: 8px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   cursor: pointer;
   border-radius: 12px;
   margin-bottom: 15px;
}
.coach_users .fullscript_tab .menubox Button{
   background-color: #226920;
   border: none;
   color: white;
   padding: 8px 32px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   cursor: pointer;
   border-radius: 12px;
   margin-bottom: 15px;
}
.coach_users .fullscript_tab .menubox Button:active{
   background-color: #226920;
}

.admin_manageusers_add_coach_popup .coachadd_timezone, .admin_manageusers_add_client_popup .clientadd_timezone, .admin_manageusers_user_edit_popup .useredit_timezone,.admin_manageusers_user_edit_popup .role, .admin_manageusers_add_coach_popup .specialized_in,.admin_manageusers_user_edit_popup .specialized_in{
   width: 190px;
}

.admin_manageusers_add_coach_popup .PhoneInput, .admin_manageusers_add_client_popup .PhoneInput,.admin_manageusers_user_edit_popup .PhoneInput{
   display: inline-flex !important;
}
.admin_manageusers_add_coach_popup .coach_bio_row textarea,.admin_manageusers_user_edit_popup .coach_bio_row textarea{
   width: 100%;
}
.profile_screen_bio textarea{
   border-radius: 5px;
   border: 1px solid #7c7f83;
   width: 100%;
   margin-top: 8px;
}
.profile_screen_bio textarea:focus{
   outline: none;
   border: 1px solid #7c7f83;
}

.videomessages .timerinfo{
   font-weight: bold;
   color: #999999;
   float: right;        
}

.videomessages .timerinfo label{    
   padding-right: 10px;
}

.videomessages .timerinfo label .fa{
vertical-align: text-bottom;
padding-right: 2px;
}
.small_btm_book_apoinment .border_in_card{
   border: 1px solid #2269204a;
   border-radius: 5px;
   margin-bottom: 10px;
   padding-top: 10px;
}
.buy_service_popup_client .coach_info_row ,.book_client_popup .coach_info_row {
   display: flex;
}
.buy_service_popup_client .coach_info_row .img ,.book_client_popup .coach_info_row .img{
   width: 25%;
}
.buy_service_popup_client .coach_info_row .img img,.book_client_popup .coach_info_row .img img{
   width: 100%;
   height: 160px;
   border-radius: 50%;
}
.buy_service_popup_client .coach_info_row .info ,.book_client_popup .coach_info_row .info{
width: 70%;
margin-left: 10px;
color:rgb(0 18 16)

}
.buy_service_popup_client .coach_info_row .img p ,.book_client_popup .coach_info_row .img p{
margin: 0;
padding: 0;
}
.buy_service_popup_client .coach_info_row .img .name ,.book_client_popup .coach_info_row .img .name{
   font-size: 18px;
   color: black;
   text-align: center;
}
.buy_service_popup_client .coach_info_row .img .spl,.book_client_popup .coach_info_row .img .spl {
   font-size: 15px;
   color: gray;
   text-align: center;  
}
.admin_mail_template .modal-dialog{
   max-width: 100% !important;
}

.sidenav-inner .side_nav_class{
   margin-bottom:200px;
}
.calories_info_client_dashboard_srow .add_exercise{
   font-size: 12px;
   color: orange;
   cursor: pointer;
}

.coach_users .section_box .leftblock .users_list::-webkit-scrollbar {
   width: 3px;
}

.coach_users .section_box .leftblock .users_list::-webkit-scrollbar-track {
   box-shadow: inset 0 0 5px grey;
}

.coach_users .section_box .leftblock .users_list::-webkit-scrollbar-thumb {
   background: gray;
   border-radius: 10px;
}
.exercise_diary_row .met_chart{
   color:#226920;
   cursor:pointer
}

.coach_users .tabcontent .quiz_tab .no_quiz_error{
   text-align: center;
   margin-top: 15%;
   color: #226920;
}
.coachdocuments .fa {
   font-size: 14px  !important;
}
tr.trow.navigateclientdashboard{
   cursor: pointer;
}
tr.trow.navigateclientdashboard:hover{
   background-color: #53c6a214;
}

.admin_client_dashboard .add_data_coach
{
float: right;
padding: 7px 31px;
border-radius: 8px;
color: white;
background-color: #226920;
border: none;
font-size: 15px;
}

@media screen and (max-width:374px) and (min-width:320px) {

   .top_level_row_week_heading {
       margin-bottom: 30px !important;
       height: 50px;
       padding-left: 40px !important;
       display: flex;
       gap: 15px;
       margin-top: 10px !important;
    }
    .numberCircle{
font-size: 9px  !important
    }
    .col_activity_tick .row{
       gap: 8px;
    }

}
@media screen and (max-width:424px) and (min-width:375px) {
   .top_level_row_week_heading {
       margin-bottom: 30px !important;
       height: 50px;
       padding-left: 40px !important;
       display: flex;
       gap: 15px;
       margin-top: 10px !important;
    }
    .numberCircle{
font-size: 9px  !important
    }
    .col_activity_tick .row{
       gap: 12px;
    }
}

@media screen and (max-width:768px) and (min-width:425px)  {

   .top_level_row_week_heading {
       margin-bottom: 30px !important;
       height: 50px;
       padding-left: 61px !important;
       display: flex;
       gap: 30px;
       margin-top: 10px !important;
    }
    .numberCircle{
font-size: 9px  !important
    }
    .col_activity_tick .row{
       gap: 30px;
    }
}
.admin_coach_dashboard .filter_options_payment{
   display: flex;
   gap: 20px;
}
.admin_coach_dashboard .filter_options_payment .action_buttons{

padding: 3px 30px;
border-radius: 8px;
color: white;
background-color: #226920;
border: none;
font-size: 15px;

}
.admin_coach_dashboard .top_row_earnings{
   width: 50%;
}
.admin_coach_dashboard .top_row_earnings .items{
   width: 46%;
   float: left;
   height: 100px;
   background-color: #80808014;
   margin-right: 10px;
   margin-bottom: 10px;
   padding: 10px;
   border-radius: 5px;
}
.admin_coach_dashboard .top_row_earnings .items .amount{
   font-family: 'Playfair Display', serif;
   font-size: 22px;
   color: black;
   font-weight: 700;
}
.admin_coach_dashboard .top_row_earnings .items .label{
   font-size: 13px;
   color: #7c7f83;
}
.admin_coach_dashboard .recipe_img{
   object-fit: cover;
   height: 200px;
   width: 200px;
   
}
.admin_coach_client_food_journal .food_journal_img_td img{
   object-fit: cover;
   height: 200px;
   width: 200px;
}








.coach_availability .regular_availability .booking_allow {
   float: right;
   padding: 20px;
}

.coach_availability .regular_availability .booking_allow label{
   font-weight: bold;
   float:left;
   padding-right: 10px;
}

.coach_availability .regular_availability .booking_allow #checkboxes{
   float:left;
   padding-right: 5px;
}
/*client food journal*/
.client_food_journal.food_journal_outerwrap {
   display: flex;
   gap: 20px;
}
.client_food_journal .food_journal_leftwrap {
   flex-basis: 80%;
}
.client_food_journal .food_journal_rightwrap {
   flex-basis: 20%;
}
.client_food_journal .food_journal_top_head_inner {
   display: flex;
   justify-content: space-between;
   align-items: flex-start;
   background: #f8f8f8;
   padding: 12px 0px;
}
.food_journal_list_div .list_col .img_food_jour img {
   border-radius: 4px ;
   max-width: 100%;
   object-fit: contain;
   height: 300px;
}
.food_journal_list_div .list_col .img_food_jour {
   background: #00000012;
   padding: 10px;
   border-radius: 10px;
   text-align: center;
}
.client_food_journal .food_journal_edit {
   margin-top:10px;
   display: flex;
   justify-content: space-between;
}
.client_food_journal .food_journal_content {
   margin: 0px 20px 0px 0px;
}
.client_food_journal .food_journal_details p.type {
   font-size: 18px;
}
.client_food_journal h6.food_journal_title {
   text-align: center;
}
.client_food_journal .food_journal_grid {
   padding: 8px;
   display: flex;
   gap: 5px;
   flex-wrap: wrap;
   border: 1px solid #918f8f;
   border-radius: 16px;
   box-shadow: 0px 0px 1px 0px #008000;
}
.client_food_journal .food_journal_grid img {
   max-width: 48%;
   height: 65px !important;
   object-fit: cover;
}
.client_food_journal .add_input_div {
   border-bottom: 1px solid #22692082;
   padding-bottom: 30px;
}
.client_food_journal .list_col {
   margin-bottom: 20px;
}
.client_food_journal span.add_food_journal {
   cursor: pointer;
   border: 1px solid #120f0f40;
   padding: 0px 6px;
   border-radius: 7px;
} 
.client_food_journal .add_food_journal_top_head,.client_food_journal .food_journal_right_outerwrap, .coach_food_journal_top_nav {
   position: sticky;
   top: 0px;
   z-index: 1;
}
.client_food_journal i.fa.fa-edit.pointer_eff {
   color: green !important;
   background: #e7e7e7;
   padding: 7px 4px 0px 6px;
   border-radius: 12px;
   font-size: 23px !important;
}
.client_food_journal i.fa.fa-trash.pointer_eff {
   color: green !important;
   background: #e7e7e7;
   padding: 8px;
   border-radius: 12px;
}
.client_food_journal span.create_new_food_journal {
   border: 1px solid #0000007a;
   border-radius: 8px;
   padding: 0px 7px 4px 7px;
}
.client_food_journal span.create_new_food_journal:hover {
   cursor: pointer;
   background: #22692014;
}
.create_new_food_journal i.fa.fa-plus {
   vertical-align: middle;
   padding-right: 4px;
}
.client_food_journal p.desc .coach_food_journal p.desc {
    text-align: justify; 
}
@media screen and (max-width:967px) and (min-width:768px) {
   .client_food_journal .food_journal_grid img {
       max-width: 74%;
       height: 65px !important;
       object-fit: cover;
       margin: 0px auto;
   }
}
@media screen and (max-width:850px) and (min-width:768px) {
   .add_food_journal .add_food_journal_top_head span {
       font-size: 15px;
   }
}
@media screen and (max-width:767px) {
   .client_food_journal.food_journal_outerwrap {
       display: block;
   }
   .food_journal_right_outerwrap {
       display: none;
   }
   .coach_food_journal_outerwrap{
       display: block !important; 
   }
}
@media screen and (max-width:545px){
   .add_food_journal .add_food_journal_top_head select {
   padding:0px;
   }
   .add_food_journal .add_food_journal_top_head span {
       font-size: 14px;
   }
   .client_food_journal span.create_new_food_journal {
       padding: 0px 2px 1px 2px;
   }
   .coach_food_journal_top_nav {
       position: unset;
   }
   .coachpayments .filter_section .col.buttons{
       flex:none;
   }
}
@media screen and (max-width:419px) {
.client_food_journal .add_food_journal_top_head, .client_food_journal .food_journal_right_outerwrap {
   position: unset;
}
.client_food_journal .food_journal_top_head_inner {
   flex-direction: column;
   gap:10px;
}
.client_food_journal .add_food_journal .add_food_journal_top_head span {
   font-size: 16px;
}
.add_food_journal .food_journal_list_div .list_col .food_journal_details .type, .add_food_journal .food_journal_list_div .list_col .food_journal_details p{
   font-size: 12px;
}
.add_food_journal .food_journal_list_div .list_col .food_journal_details .date, .add_food_journal .food_journal_list_div .list_col .food_journal_details .comments{
   font-size:10px;
}
.food_journal_list_div .list_col .img_food_jour img{
   height: auto;
}
.food_journal_list_div .list_col .img_food_jour{
   padding:0px;
}
.client_food_journal i.fa.fa-edit.pointer_eff {
   padding: 5px 2px 4px 5px;
   font-size: 17px !important;
}
.client_food_journal i.fa.fa-trash.pointer_eff{
   padding: 6px;
}
}

.videomessages .call_state{
   margin-top: 20px;
}

.videomessages .call_state .local, .videomessages .call_state .remote{
   height: 300px;
   border: 10px solid #555555;
   background-color: #80808047;
   float: left;
   margin-right: 10px;
}
/*coach food journal*/
.coach_food_journal .list_col {
   padding-bottom: 25px;
   border-bottom: 1px solid #30892ea6;
   margin-bottom: 25PX;
}
.coach_food_journal_outerwrap {
   display: flex;
   gap: 10px;
}
.coach_food_journal_leftwrap {
   flex-basis: 80%;
}
.coach_food_journal_top_nav{
background: #f8f8f8;
}
.coach_food_journal span.clientEmail {
   display: block;
   font-size: 12px;
}
.coach_food_journal .coach_food_journal_topwrap {
   display: flex;
   justify-content: space-between;
   margin-top: 10px;
}

.coach_food_journal  .fa-cutlery:before{
   color: green;
   padding-right: 5px;
}

.coach_food_journal .desc_head {
   font-size: 16px;
   font-weight: 700;
}
.coach_food_journal .coach_food_journal_topright p.type{
   font-size: 16px;
   font-weight: 400 !important;
}
span.selected_client_email {
   display: block;
   font-size: 13px;
   color: #86898d;
}
.coach_food_journal .add_food_journal .food_journal_list_div .list_col .food_journal_details .type {
   font-weight: 700;
   padding-left: 5px;
   color: #525c52fc;
}
.food_journal_list_div p.desc {
   text-align: justify;
}
span.selected_client_email {
   width: 166px;
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
@media screen and (max-width:1050px) {
   span.selected_client_email {
       width: 100px;
   }
   p.clientNameNotfound {
       font-size: 13px;
   }
   }
.adminmanageusers .plan_link_manage_users{
   text-decoration: underline;
   color: #2196f3;
}
.session_package_list .plan_col.active{
   border: 3px solid #226920;
}
.session_package_list .plan_col{
   border: 1px solid black
}

.videomessages .call_state .local .overlay,.videomessages .call_state .remote .overlay{
   position: absolute;
}
.admin_intake_form .intake_form_list {
   margin-top: 10px;
}
.load_form_client_questionnaire .form-group canvas{
   background-color: #00800029;
}
.react-form-builder input[type='checkbox'], .react-form-builder input[type='radio']
{
   margin-right: 5px;
}

.react-form-builder-form input[type='checkbox'], .react-form-builder-form input[type='radio']
{
   margin-right: 5px;
}
.intake_form_export_div{
   margin-top: 5px !important;
   margin-bottom: 20px  !important;
}
.intake_form_export_div .intake_form_export_button{
   float: right;
   background-color: #226920;
   border: none;
   color: white;
   padding: 6px 25px;
   border-radius: 8px;
}
.intake_form_export_div_form_details{
   display: flex;
   gap: 1rem;
   flex-wrap: wrap;
   flex-direction: column;
   border-bottom: 1px dotted orange;
  
}
.intake_form_export_div_form_details span{
   font-weight: 500;
}
.intake_form_overall{
   border: 1px solid green;
   border-radius: 5px;
   padding: 15px;
}
.ai_calorie_finder_top .title{
   font-size: 18px;
   font-weight: 600;
   margin-bottom: 10px;
}
.ai_calorie_finder_top{
   margin-top: 20px;
}
.ai_calorie_finder_top .intake_form_export_button{
   float: right;
   background-color: #226920;
   border: none;
   color: white;
   padding: 1px 14px;
   border-radius: 8px;

}
.add_food_journal_popup .nutrition_info_food_journal{
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   border: 1px solid orange;
   padding: 10px;
   border-radius: 6px;
   gap: 7px;

}
.nutrition_info_food_journal .heading{
   font-weight: 600;
}
.nutrition_info_food_journal .info_row .title{
   width: 30%;
}
.nutrition_info_food_journal .info_row .content{
   width: 30%;
}
.nutrition_info_food_journal .add_button{
   cursor: pointer;
   color: orange;
   text-decoration: underline;

}
.added_ingredient .remove_icon{
   margin-left: 10px;
   font-size: 14px !important;
   cursor: pointer;
}
.success_msg{
   color: green;
}
.event_modal .event_picker_row{
   margin-top: 10px;
}

.event_picker_row .react-datetime-picker{
   width: 100%;
}
.event_picker_row .react-datetime-picker .react-datetime-picker__wrapper{
   border-radius: 5px;
}
.profile_calendar_div .google_calendar_access_button{
   border: 1px solid red;
   padding: 3px;
   border-radius: 4px;
   color: red  !important;
}
.profile_calendar_div .ms_calendar_access_button{
   border: 1px solid #039bf4;
   padding: 3px;
   border-radius: 4px; 
   color: #039bf4  !important;
}
.profile_calendar_div .fullscript_access_button{
   border: 1px solid #226920;
   padding: 3px;
   border-radius: 4px; 
   color: #226920  !important;
}
.profile_calendar_div .google_calendar_access_button .fa-google{
   color: red  !important;
}

.profile_calendar_div{
   margin-top: 30px;
}
.warning-msg-appointment{
   color: #9F6000;
   background-color: #FEEFB3;
   padding: 5px;
   border-radius: 5px;
   margin-bottom: 10px;
}

.event_picker_row .react-date-picker{
   width: 100%;
}
.event_picker_row .react-date-picker__wrapper{
   width: 100%;
   border-radius: 4px;
}
.join_meet{
   color: #0037ff;
   text-decoration: underline;
   cursor: pointer;
}
.nutrition_dashboard_col .c-avatar__status {
   margin-top: 20px !important;
}
.google_calendar_svg{
   width: 20px;
}
/* select option {
   background: #f00; 
   color: #fff; 
   box-shadow: inset 20px 20px #f00
 } 
 
 select option:hover {
   color: yellow  !important; 
   box-shadow: inset 20px 20px yellow;
 }
 select:hover { background-color: red; } */
 .withings_input_row  .withings_label_div{
   width: 20%;
 }
 .withings_input_row  .withings_input_div{
   width: 80%;
 }
 .withings_input_row {
   display: flex;
   margin-bottom: 20px;
 }
 .withings_input_row  .withings_input_div input[type='text'], .withings_input_row  .withings_input_div input[type='number']{
   width: 100%;
   border-radius: 4px;
   outline: none;
   border: 1px solid #607D8B;
 }
 .withings_input_div input[type='radio']{
margin-right: 4px;
 }
.withings_input_div .radio_label{
   margin-right: 4px;
     }
.withings_device_form .err{
       color: red;
     }
.name_block_available {
 width: 40%;
     }

@media (max-width:980px) { 

       .over_all_row_contact .contact_form_inputs{
  width: 100% !important;
       }
      } 

.user_info .set_goal_button {
       margin-right: 4px;
      }
.withings_whole_div .withings_logo{
  
       height: 350px;
      }
.withings_whole_div .withings_image_div{
       display: flex;
       justify-content: center;
      }
.withings_image_div .top_head{
   font-size: 28px;
   margin-bottom: 10px;
   text-align: center;
}
.order_details_withings {
   margin-top: 10px;
   font-size: 20px;

}
.weight_icon_withings {
   font-size: 11px;
   color: gray;

}
.overall_withings_admin_row{
   display: flex;
   border: 1px solid #00800069;
   margin-bottom: 13px;
   border-radius: 3px;
   padding: 4px;
}
.overall_withings_admin_col1{
   width: 50%;
   font-weight: 600;
}
.overall_withings_admin_col2{
   width: 50%;
}
   
.coach_users .connected_devices_tab .headsup_connect_access{
   text-align: center;
   margin-top: 10%;
   font-weight: 600;
}
 
.coach_users .fullscript_tab .eventhistory .time_filter{
   float: right;
   margin-bottom: 25px;
}

.coach_users .fullscript_tab .eventhistory .nolist{
clear: both;
display: inline-block;
margin-top: 10%;
margin-left: 20%;
border: 1px solid;
padding: 20px;
border-radius: 20px;
}
.glp_medication_form label{
   font-weight: 500;
   margin-bottom: 10px;
}
.glp_medication_form input[type='checkbox']{
   cursor: pointer;
   margin-right: 4px;
}
.glp_medication_form input[type='radio']{
   cursor: pointer;
   margin-right: 4px;
}
.glp_medication_form div:first-child  button {
   color: #ffffff;
   border: 1px solid #226920;
   border-radius: 10px;
   font-weight: 500;
   margin-right: 10px;
   padding: 5px 30px;
   background-color:#226920 !important;

}
.glp_medication_form div:first-child  button:disabled {
   background-color: #80808073 !important;
   border-color: #80808000;
}
.glp_medication_form div:first-child {
   margin-top: 20px;
}


.fullscript.alert_book_popup .product_data
{
margin-bottom: 15px;
border-bottom: 1px solid gray;
padding-bottom: 10px;
}
.notes_tab .top_bar_notes , .quiz_tab .top_bar_notes{
   display: flex;
   margin-bottom: 15px;
}
.notes_tab .top_bar_notes .tabs , .quiz_tab .top_bar_notes .tabs{
   padding: 10px 20px;
   color: #7C7F83 !important;
   text-decoration: none;
   font-weight: 500;
   font-size: 14px;
   display: inline-block;
   cursor: pointer;

}
.notes_tab .top_bar_notes .active , .quiz_tab .top_bar_notes .active{
   border-bottom: 2px solid #226920 !important;
   color: #226920 !important;
}
.glp_notes_faq_section {
   display: flex;
   justify-content: end;
   margin-bottom: 15px;
}
.glp_notes_faq_section button{

   padding: 7px 31px;
   border-radius: 8px;
   color: white;
   background-color: #226920;
   border: none;
   font-size: 15px;

}
.view_button{
    padding: 6px;
    border-radius: 5px;
    width: 54%;
    color: #fff;
    background-color: #226920 ;
    border-color: #226920;
    border: none;
}
.view_button_beluga_upload{
    padding: 6px;
    border-radius: 5px;
    color: #fff;
    background-color: #226920 ;
    border-color: #226920;
    border: none;
}
.view_button_beluga_upload_cancel{
    padding: 6px;
    border-radius: 5px;
    color: #fff;
    background-color: #c53c3c ;
    border-color: #c53c3c;
    border: none;
}
.view_button_beluga_upload_submit{
    padding: 6px;
    border-radius: 5px;
    color: #fff;
    background-color: orange ;
    border-color: orange;
    border: none;
}
.beluga_visit_container{
    margin-top: 33px !important;
}
.beluga_visit_Top_heading_row .client_top_appointment_row_span{
    width: 70% !important;
}
.beluga_visit_Top_heading_row .view_button{
    width: 30% !important;
}
.photo_submission_wrap .over_all_image_wrapper .image-item ,.photo_submission_wrap .over_all_image_wrapper .image-item img{
    margin-top: 20px;
    margin-bottom: 20px;
}
.image-item__btn-wrapper{
    display: flex;
    gap: 5px;
}
.beluga_visit_tbody_img img{
    width: 200px;
    height: 200px;
}
@media screen and (max-width: 440px) {
    .beluga_visit_tbody_img img{
        width: 145px;
        height: 145px;
    }
  }
.beluga_health_visit_form .modal-title{
    width: 100%  !important;
}
.beluga_health_visit_form .close_icon{
    cursor: pointer;
    float: right;
}
.beluga_health_visit_form .main_container_quiz .quiz_form_container > div:first-child >div:last-child{
 margin-top: 20px;
}
.beluga_health_visit_form  input[type='text']
{

    width: 100%;
    padding: 4px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #607D8B;
}
.beluga_health_visit_form .radio_input_yes_no {
    display: flex;
    gap:12px;
    margin-top: 10px;
}
.beluga_health_visit_form .radio_input_yes_no  label{
    font-weight: 400 !important;
}
.beluga_health_visit_form .textarea_row , .beluga_health_visit_form .textarea_row textarea {

    width: 100%;
    margin-top: 10px;
}
.beluga_health_visit_form .textarea_row textarea {
    border-radius: 4px;
    outline: none;
    border: 1px solid #607D8B;
}

.radio_health_qn .input_row{
    margin-top: 20px;
}
.beluga_visit_container .beluga_upload_media{
    position: relative;
    top: 6px;
    right: 10px;
    cursor: pointer;
}
.beluga_visit_container .beluga_upload_media_input{
display: none;
}
.sex_beluga_visit{
    display: flex;
    gap: 10px;
}
.beluga_medicine_details .input_row select{
    width: 100%;
    padding: 5px;
    cursor: pointer;
}
.beluga_health_visit_form  .modal-dialog{
    width: 70% !important;
    max-width: 70% !important;
}
.feet_inch_row input{
    width: 10% !important;
    margin-right: 8px;
}
.weight_input_row{
    width: 21% !important;
}
.bmi_input_row{
    text-align: left !important;
}
.basic_info_gender_glp_quiz{
    text-align: left !important;
    margin-top: 10px !important;

}
.beluga_quiz_form_container .consent_checkbox{
    margin-right: 10px;
}
.rce-mbox-photo--img__block{
    background-color: transparent !important;
}
.admin_client_dashboard .view_beluga_log_butt ,.section_box .view_beluga_log_butt{
width: 64% !important;
}
.admin_client_dashboard .beluga_visit_container .menubox{
    margin-top: 20px;
}
.beluga_visit_container tbody .view_button{
    width: 100% !important;
}
.admin_client_dashboard .beluga_visit_container .presription_vial_row img{
    width: 45%;
    margin-top: 11px;
    margin-bottom: 10px;
}
.admin_client_dashboard .beluga_visit_container .presription_vial_row
{
    margin-top: 10px;
}
.admin_client_dashboard .beluga_visit_container .presription_vial_row h6{
    font-size: 18px !important;
    color: #226920;
    font-weight: 400;

}

 .beluga_visit_container .presription_vial_row img{
    width: 45%;
    margin-top: 11px;
    margin-bottom: 10px;
    }
     .beluga_visit_container .presription_vial_row
    {
        margin-top: 10px;
    }
     .beluga_visit_container .presription_vial_row h6{
        font-size: 18px !important;
        color: #226920;
        font-weight: 400;
    
    }
    #glpdosepref  .input_row_dose {
        text-align: left  !important;
        margin-top: 20px;
    }
    #glpdosepref  .input_row_dose select{
        padding: 5px;
        width: 70%;
        cursor: pointer;
 
    }
    .beluga_quiz_form_container .input_row  label{
        font-weight: 600;
    }
    .beluga_quiz_form_container .beluga_consent .remember{
        margin-top: 20px;
    }
    .rce-container-mlist.message-list{
        height: 400px;
    }
    .beluga_input.err{
        display: flex;
        justify-content: flex-end;
    }
    .rce-mbox-photo--img img{
        height: 150px !important;
    }
    #glpdosepref .even-columns{
        display: flex;
    }
    #glpdosepref .even-columns .left{
       width: 50%;
    }
    #glpdosepref .even-columns .right{
        width: 50%;
     }
     #glpdosepref .even-columns .right img{
        width: 100%;
     }
     .beluga_visit_container_table .even-columns{
        display: flex;
        justify-content: flex-end;
     }
     .beluga_visit_container_table .even-columns .input input[type="text"] {
        margin-bottom: 20px;
        padding: 5px;
        border-radius: 5px 0px 0px 5px;
        outline: none;
        border: 1px solid #8cb38b;
    }
    .beluga_visit_container_table .even-columns button{
        border: 1px solid #8cb38b;
        padding: 5px;
        border-left: 0px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .beluga_visit_container_table .even-columns button .fa-search{
        color: #226920 !important;
    }

 
  @media only screen and (min-width:480px){
    .beluga_visit_container_table .even-columns .input input[type="text"] {
        width: 287px;
    }
  }

  @media screen and (max-width: 40em) {
    .responsiveTable tbody tr {
       
        margin-bottom: 12px;
    }
}
.beluga_admin_chat{
    width: 94% !important;
}

@media only screen and (max-width: 583px) {
    .payment_success_page .payment_success_card {
        width: 90%;
    }
}
@media only screen and (max-width: 575px) {
    .beluga_health_visit_form .modal-dialog {
        width: 92% !important;
        max-width: 92% !important;
    }
}
.width_big {
    width: 20%;
}
.plan_image_preview{
    display: flex;
   margin-bottom: 20px;
}
.plan_image_preview img{
    width:50%
}
.plan_image_preview i{
    cursor: pointer;
}
.subscription_add_ons_container .add_ons_row{
    display: flex;
    padding: 25px;
    border: 1px solid #b8b8b8;
    margin-bottom: 10px;
    border-radius: 10px;
}
.inpagesubaddons .subscription_add_ons_container .add_ons_row .img_col img{
    width: 100px !important;
}
.subscription_add_ons_container .add_ons_row .img_col img{
    width: 200px;
}
.subscription_add_ons_container .add_ons_row .img_col{
    width: 35%;
}
.subscription_add_ons_container .add_ons_row .name_col{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.subscription_add_ons_container .add_ons_row .tick_col{
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}
section.admin_client_dashboard{
    height: 100% !important;
    min-height: 100% !important;
}
.clientsubscriptions .subscription_add_ons{
    display: contents;
}
.clientsubscriptions .subscription_add_ons button{
    width: 20%;
    float: right;
}








